<template>
    <div class="catalog-container text-center">
      <div class="mb-3">
        <strong class="title">User Profiles</strong>
      </div>
        <!-- Filter -->
        <b-row>
            <b-col offset-xl="4" col xl="4" offset-md="2" md="8">
                <b-form-group id="filter-fieldset" label="Filter" label-class="font-weight-bold" label-for="filter-input"
                    label-cols-md="3">
                    <b-form-input id="filter-input" type="search" placeholder="Search" v-model="filter"></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col offset-xl="3" col xl="6">
                <b-table id="catalog-table" class="catalog-table" bordered small no-border-collapse responsive="lg"
                    primary-key="id" :items="items" :fields="fields" :per-page="perPage" :current-page="currentPage"
                    :filter="filter" @filtered="onFiltered" :filterIncludedFields="filterIncludedFields"
                    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                    <template #cell(actions)="row">
                        <b-button variant="kag-light-blue" class="mr-2 edit-btn" size="sm"
                        :to="{ name: 'UserProfilesRegions' , params: { email: row.item.emailAddress }}">
                            <font-awesome-icon class="clickable" icon="arrow-right" />
                        </b-button>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <b-row>
            <!-- Pagination -->
            <b-col col sm="6" offset-xl="3" xl="3" id="catalog-pagination" class="pagination-container">
                <b-pagination class="justify-content-center" v-model="currentPage" :total-rows="totalRows"
                    :per-page="perPage"></b-pagination>
            </b-col>
            <!-- Per Page -->
            <b-col col sm="6" offset-xl="1" xl="2" id="catalog-row-per-page">
                <b-form-select class="justify-content-center" v-model="perPage" :options="pageOptions"
                    size="sm"></b-form-select>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
import userAuthGetters from '@/mixins/shared/userAuthGetters'
import toastMixin from '@/mixins/shared/toastMixin'
const UserProfileRepo = RepositoryFactory.get('UserProfileRepository')

export default {
  name: 'UserProfiles',
  mixins: [
    userAuthGetters,
    toastMixin
  ],
  data () {
    return {
      getAllRequest: UserProfileRepo.getAll,
      items: [],
      fields: [
        { key: 'name', label: 'Name', sortable: true, sortDirection: 'desc' },
        { key: 'emailAddress', label: 'E-Mail', sortable: true, sortDirection: 'desc' },
        { key: 'actions', label: '', class: 'actions' }
      ],
      perPage: 10,
      pageOptions: [
        { value: 5, text: '5 per page' },
        { value: 10, text: '10 per page' },
        { value: 25, text: '25 per page' },
        { value: 50, text: '50 per page' },
        { value: 100, text: '100 per page' }
      ],
      totalRows: 0,
      currentPage: 1,
      filter: '',
      filterIncludedFields: ['name', 'emailAddress'],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc'
    }
  },
  async beforeRouteEnter (to, from, next) {
    try {
      const response = await UserProfileRepo.getAll()
      to.meta.userProfiles = response && response.data ? response.data : []
      next()
    } catch (ex) {
      console.log(ex)
      next('/error')
    }
  },
  created () {
    if (!this.canViewCustomGroup) {
      if (this.canViewCustomGroup) {
        this.$router.push({ name: 'CustomGroups' })
      } else if (this.canViewCompany) {
        this.$router.push({ name: 'Sites' })
      } else {
        this.$router.push({ name: 'AccessDenied' })
      }
      return
    }
    const items = this.$route.meta.userProfiles ? this.$route.meta.userProfiles : []
    this.init(items)
  },
  methods: {
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    init (items) {
      // this.items = items.filter((d) => d.isActive)
      this.items = items
      this.totalRows = this.items.length
      this.currentPage = 1
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/themes/theme';
.catalog-container {
  margin-top: 10px;

  .catalog-table {
    .actions {
      width: 80px;
      min-width: 80px;
      background-color: $white;
      border: none;
    }
    .keep-spaces { white-space: pre-wrap; }
  }
}
.kag-dark-blue {
    color: #fff;
    background: #114c8e linear-gradient(180deg, #35679f, #114c8e) repeat-x;
    border-color: #114c8e;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
label {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>

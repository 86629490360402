<template>
  <div id="app-overlay" class="app-overlay" v-if="isLoading">
    <font-awesome-icon icon="fa-solid fa-spinner" class="spinner fa-spin" />
  </div>
</template>

<style lang="scss">
@import '~@/assets/scss/themes/theme';

.app-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: black;
  z-index: 9999;
  opacity: .5;

  .spinner {
    position: relative;
    height: 50px;
    color: $kag-dark-blue-color;
    margin-left: calc(50% - 25px);
    top: calc(50% - 25px);
  }
}
</style>

<script>
export default {
  name: 'AppLoading',
  props: {
    isLoading: Boolean
  }
}
</script>

import global from '@/mixins/shared/global'
import Repository from './Repository'
const regionRepo = Repository(global.basePath)

const resource = '/Region'

export default {
  getAll () {
    return regionRepo.get(`${resource}`)
  },
  get (id) {
    return regionRepo.get(`${resource}/${id}`)
  },
  create (payload) {
    return regionRepo.post(`${resource}`, payload)
  },
  update (payload) {
    return regionRepo.put(`${resource}`, payload)
  },
  userProfileByEmail () {
    return regionRepo.get(`${resource}/UserProfileByEmail`)
  }
}

<template>
  <div id="pending-orders" class="pending-orders">
    <div
      class="pending-orders-table-container"
      :class="{ 'show-edit': showEdit && (canUpdateOrder || canDeleteOrder) }"
      v-if="orderData.length"
    >
      <b-table
        :id="'pending-orders-table-' + compId"
        class="pending-orders-table"
        bordered
        small
        responsive
        no-border-collapse
        tbody-tr-class="table-row"
        :items="orderData"
        :fields="orderFields"
      >
        <template #cell(edit)="row">
          <b-button variant="kag-light-blue" class="mr-2 edit-btn" size="sm" @click="editItem(row.item)" v-b-tooltip.hover="'Edit'">
            <font-awesome-icon class="clickable" icon="pen-to-square" />
          </b-button>
        </template>
        <template v-slot:cell(orderRemarks)="data">
          <span v-b-tooltip.hover :title=data.value>{{ data.value.substring(0,25)}}</span>
        </template>
        <template v-slot:cell(priority)="data">
          <span> {{ data.value == 'UNK' ? 'Available' : 'Priority' }}</span>
        </template>
      </b-table>
    </div>

    <div
      class="pending-orders-actions-table-container"
      v-if="showEdit && orderData.length && (canUpdateOrder || canDeleteOrder) && confirmButtonStatus"
    >
      <b-table
        id="pending-orders-actions-table"
        class="pending-orders-actions-table"
        bordered
        small
        no-border-collapse
        responsive="lg"
        tbody-tr-class="table-row"
        :items="orderData"
        :fields="editFields"
      >
        <template #cell(edit)="row">
          <font-awesome-icon v-if="canUpdateOrder" class="clickable icon-light-blue pr-3" icon="pen-to-square" v-b-tooltip.hover="'Edit'" @click="editItem(row.item)"/>
          <font-awesome-icon v-if="canDeleteOrder" class="clickable icon-red" icon="trash" v-b-tooltip.hover="'Delete'" @click="deleteItem(row.item)"/>
        </template>
      </b-table>
    </div>

    <div v-if="!orderData.length" class="d-block w-100 text-center">
      No records to display
    </div>

    <ConfirmModal ref="deleteModal"></ConfirmModal>
    <CreateOrder ref="createOrderModal"></CreateOrder>
  </div>
</template>

<style lang="scss">
@import '~@/assets/scss/themes/theme';

.text-red {
  color: $kag-red-color
}

.text-green {
  color: $kag-green-color
}

.pending-orders {
  width: 100%;
  display: inline-flex;

  > div {
    display: inline-flex;
  }

  $actions-container-width: 80px;
  $row-height: 33px;

  .pending-orders-table-container {
    width: 100%;
    overflow-x: overlay;

    &.show-edit {
      width: calc(100% - #{$actions-container-width});
    }

    .pending-orders-table {
      tbody {
        tr {
          height: $row-height;

          td {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .edit {
      background-color: $white;
      border: none;

      .btn {
        font-size: 0.475rem;
      }
    }
  }

  .pending-orders-actions-table-container {
    width: $actions-container-width;

    .pending-orders-actions-table {
      margin-top: 20px;
      tbody {
        tr {
          height: $row-height;

          td {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .edit {
      .btn {
        font-size: 0.475rem;
      }
    }
  }
  .icon-red {
    color: $kag-red-color;
  }
  .icon-light-blue {
    color: $kag-light-blue-color;
  }
}
</style>

<script>
import moment from 'moment-timezone'

import global from '@/mixins/shared/global'
import userAuthGetters from '@/mixins/shared/userAuthGetters'
import toastMixin from '@/mixins/shared/toastMixin'

import { RepositoryFactory } from '@/repositories/RepositoryFactory'

import ConfirmModal from '@/components/shared/ConfirmModal.vue'
import CreateOrder from '@/components/site/CreateOrder.vue'

const FimOrderRepo = RepositoryFactory.get('FimOrderRepository')

const ID_FIELD = [
  { key: 'id', label: 'Number' }
]

const COMPANY_FIELD = [
  { key: 'companyName', label: 'Site' }
]

const ORDER_FIELDS = [
  { key: 'retainDate', label: 'Retain Date' },
  { key: 'retainTime', label: 'Retain Time' },
  { key: 'runoutDate', label: 'Runout Date' },
  { key: 'runoutTime', label: 'Runout Time' }
]

const ORDER_CSRNOR_FIELDS = [
  { key: 'customerShift', label: 'Customer Shift' },
  { key: 'releaseNumber', label: 'Release Number' },
  { key: 'orderRemarks', label: 'Order Remarks' },
  { key: 'priority', label: 'Order Priority' }
]

export default {
  name: 'PendingOrders',
  mixins: [
    userAuthGetters,
    toastMixin
  ],
  components: {
    ConfirmModal,
    CreateOrder
  },
  props: {
    compId: String,
    timeZoneId: String,
    showEdit: Boolean,
    pendingOrders: Array,
    confirmButtonStatus: Boolean
  },
  data () {
    return {
      headerHeight: '30px',
      orderFields: [],
      orderData: [],
      editFields: [
        { key: 'edit', label: 'Actions', class: 'edit' }
      ]
    }
  },
  watch: {
    pendingOrders (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.build()
      }
    }
  },
  created () {
    this.build()
  },
  methods: {
    build () {
      this.orderFields = []
      this.orderData = []

      if (this.pendingOrders && this.pendingOrders.length === 0) {
        return
      }

      this.orderFields = [...ID_FIELD]
      if (this.showEdit) {
        this.orderFields = this.orderFields.concat(COMPANY_FIELD)
      }

      this.orderFields = this.orderFields.concat(ORDER_FIELDS)

      let totalProducts = 0
      this.pendingOrders.forEach((po) => {
        if (po.fimOrderProducts.length > totalProducts) {
          totalProducts = po.fimOrderProducts.length
        }
      })

      for (let i = 0; i < totalProducts; i++) {
        this.orderFields.push({ key: `product${i}`, label: 'Product' })
        this.orderFields.push({ key: `product${i}Qty`, label: 'Qty' })
      }

      this.orderFields = this.orderFields.concat(ORDER_CSRNOR_FIELDS)

      this.pendingOrders.forEach((order) => {
        const retainDateTime = new Date(order.retainDateTime)
        const runoutDateTime = new Date(order.runoutDateTime)
        const data = {
          id: order.id,
          companyName: order.companyUniqueId,
          retainDate: this.formatDate(retainDateTime),
          retainTime: this.formatTime(retainDateTime),
          runoutDate: this.formatDate(runoutDateTime),
          runoutTime: this.formatTime(runoutDateTime),
          customerShift: order.customerShift,
          releaseNumber: order.releaseNumber,
          orderRemarks: order.orderRemarks,
          priority: order.priority
        }

        if (order.fimOrderProducts) {
          order.fimOrderProducts.forEach((p, idx) => {
            data[`product${idx}`] = p.commodityClass
            data[`product${idx}Qty`] = p.gallons
          })
        }
        this.orderData.push(data)
      })

      const interval = setInterval(() => {
        const tableElm = document.getElementById(`pending-orders-table-${this.compId}`)
        if (!tableElm) {
          this.headerHeight = '30px'
        }
        const tHeadElm = tableElm.getElementsByTagName('thead')
        if (!tHeadElm || !tHeadElm[0]) {
          this.headerHeight = '30px'
        }

        this.headerHeight = `${tHeadElm[0].clientHeight}px`
        clearInterval(interval)
      }, 100)
    },
    updateAfterConfirm (response) {
      this.orderFields.splice(2, 0, {
        key: 'status',
        label: 'Status',
        tdClass: (value) => {
          if (value === 'Failed') {
            return 'text-red'
          }
          if (value === 'Accepted') {
            return 'text-green'
          }
        }
      })
      this.orderFields.splice(3, 0, { key: 'kagOrderNumber', label: 'Order Number' })

      response.map((order, index) => {
        this.orderData[index].status = order.statusDescription
        this.orderData[index].kagOrderNumber = order.kagOrderNumber
      })
    },
    formatDate (date) {
      return moment(date).format('MM/DD')
    },
    formatTime (date) {
      return moment(date).format('HH:mm')
    },
    async editItem (item) {
      if (this.canUpdateOrder) {
        const fimOrder = this.pendingOrders.find((po) => po.id === item.id)
        const result = await this.$refs.createOrderModal.show({
          title: 'Edit Order',
          timeZoneId: this.timeZoneId,
          fimOrder
        })
        if (result) {
          this.$emit('refresh')
        }
      } else {
        this.showError('Current user does not have permissions to edit orders')
      }
    },
    async deleteItem (item) {
      if (this.canDeleteOrder) {
        const result = await this.$refs.deleteModal.show({
          title: 'Delete New Order',
          text: `Are you sure you want to delete Order ${item.id}?`,
          confirmText: 'Delete',
          confirmVariant: 'outline-kag-red'
        })
        if (result) {
          item.isDeleted = true
          FimOrderRepo.delete(item.id)
            .then(() => {
              const itemIdx = this.orderData.findIndex((o) => o.id === item.id)
              this.orderData.splice(itemIdx, 1)
              this.showSuccess('Order has been deleted')
              this.$store.dispatch('orders/deleteOrderToReview', item.id)
              if (!this.orderData.length) {
                this.$emit('refresh')
              }
              this.$emit('deleting')
            })
            .catch((error) => {
              this.showError(global.handleError(error, 'An error occurred while trying to delete Order. Please try again later'))
            })
        }
      } else {
        this.showError('Current user does not have permissions to delete orders')
      }
    }
  }
}
</script>

import axios from 'axios'
import store from '../store'

import { EventBus } from '@/mixins/shared/event-bus'

const createRepository = (baseDomain) => {
  const baseURL = `${baseDomain}`
  const httpClient = axios.create({ baseURL })
  httpClient.interceptors.request.use((config) => {
    EventBus.$emit('loading', true)
    config.headers.Authorization = `Bearer ${store.getters['oidcStore/oidcAccessToken']}`
    return config
  }, (error) => {
    EventBus.$emit('loading', false)
    return Promise.reject(error)
  })

  httpClient.interceptors.response.use((response) => {
    EventBus.$emit('loading', false)
    return response
  }, (error) => {
    EventBus.$emit('loading', false)
    return Promise.reject(error)
  })

  return httpClient
}

export default createRepository

import getters from './getters.js'

const namespaced = true
const state = {
  firstName: '',
  lastName: '',
  email: '',
  hasInventoryManagementAdmin: false,
  tenantName: null,
  tenantNameMatch: false,
  canViewCompany: false,
  canCreateCompany: false,
  canUpdateCompany: false,
  canDeleteCompany: false,
  canViewCommodity: false,
  canCreateCommodity: false,
  canUpdateCommodity: false,
  canDeleteCommodity: false,
  UICalculatorEditor: false,
  UICompanyEditor: false,
  UITankEditor: false,
  UIUserProfilesEditor: false,
  userProfilesAdmin: false,
  canViewCustomGroup: false,
  canCreateCustomGroup: false,
  canUpdateCustomGroup: false,
  canDeleteCustomGroup: false,
  canViewInventory: false,
  canCreateInventory: false,
  canUpdateInventory: false,
  canDeleteInventory: false,
  canViewNote: false,
  canCreateNote: false,
  canUpdateNote: false,
  canDeleteNote: false,
  canViewOrder: false,
  canCreateOrder: false,
  canUpdateOrder: false,
  canDeleteOrder: false,
  canViewRegion: false,
  canCreateRegion: false,
  canUpdateRegion: false,
  canDeleteRegion: false,
  canViewRestriction: false,
  canCreateRestriction: false,
  canUpdateRestriction: false,
  canDeleteRestriction: false,
  canViewSales: false,
  canCreateSales: false,
  canUpdateSales: false,
  canDeleteSales: false,
  canViewTank: false,
  canCreateTank: false,
  canUpdateTank: false,
  canDeleteTank: false,
  canViewTenant: false,
  canCreateTenant: false,
  canUpdateTenant: false,
  canDeleteTenant: false,
  canCreateUserProfile: false,
  canViewFileUploadHeader: false,
  canCreateFileUploadHeader: false,
  canUpdateFileUploadHeader: false,
  canDeleteFileUploadHeader: false
}

export default {
  namespaced,
  state,
  getters
}

<template>
  <div id='app'>
      <AppLoading :isLoading='isLoading'></AppLoading>
      <b-navbar type='dark' variant='kag-dark-grey'>
        <b-navbar-brand>
          <b-button v-b-toggle.kag-sidebar='true' size='sm' variant='kag-grey'>
            <font-awesome-icon icon='bars' />
          </b-button>
          <img
            alt='Logo'
            class='ml-3 logo'
            src='./assets/images/kenan-logo.png'
          />
        </b-navbar-brand>
        <EnvironmentBanner></EnvironmentBanner>
        <span class='user mr-2 ml-auto'
          >{{ getFirstName }} {{ getLastName }} - ( {{ tenantName }} )</span
        >
        <SignOut></SignOut>
      </b-navbar>
      <b-sidebar
        id='kag-sidebar'
        bg-variant='kag-dark-grey'
        backdrop-variant='dark'
        text-variant='light'
        no-header-close
        backdrop
        shadow
        v-model="showSideBar"
        ref='kagSideBar'
      >
        <div class='p-3 sidenav-container'>
          <nav class='mb-3'>
            <b-nav vertical>
              <b-nav-item>
                <img
                  alt='Logo'
                  class='logo'
                  src='./assets/images/kenan-logo.png'
                />
              </b-nav-item>
              <b-nav-item active-class="active" :to="{ name: 'CustomGroups' }">
                Custom Groups
              </b-nav-item>
              <b-nav-item
                v-if="UICompanyEditor"
                active-class="active"
                :to="{ name: 'Companies' }"
              >
                Companies
              </b-nav-item>
              <b-nav-item active-class="active" :to="{ name: 'Regions' }">
                Regions
              </b-nav-item>
              <b-nav-item
                active-class="active"
                :to="{ name: 'Sites', params: { siteId: '0000' } }"
              >
                Sites
              </b-nav-item>
              <b-nav-item
                v-if="canViewUserRegionProfiles"
                active-class="active"
                :to="{ name: 'UserProfiles' }"
              >
                User Profiles
              </b-nav-item>
            </b-nav>
          </nav>
        </div>
        <template #footer="{ hide }">
          <div class="d-flex bg-dark align-items-center px-3 py-2">
            <div class="mr-auto">{{ uiReleaseNumber }}</div>
            <b-button size="sm" @click="hide">Close</b-button>
          </div>
        </template>
      </b-sidebar>
      <router-view />
      <b-modal
        v-model="showModal"
        title="Exiting page with pending changes"
        header-bg-variant="warning"
        ref="confirmationModal"
        ok-only>
        <p>You have orders pending to be reviewed. Please review them before leaving.</p>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="showModal=false"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
  </div>
</template>

<style lang="scss">
@import "~@/assets/scss/vendors/bootstrap-vue/index";
@import "~@/assets/scss/styles";

#app {
  font-family: system-ui, "Helvetica Neue LT Std Family", "Helvetica Nue",
    "Open Sans Family", "Sans Serif" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .navbar {
    .logo {
      width: 100px;
    }

    .user {
      color: white;
      font-size: 0.850rem;
    }
  }

  .sidenav-container {
    .logo {
      width: 100px;
    }
  }
.oos-disabled{
    background-color: #ffeeba !important;
    border-color: #ffdf7e !important;
  }
}
</style>

<script>
import global from '@/mixins/shared/global'
import userAuthGetters from '@/mixins/shared/userAuthGetters'
import { EventBus } from '@/mixins/shared/event-bus'

import EnvironmentBanner from '@/components/shared/EnvironmentBanner.vue'
import SignOut from '@/components/shared/SignOut.vue'
import AppLoading from '@/components/shared/AppLoading.vue'
import toastMixin from '@/mixins/shared/toastMixin'

export default {
  name: 'App',
  mixins: [userAuthGetters, toastMixin],
  components: {
    EnvironmentBanner,
    SignOut,
    AppLoading
  },
  created () {
    EventBus.$on('loading', (loading) => {
      this.setLoading(loading)
    })
    EventBus.$on('closeSideBar', (closeSideBar) => {
      this.showSideBar = false
    })
  },
  mounted () {
    this.showPendingOrdersToBeReviewedMessage()
    document.addEventListener('visibilitychange', this.handleVisibilityChange)
  },
  beforeDestroy () {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange)
  },
  data () {
    return {
      refCount: 0,
      isLoading: false,
      showModal: false,
      showSideBar: false
    }
  },
  computed: {
    uiReleaseNumber () {
      return 'Release ' + global.appConfig.UiReleaseNumber
    },
    ordersToReviewCount () {
      return this.$store.getters['orders/amountOfOrdersToReview']
    },
    routeName () {
      return this.$route.name
    }
  },
  methods: {
    setLoading (isLoading) {
      if (isLoading) {
        this.refCount++
        this.isLoading = true
      } else if (this.refCount > 0) {
        this.refCount--
        this.isLoading = this.refCount > 0
      }
    },
    handleVisibilityChange (event) {
      if (this.ordersToReviewCount > 0 && document.visibilityState === 'hidden') {
        event.preventDefault()
        this.$refs.confirmationModal.show()
        event.returnValue = ''
      }
    },
    showPendingOrdersToBeReviewedMessage (showModal) {
      if (this.ordersToReviewCount > 0) {
        if (!showModal) this.showWarning('You have pending orders to review. Please head to sites page.')
        if (showModal) this.$refs.confirmationModal.show()
      }
    }
  },
  watch: {
    routeName: {
      // Handler function to execute when the property changes
      handler (newValue, oldValue) {
        if (newValue !== 'Sites' && oldValue !== undefined) this.showPendingOrdersToBeReviewedMessage(oldValue !== 'Sites')
      },
      // Optional: Specify whether to deep watch the property
      deep: true,
      // Optional: Specify whether to trigger the watcher immediately upon creation
      immediate: true
    }
  }
}
</script>

<template>
    <div class="catalog-container text-center">
      <div class="mb-3">
        <strong class="title">Add Regions to User</strong>
      </div>
        <UserProfileInfo :user="user" ></UserProfileInfo>
        <RegionListToSelect :user="user"></RegionListToSelect>
    </div>
</template>

<script>
import userAuthGetters from '@/mixins/shared/userAuthGetters'
import toastMixin from '@/mixins/shared/toastMixin'
import UserProfileInfo from '@/components/userProfile/UserProfileInfo.vue'
import RegionListToSelect from '@/components/userProfile/RegionListToSelect.vue'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const UserProfileRepo = RepositoryFactory.get('UserProfileRepository')
export default {
  mixins: [
    userAuthGetters,
    toastMixin
  ],
  components: {
    UserProfileInfo,
    RegionListToSelect
  },
  data () {
    return {
      user: {
        id: 0,
        name: '',
        emailAddress: ''
      }
    }
  },
  created () {
    this.getUser()
  },
  methods: {
    getUser () {
      try {
        const email = this.$route.params.email
        UserProfileRepo.getByEmailAddress(email).then((userResponse) => {
          if (userResponse) {
            this.user = userResponse.data
          } else {
            console.log('there is no user information')
          }
        })
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/themes/theme';
.catalog-container {
  margin-top: 10px;

  .catalog-table {
    .actions {
      width: 80px;
      min-width: 80px;
      background-color: $white;
      border: none;
    }
    .keep-spaces { white-space: pre-wrap; }
  }
}
.kag-dark-blue {
    color: #fff;
    background: #114c8e linear-gradient(180deg, #35679f, #114c8e) repeat-x;
    border-color: #114c8e;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
label {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>

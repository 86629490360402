<template>
  <div>
    <div class="product-inventory-container">
      <b-row class="top">
        <b-col class="middle" col cols="12" xl="2">
          <b-button v-if="!isActive" id="product-inventory-new-btn" variant="kag-dark-blue" block size="sm" @click="newInventory" :disabled="!tanksExist">New Inventory</b-button>
          <b-button v-if="isActive" id="product-inventory-cancel-btn" variant="kag-dark-blue" block size="sm" @click="cancelInventory">Cancel</b-button>
        </b-col>
        <b-col col cols="12" xl="8" class="inventory-time">
          <b-row>
            <b-col col cols="12" xl="6">
              <b-input-group>
                <b-form-input
                  id="product-inventory-date"
                  v-model="inventoryDateString"
                  type="text"
                  size="sm"
                  placeholder="MM/DD/YY"
                  autocomplete="off"
                  maxlength="8"
                  :disabled="!isActive"
                  :state="dateState"
                  @input="onInventoryDateChange"
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    button-only
                    left
                    id="product-inventory-date-icon"
                    size="sm"
                    :max="now"
                    value-as-date
                    hide-header
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="en"
                    :disabled="!isActive"
                    v-model="inventoryDateTime"
                    @context="onDateContext"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col col cols="12" xl="6">
              <b-input-group>
                <b-form-input
                  id="product-inventory-date"
                  v-model="inventoryTimeString"
                  type="text"
                  size="sm"
                  placeholder="HH:MM"
                  autocomplete="off"
                  maxlength="5"
                  :disabled="!isActive"
                  :state="timeState"
                  @input="onInventoryTimeChange"
                ></b-form-input>
                <b-input-group-append>
                  <b-form-timepicker
                    button-only
                    left
                    id="product-inventory-time-icon"
                    size="sm"
                    hide-header
                    no-close-button
                    locale="en-US-u-hc-h23"
                    :hour12="false"
                    :disabled="!isActive"
                    v-model="inventoryTime"
                    @context="onTimeContext"
                  ></b-form-timepicker>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="middle" col cols="12" xl="2">
          <b-button id="product-inventory-save-btn" variant="kag-dark-blue" block size="sm" :disabled="!isActive || newInventoryState || dateState === false || timeState === false" @click="saveInventory">Save</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col col>
          <b-table
            id="inventory-table"
            class="inventory-table"
            bordered
            small
            no-border-collapse
            responsive="lg"
            :tbody-tr-class="rowClass"
            primary-key="id"
            :items="mappedTanks"
          :fields="computedFields"
          >
            <template slot="top-row">
              <td id="product-inventory-no-records" v-if="!mappedTanks || mappedTanks.length === 0" colspan="5" class="text-center align-middle">
                No records to display
              </td>
            </template>

          <template #cell(editAction)="row">
            <div>
              <!-- v-if="canDeleteTank" -->
              <b-button v-if="UITankEditor" variant="kag-red" class="delete-btn" size="sm" @click="deleteTank(row.item)" v-b-tooltip.hover="'Delete'">
                <font-awesome-icon class="clickable" icon="trash" />
              </b-button>
              <!-- v-if="canUpdateTank" -->
              <b-button variant="kag-light-blue" class="mr-2 edit-btn" size="sm" @click="$event => editTank(row.item)" v-b-tooltip.hover="'Edit'">
                <font-awesome-icon class="clickable" icon="pen-to-square" />
              </b-button>
            </div>
          </template>

            <template #cell(threshold)="row">
                <div>
                  {{ row.item.threshold }}
                </div>
            </template>

            <template #cell(inventory)="row">
              <b-form-group
                :id="'inventory-fieldset' + row.index"
                class="inventory-form-group"
                v-bind:class="{ 'warn': !!inventoryWarnState(row.item), 'danger': !!inventoryDangerState(row.item) }"
              >
                <b-form-input
                  :id="'inventory' + row.index"
                  class="inventory-input"
                  size="sm"
                  v-model="row.item.inventory"
                  type="number"
                  min="0"
                  :max="row.item.tankSize"
                  :disabled="!isActive || row.item.outOfService"
                  :class="{'oos-disabled': row.item.outOfService }"
                ></b-form-input>
                <font-awesome-icon
                  class="inventory-icon"
                  icon="circle-info"
                  v-if="!!inventoryWarnState(row.item)"
                  v-b-tooltip.hover="inventoryTooltipState(row.item)"
                />
              </b-form-group>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </div>
    <!-- v-if="canCreateTank" -->
    <div>
            <b-row>
        <b-col col cols="3" xl="2">
          <b-button v-if="UITankEditor" id="product-newTank-new-btn" variant="kag-dark-blue" block size="sm" @click="openForm">New Tank</b-button>
              </b-col>
            </b-row>
  </div>

    <!-- Tanks' threshold edit modal -->
    <b-modal ref="tankEditModal" id="tank-edit-modal" hide-footer centered :title="tankEditData.title">
      <ThresholdForm
      @cancelModal="cancelModal"
      @onSubmit="updateTankThreshold"
      :tankEditData="tankEditData"
      ></ThresholdForm>
    </b-modal>

    <!-- Tanks' Creation MODAL  -->
    <b-modal ref="newTankModal" id="tank-create-modal" hide-footer centered :title="TankData.title" @hide="closeModal">
      <TankForm
        @closeModal="closeModal"
        @onCreate="createTank"
        @onUpdate="updateTank"
        :TankData="TankData"
      ></TankForm>
    </b-modal>

    <!-- Tanks' deletion modal -->
    <b-modal ref="tankDeleteModal" id="tank-delete-modal" hide-footer centered :title="tankDeleteData.title">
      <DeleteForm
        :tankDeleteData="tankDeleteData"
        :deleteText="deleteText"
        @cancelModal="closeDeleteModal"
        @onConfirm="confirmDeleteModal"
      ></DeleteForm>
    </b-modal>

  </div>
</template>

<script>
import moment from 'moment-timezone'

import global from '@/mixins/shared/global'
import userAuthGetters from '@/mixins/shared/userAuthGetters'
import toastMixin from '@/mixins/shared/toastMixin'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
import TankForm from './Tanks/TankForm.vue'
import ThresholdForm from './Tanks/ThresholdForm.vue'
import DeleteForm from './Tanks/DeleteForm.vue'

const TankRepo = RepositoryFactory.get('TankRepository')
const InventoryRepo = RepositoryFactory.get('InventoryRepository')

export default {
  name: 'ProductInventory',
  mixins: [
    userAuthGetters,
    toastMixin],
  components: {
    TankForm, ThresholdForm, DeleteForm
  },
  data () {
    return {
      inventoryFields: [
        { key: 'editAction', label: 'Edit', class: 'align-middle' },
        { key: 'tankNumber', label: 'Tank #', class: 'align-middle' },
        { key: 'displayProduct', label: 'Product', class: 'align-middle' },
        { key: 'commodityCode', label: 'Commodity Code', class: 'align-middle' },
        { key: 'safefillQty', label: 'Safe Fill', class: 'align-middle' },
        { key: 'tankSize', label: 'Phys. Size', class: 'align-middle' },
        { key: 'pumpStopQty', label: 'Pump Stop', class: 'align-middle' },
        { key: 'threshold', label: 'Threshold', class: 'align-middle' },
        { key: 'inventory', label: 'Inventory' }
      ],
      mappedTanks: [],
      now: new Date(),
      isActive: false,
      submitButtonIsDisabled: true,
      inventoryDateTime: null,
      inventoryDateString: '',
      inventoryTime: '',
      inventoryTimeString: '',
      selection: {},
      tankEditData: {
        title: '',
        tank: {}
      },
      TankData: {
        title: '',
        type: 'new',
        tank: {
          tankNumber: '',
          product: '',
          commodity: '',
          commodityCode: '',
          commodityClass: '',
          threshold: '',
          safefillQty: '',
          pumpStopQty: '',
          sourceSystemId: '',
          tankSize: '',
          id: null
        }
      },
      deleteText: '',
      tankDeleteData: {
        title: '',
        productNameGiven: '',
        tank: {}
      },
      thresholdAfter: 0,
      thresholdBefore: 0
    }
  },
  computed: {
    computedFields () {
      return this.inventoryFields
    },
    tanksExist () {
      return this.tankItems.length > 0
    },
    newInventoryState () {
      return !!this.mappedTanks.find((t) => t.inventory > t.tankSize || !!(t.inventory % 1) || t.inventory < 0)
    },
    dateState () {
      if (this.inventoryDateString.match(global.shortDateRegex)) {
        const now = new Date()
        if (this.inventoryDateTime && this.inventoryDateTime.getTime() <= now.getTime()) {
          return null
        }
      }

      return false
    },
    timeState () {
      if (this.inventoryTimeString.match(global.time23Regex)) {
        const now = new Date()
        const time = this.inventoryTime.split(':')
        const date = new Date(
          this.inventoryDateTime.getFullYear(),
          this.inventoryDateTime.getMonth(),
          this.inventoryDateTime.getDate(),
          +time[0],
          +time[1]
        )
        if (date.getTime() <= now.getTime()) {
          return null
        }
      }

      return false
    },
    shouldShowToggle () {
      return !this.UITankEditor
    },
    shouldShowSuccessMessage () {
      return !(this.thresholdAfter === this.thresholdBefore)
    }
  },
  props: {
    tankItems: Array,
    timeZoneId: String,
    site: Object
  },
  watch: {
    tankItems (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isActive = false
        this.mapTanks()
      }
    }
  },
  created () {
    this.mapTanks()
    this.fillDates()
  },
  methods: {
    rowClass (item, type) {
      if (item && item.outOfService) {
        return 'table-row table-warning'
      } else {
        return 'table-row'
      }
    },
    cancelModal () {
      this.tankEditData.title = ''
      this.tankEditData.tank = {}
      this.$refs.tankEditModal.hide()
    },
    closeDeleteModal () {
      this.$refs.tankDeleteModal.hide()
    },
    openForm () {
      // if (this.canCreateTank) {
      this.TankData.title = 'Create a new tank'
      this.TankData.type = 'new'
      this.TankData.tank = {
        threshold: 0,
        pumpStopQty: 0
      }
      this.$refs.newTankModal.show()
      // } else {
      //   this.showError('Current user does not have permissions to create a new tank')
      // }
    },
    editTank (tankItem) {
      // if (this.canUpdateTank) {
      this.TankData.type = 'edit'
      this.TankData.tank = {
        companyId: tankItem.companyId,
        sourceSystemId: tankItem.sourceSystemId,
        tankNumber: tankItem.tankNumber,
        product: tankItem.displayProduct,
        tankSize: tankItem.tankSize,
        threshold: tankItem.threshold,
        safefillQty: tankItem.safefillQty,
        pumpStopQty: tankItem.pumpStopQty,
        id: tankItem.id,
        checksum: tankItem.checksum,
        commodity: tankItem.commodity,
        commodityCode: tankItem.commodityCode,
        commodityClass: tankItem.commodityClass,
        isDeleted: false,
        outOfService: tankItem.outOfService
      }

      this.TankData.title = `Editing ${this.TankData.tank.product} Tank`
      if (this.shouldShowToggle) {
        this.editItem(tankItem)
      } else {
        this.$refs.newTankModal.show()
      }
      // } else {
      //   this.showError('Current user does not have permissions to edit tank threshold data')
      // }
    },
    confirmDeleteModal () {
      TankRepo.deleteTank(this.tankDeleteData.tank.companyUniqueId, this.tankDeleteData.tank.tankNumber)
        .then(() => {
          this.showSuccess(`Tank "${this.tankDeleteData.tank.product}" was deleted successfully`)
          const tanke = this.tankItems.find((mp) => mp.id === this.tankDeleteData.tank.id)
          const index = this.tankItems.findIndex((tank, index) => tank === tanke && index >= 0)
          if (tanke) {
            this.tankItems.splice(index, 1)
            this.mapTanks()
          }
        })
        .catch((error) => {
          this.showError(global.handleError(error, 'An error occurred while deleting the tank. Please try again later'))
        })
        .finally(() => {
          this.$refs.tankDeleteModal.hide()
          this.$emit('refresh-calculator')
        })
    },
    deleteTank (tankItem) {
      // if (this.canDeleteTank) {
      const tankDeletion = {
        id: tankItem.id,
        product: tankItem.displayProduct,
        companyUniqueId: this.site.companyUniqueId,
        tankNumber: tankItem.tankNumber
      }
      this.tankDeleteData.title = `Delete Tank: ${tankDeletion.product}`
      this.tankDeleteData.tank = tankDeletion
      this.deleteText = `Are you sure you want to delete Tank ${tankDeletion.product}?`
      this.$refs.tankDeleteModal.show()
      // } else {
      //   this.showError('Current user does not have permissions to delete tank')
      // }
    },
    createTank () {
      this.TankData.tank.companyId = this.site.id
      if (this.TankData.type === 'new') {
        TankRepo.createTank(this.TankData.tank)
          .then(response => {
            this.showSuccess('New Tank added successfully')
            this.tankItems.push(response.data)
            this.mapTanks()
            this.TankData.title = ''
            this.TankData.tank = {}
            this.TankData.type = 'new'
            this.$refs.newTankModal.hide()
            this.$emit('refresh-calculator')
            this.$emit('active-tab-action')
          })
          .catch(error => {
            this.showError(global.handleError(error, 'An error occurred while creating the tank. Please try again later', 'tank'))
          })
      } else {
        this.updateTank()
      }
    },
    updateTank () {
      TankRepo.updateTank(this.TankData.tank.id, this.TankData.tank)
        .then(() => {
          this.showSuccess('Tank was updated successfully')
          const tank = this.mappedTanks.find((mp) => mp.id === this.TankData.tank.id)
          if (tank) {
            tank.displayProduct = this.TankData.tank.commodity
            tank.commodity = this.TankData.tank.commodity
            tank.commodityClass = this.TankData.tank.commodityClass
            tank.commodityCode = this.TankData.tank.commodityCode
            tank.pumpStopQty = this.TankData.tank.pumpStopQty
            tank.safefillQty = this.TankData.tank.safefillQty
            tank.tankNumber = this.TankData.tank.tankNumber
            tank.tankSize = this.TankData.tank.tankSize
            tank.threshold = this.TankData.tank.threshold
          }
          this.mapTanks()
          this.TankData.title = ''
          this.TankData.tank = {}
          this.TankData.type = 'new'
          this.$refs.newTankModal.hide()
          this.$emit('refresh-calculator')
          this.$emit('active-tab-action')
        })
        .catch((error) => {
          this.showError(global.handleError(error, 'An error occurred while updating the tank. Please try again later', 'tank'))
        })
    },
    editItem (tankItem) {
      // if (this.canUpdateTank) {
      const newTankEdition = {
        product: tankItem.displayProduct,
        threshold: tankItem.threshold,
        safefillQty: tankItem.safefillQty,
        pumpStopQty: tankItem.pumpStopQty,
        id: tankItem.id,
        outOfService: tankItem.outOfService
      }
      this.tankEditData.title = `Editing Threshold for ${newTankEdition.product}`
      this.tankEditData.tank = newTankEdition
      this.thresholdBefore = this.tankEditData.tank.threshold
      this.$refs.tankEditModal.show()
      // } else {
      //   this.showError('Current user does not have permissions to edit tank threshold data')
      // }
    },
    closeModal () {
      this.TankData.title = ''
      this.TankData.tank = {}
      this.$refs.newTankModal.hide()
    },
    updateTankThreshold () {
      const threshold = this.tankEditData.tank.threshold > 0 ? this.tankEditData.tank.threshold : 0
      const outOfService = this.tankEditData.tank.outOfService ?? false
      this.thresholdAfter = this.tankEditData.tank.threshold
      TankRepo.updateTankThresholdAndOutOfService(this.tankEditData.tank.id, threshold, outOfService)
        .then(() => {
          if (this.shouldShowSuccessMessage) {
            this.showSuccess('Tank threshold updated successfully')
          }
          const tank = this.mappedTanks.find((mp) => mp.id === this.tankEditData.tank.id)
          if (tank) {
            tank.threshold = threshold
            tank.outOfService = outOfService
          }
        })
        .catch((error) => {
          this.showError(global.handleError(error, 'An error occurred while updating tank threshold. Please try again later'))
        })
        .finally(() => {
          this.tankEditData.title = ''
          this.tankEditData.tank = {}
          this.$refs.tankEditModal.hide()
        })
    },
    mapTanks () {
      this.mappedTanks = this.tankItems.map((tank) => {
        const totalProducts = this.tankItems.filter((t) => t.commodity === tank.commodity).length
        if (totalProducts > 1) {
          tank.displayProduct = `${tank.commodity} (${tank.tankNumber})`
        } else {
          tank.displayProduct = tank.commodity
        }
        return tank
      })
    },
    newInventory () {
      this.fillDates()
      this.isActive = true
    },
    cancelInventory () {
      this.fillDates()
      this.mappedTanks.forEach((t) => {
        delete t.inventory
      })
      this.isActive = false
    },
    saveInventory () {
      const allInventory = this.mappedTanks.map((tank) => {
        return {
          inventoryExists: tank.inventory || tank.outOfService ? 1 : 0
        }
      })
      var blankInventory = allInventory.filter(function (inv) {
        if (inv.inventoryExists === 0) {
          return {
            inventoryExists: inv.inventoryExists
          }
        }
      })
      if (blankInventory.length > 0) {
        this.showWarning('Inventory cannot be blank. Please enter a value to save.')
        return false
      }
      const time = this.inventoryTime.split(':')
      const dateObj = {
        year: this.inventoryDateTime.getFullYear(),
        month: this.inventoryDateTime.getMonth(),
        day: this.inventoryDateTime.getDate(),
        hour: +time[0],
        minute: +time[1],
        second: 0
      }
      const date = moment.tz(dateObj, this.timeZoneId)

      const inventories = this.mappedTanks.map((tank) => {
        return {
          tankId: tank.id,
          inventoryReading: tank.inventory ? +tank.inventory : 0,
          inventoryTimeStamp: date.format('yyyy-MM-DDTHH:mm:ss')
        }
      })

      InventoryRepo.create(inventories)
        .then((response) => {
          if (response.data && response.data.length) {
            const tankNames = []
            response.data.forEach((d) => {
              const tank = this.mappedTanks.find((mt) => mt.id === d.tankId)
              if (tank) {
                tankNames.push(tank.displayProduct)
              }
            })
            this.showError(`Could not create Inventory for tanks ${tankNames.join(', ')} because an Inventory with the same date already exists`)
          } else {
            this.showSuccess('Inventory created successfully')
          }
          this.$emit('inventory-added')
          this.$emit('refresh-calculator')
          this.mapTanks()
          this.fillDates()
          this.isActive = false
        })
        .catch((error) => {
          this.showError(global.handleError(error, 'An error occurred while trying to create new Inventory. Please try again later'))
        })
    },
    fillDates () {
      const now = new Date()
      this.now = now
      this.inventoryDateTime = new Date(now)
      this.inventoryDateTime.setHours(0)
      this.inventoryDateTime.setMinutes(0)
      this.inventoryDateTime.setSeconds(0)
      this.inventoryDateTime.setMilliseconds(0)
      this.inventoryDateString = `${('0' + (+now.getMonth() + 1)).slice(-2)}/${('0' + now.getDate()).slice(-2)}/${now.getFullYear().toString().substring(2, 4)}`
      this.inventoryTime = `${('0' + now.getHours()).slice(-2)}:${('0' + now.getMinutes()).slice(-2)}:00`
      this.inventoryTimeString = `${('0' + now.getHours()).slice(-2)}:${('0' + now.getMinutes()).slice(-2)}`
    },
    thresholdTooltipState (tank) {
      if (tank.threshold % 1) {
        return 'Threshold cannot contain decimal places'
      } else if (tank.threshold < 0) {
        return 'Threshold cannot be negative number'
      } else if (tank.threshold > tank.safefillQty) {
        return 'Threshold is greater than Safe Fill'
      }
    },
    thresholdWarnState (tank) {
      return tank.threshold > tank.safefillQty || !!(tank.threshold % 1) || tank.threshold < 0
    },
    inventoryWarnState (tank) {
      return tank.inventory > tank.safefillQty || !!(tank.inventory % 1) || tank.inventory < 0
    },
    inventoryDangerState (tank) {
      return tank.inventory > tank.tankSize || !!(tank.inventory % 1) || tank.inventory < 0
    },
    inventoryTooltipState (tank) {
      if (tank.inventory > tank.tankSize) {
        return 'Inventory cannot be greater than Physical Size'
      } else if (tank.inventory % 1) {
        return 'Inventory cannot contain decimal places'
      } else if (tank.inventory < 0) {
        return 'Inventory cannot be negative number'
      } else if (tank.inventory > tank.safefillQty) {
        return 'Inventory is greater than Safe Fill'
      }
    },
    onDateContext (ctx) {
      this.inventoryDateString = `${('0' + (+ctx.selectedDate.getMonth() + 1)).slice(-2)}/${('0' + ctx.selectedDate.getDate()).slice(-2)}/${ctx.selectedDate.getFullYear().toString().substring(2, 4)}`
    },
    onInventoryDateChange (text) {
      if (text.match(global.sixDigitRegex)) {
        this.inventoryDateString = `${text.substring(0, 2)}/${text.substring(2, 4)}/${text.substring(4, 6)}`
        this.onInventoryDateChange(this.inventoryDateString)
      } else {
        if (text.match(global.shortDateRegex)) {
          const split = text.split('/')
          this.inventoryDateTime = new Date((+split[2] + 2000), +split[0] - 1, +split[1])
          this.inventoryDateTime.setHours(0)
          this.inventoryDateTime.setMinutes(0)
          this.inventoryDateTime.setSeconds(0)
          this.inventoryDateTime.setMilliseconds(0)
        }
      }
    },
    onTimeContext (ctx) {
      this.inventoryTimeString = ctx.formatted
    },
    onInventoryTimeChange (text) {
      if (text.match(global.fourDigitRegex)) {
        this.inventoryTimeString = `${text.substring(0, 2)}:${text.substring(2, 4)}`
        this.onInventoryTimeChange(this.inventoryTimeString)
      } else {
        if (text.match(global.time23Regex)) {
          const [hours, minutes] = text.split(':')
          this.inventoryTime = `${hours}:${minutes}:00`
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/scss/themes/theme';

.threshold-form-group {
  &.warn {
   .threshold-input {
     display: inline-block;
     width: calc(100% - 30px);
     margin-right: 5px;
   }
  .threshold-icon {
    display: inline-block;
    color: $kag-warning-color;
   }
  }
}

.product-inventory-container {
  .top {
    @media (min-width: 1200px) {
      height: 50px;
    }

    .col {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .middle {
      align-self: center;
    }
  }

  .inventory-table {
    .table-row {
      height: 35px;

      td {
        padding-top: 1px;
        padding-bottom: 1px;

        .inventory-form-group {
          &.warn {
            .inventory-input {
              display: inline-block;
              width: calc(100% - 20px);
              margin-right: 5px;
            }

            .inventory-icon {
              color: $kag-warning-color;
            }
          }

          &.danger {
            .inventory-input {
              border-color: $kag-red-color;

              &:focus {
                box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
                background-position: right calc(0.375em + 0.1875rem) center;
              }
            }

            .inventory-icon {
              color: $kag-red-color;
            }
          }
        }
      }

      .align-middle {
        vertical-align: middle;
      }

      .form-group {
        margin-bottom: 0;
      }
    }
    .btn {
        float: right;
        font-size: 0.475rem;
    }
  }
}
</style>

<template>
  <div id="inventory-edit" class="inventory-edit">
    <b-modal ref="inventoryEditModal" size="md" no-close-on-backdrop hide-footer :static="true" title="Inventory Edit">
      <b-container>
        <b-form class="inventory-edit-form" @submit.prevent="update">
          <b-row>
            <b-col col cols="12">
              <b-form-group
                id="product-date-group"
                label-cols-sm="4"
                label-cols-lg="3"
                content-cols-sm
                content-cols-lg="7"
                label="Date"
                label-for="input-horizontal"
              >
                <b-form-input id="product-date" v-model="dateTime" trim disabled></b-form-input>
              </b-form-group>
            </b-col>
            <b-col col cols="12" v-for="product in products" :key="product.name">
              <b-form-group
                :id="'group' + product.name"
                label-cols-sm="4"
                label-cols-lg="3"
                content-cols-sm
                content-cols-lg="7"
                :label="product.name"
                :label-for="product.name"
                class="inventory-form-group"
                v-bind:class="{ 'warn': !!inventoryWarnState(product), 'danger': !!inventoryDangerState(product) }"
              >
                <b-form-input
                  :id="product.name"
                  type="number"
                  v-model="product.inventoryReading"
                  min="0"
                  required
                  :disabled="product.outOfService"
                  class="inventory-input"
                  :class="{'oos-disabled': product.outOfService }">
                </b-form-input>
                <font-awesome-icon
                  class="inventory-icon"
                  icon="circle-info"
                  v-if="!!inventoryWarnState(product)"
                  v-b-tooltip.hover="inventoryTooltipState(product)"
                />
              </b-form-group>
            </b-col>
            <b-col col cols="12" offset-lg="2" lg="8">
              <div class="d-flex">
                <b-button id="inventory-edit-btn" class="mt-2 mr-3" type="submit" variant="outline-kag-dark-blue" block size="sm" :disabled="productProblems()">Update</b-button>
                <b-button id="inventory-cancel-btn"  variant="outline-kag-red" block size="sm" @click="cancel">Cancel</b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
    </b-modal>
  </div>
</template>

<style lang="scss">
@import '~@/assets/scss/themes/theme';
  .inventory-form-group {
          &.warn {
            .inventory-input {
              display: inline-block;
              width: calc(100% - 20px);
              margin-right: 5px;
            }
            .inventory-icon {
              color: $kag-warning-color;
              display: inline;
            }
          }
          &.danger {
            .inventory-input {
              border-color: $kag-red-color;
              &:focus {
                box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
                background-position: right calc(0.375em + 0.1875rem) center;
              }
            }
            .inventory-icon {
              color: $kag-red-color;
              display: inline;
            }
          }
        }

</style>

<script>
import moment from 'moment-timezone'
import toastMixin from '@/mixins/shared/toastMixin'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const InventoryRepo = RepositoryFactory.get('InventoryRepository')

export default {
  name: 'InventoryEdit',
  mixins: [toastMixin],
  data () {
    return {
      products: [],
      dateTime: '',
      timeZoneId: '',
      // Private variables
      resolvePromise: () => {}
    }
  },
  mounted () {
    this.$root.$on('bv::modal::hide', (bvEvent) => {
      if (bvEvent.vueTarget._uid - 1 === this._uid) {
        this.clear()
      }
    })
  },
  methods: {
    show (opts = {}) {
      if (opts.existingTanks) {
        this.timeZoneId = opts.timeZoneId
        opts.existingTanks.forEach((tank) => {
          let name = tank.commodityClass
          if (opts.existingTanks.filter((t) => t.commodityClass === name).length > 1) {
            name += ` (${tank.tankNumber})`
          }

          const productItem = opts.item[name]
          if (productItem) {
            this.dateTime = moment(new Date(productItem.inventoryTimeStamp)).format('ddd MM/DD/yyyy HH:mm')
            this.products.push({
              id: productItem.inventoryId,
              name: productItem.name,
              inventoryReading: productItem.inventoryReading,
              outOfService: tank.outOfService,
              tankSize: tank.tankSize,
              safefillQty: tank.safefillQty,
              hasProblem: false
            })
          } else {
            this.products.push({
              tankId: tank.id,
              name,
              inventoryReading: null,
              outOfService: tank.outOfService,
              tankSize: tank.tankSize,
              safefillQty: tank.safefillQty,
              hasProblem: false
            })
          }
        })
      }
      this.$refs.inventoryEditModal.show()

      return new Promise((resolve) => {
        this.resolvePromise = resolve
      })
    },
    clear () {
      this.products = []
      this.dateTime = ''
      this.timeZoneId = ''
    },
    cancel () {
      this.clear()
      this.$refs.inventoryEditModal.hide()
      this.resolvePromise(false)
    },
    async update () {
      try {
        await Promise.all(
          this.products.map(async (p) => {
            if (p.id) {
              return await InventoryRepo.update({ id: p.id, inventoryReading: p.inventoryReading })
            } else {
              const dateTimeString = this.dateTime.split(' ')
              const dateString = dateTimeString[1].split('/')
              const timeString = dateTimeString[2].split(':')
              const dateObj = {
                year: +dateString[2],
                month: +dateString[0] - 1,
                day: +dateString[1],
                hour: +timeString[0],
                minute: +timeString[1],
                second: 0
              }
              const date = moment.tz(dateObj, this.timeZoneId)
              return await InventoryRepo.create([{
                tankId: p.tankId,
                inventoryReading: p.inventoryReading ? +p.inventoryReading : 0,
                inventoryTimeStamp: date.format('yyyy-MM-DDTHH:mm:ss')
              }])
            }
          })
        )
        this.showSuccess('Inventories updated successfully')
        this.resolvePromise({
          dateTime: this.dateTime,
          products: this.products
        })
        this.clear()
        this.$refs.inventoryEditModal.hide()
      } catch (error) {
        this.showError('An error occurred while updating some inventories. Please try again later')
        this.resolvePromise({
          dateTime: this.dateTime,
          products: this.products
        })
        this.clear()
        this.$refs.inventoryEditModal.hide()
      }
    },
    inventoryWarnState (tank) {
      var result = tank.inventoryReading > tank.safefillQty || !!(tank.inventoryReading % 1) || tank.inventoryReading < 0
      return result
    },
    inventoryDangerState (tank) {
      var result = tank.inventoryReading > tank.tankSize || !!(tank.inventoryReading % 1) || tank.inventoryReading < 0
      tank.hasProblem = result
      return result
    },
    inventoryTooltipState (tank) {
      if (tank.inventoryReading > tank.tankSize) {
        return `Inventory cannot be greater than Physical Size (${tank.tankSize})`
      } else if (tank.inventoryReading % 1) {
        return 'Inventory cannot contain decimal places'
      } else if (tank.inventoryReading < 0) {
        return 'Inventory cannot be negative number'
      } else if (tank.inventoryReading > tank.safefillQty) {
        return `Inventory is greater than Safe Fill (${tank.safefillQty})`
      }
    },
    productProblems () {
      var output = false
      this.products.forEach((tank) => {
        if (tank.hasProblem) {
          output = true
        }
      })
      return output
    }
  }
}
</script>

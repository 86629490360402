import Configuration from '@/configuration/configurations'
export default {
  basePath: Configuration.UiApiUrl,
  appConfig: Configuration,
  handleError: (error, msg, itemType) => {
    const res = error && error.response ? error.response : {}

    if (res) {
      if (res.status === 400) {
        return `Cannot add this ${itemType} as it already exists.`
      }

      if (res.status === 404) {
        return 'Service is not available. Please try again later'
      }
    }

    return res.data && res.data.message ? res.data.message : msg
  },
  mapUpperCase: (property) => {
    return (i) => {
      i[property] = i[property] ? i[property].toUpperCase() : i[property]
      return i
    }
  },
  sortBy: (property) => {
    return (a, b) => {
      const nameA = a[property].toUpperCase()
      const nameB = b[property].toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    }
  },
  sortIntBy: (property) => {
    return (a, b) => {
      const nameA = a[property]
      const nameB = b[property]
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    }
  },
  mapOptions: (value, text) => {
    return (i) => {
      return {
        value: i[value],
        html: i[text].replaceAll(' ', '&nbsp;')
      }
    }
  },
  productsMapOptions: (value, text) => {
    return (i) => {
      const cmdDescClass = i[text].replaceAll(' ', '&nbsp;')
      return {
        value: i[value],
        html: `${cmdDescClass}`
      }
    }
  },
  codesMapOptions: (value, text) => {
    return {
      value: value,
      html: `${text}`
    }
  },
  formatPhone: (phone) => {
    if (!phone) {
      return phone
    }
    const cleaned = phone.trim().replace(/\D/g, '')
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return `${match[1]} ${match[2]} ${match[3]}`
    }

    return phone
  },
  buildShortDateString: (dateObj) => {
    return `${('0' + (+dateObj.getMonth() + 1)).slice(-2)}/${('0' + dateObj.getDate()).slice(-2)}/${dateObj.getFullYear().toString().substring(2, 4)}`
  },
  buildDateString: (dateObj) => {
    return `${('0' + (+dateObj.getMonth() + 1)).slice(-2)}/${('0' + dateObj.getDate()).slice(-2)}/${dateObj.getFullYear()}`
  },
  fourDigitRegex: /^[0-9]{4}$/,
  sixDigitRegex: /^[0-9]{6}$/,
  eightDigitRegex: /^[0-9]{8}$/,
  shortDateRegex: /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/\d{2}$/,
  dateRegex: /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
  time23Regex: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
  time12Regex: /^(1[0-2]|0?[1-9]):([0-5][0-9]) (AM|PM)$/
}

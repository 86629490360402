<template>
  <div v-if="showBanner" class="container text-center environment-banner-container">
    <b-nav-text class="environment-banner">
    You are currently in the {{instance}} Environment.
    </b-nav-text>
  </div>
</template>

<style lang="scss" scope>
.environment-banner {
  background-color: rgb(219, 219, 88);
  color: black !important;
  font-weight: bold;
}
.environment-banner-container {
  display:inline-block !important;
  width: 500px !important;
}
</style>

<script>
import global from '@/mixins/shared/global'

export default {
  name: 'EnvironmentBanner',
  computed: {
    instance () {
      return global.appConfig.InternalEnvironment
    },
    showBanner () {
      return global.appConfig.ShowEnvironmentBanner
    }
  }
}
</script>

import global from '@/mixins/shared/global'
import Repository from './Repository'
const inventoryRepo = Repository(global.basePath)

const resource = '/Inventory'

export default {
  create (payload) {
    return inventoryRepo.post(`${resource}`, payload)
  },
  update (payload) {
    return inventoryRepo.put(`${resource}`, payload)
  },
  delete (id) {
    return inventoryRepo.delete(`${resource}/${id}`)
  }
}

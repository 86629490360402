<template>
  <div class="catalog-container text-center">
    <!-- Content -->
    <div v-if="canCreate" class="mb-3">
      <b-button id="add-btn" variant="kag-dark-blue" @click="newItem()">
        Add {{ itemName }}
      </b-button>
    </div>

    <!-- Filter -->
    <b-row>
      <b-col offset-xl="4" col xl="4" offset-md="2" md="8">
        <b-form-group
          id="filter-fieldset"
          label="Filter"
          label-class="font-weight-bold"
          label-for="filter-input"
          label-cols-md="3"
        >
          <b-form-input id="filter-input" type="search" placeholder="Search" v-model="filter"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col offset-xl="3" col xl="6">
        <b-table
          id="catalog-table"
          class="catalog-table"
          bordered
          small
          no-border-collapse
          responsive="lg"
          primary-key="id"
          :items="items"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="filter"
          @filtered="onFiltered"
          :filterIncludedFields="filterIncludedFields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
        >
          <template #cell(description)="row" >
            <div v-html="$options.filters.uppercase(row.item.description)" class="keep-spaces"></div>
          </template>
          <template #cell(actions)="row">
            <b-button v-if="canUpdate" variant="kag-light-blue" class="mr-2 edit-btn" size="sm" @click="editItem(row.item)" v-b-tooltip.hover="'Edit'">
              <font-awesome-icon class="clickable" icon="pen-to-square" />
            </b-button>
            <b-button v-if="canDelete" variant="kag-red" class="delete-btn" size="sm" @click="deleteCompany(row.item)" v-b-tooltip.hover="'Delete'">
              <font-awesome-icon class="clickable" icon="trash" />
            </b-button>
          </template>
        </b-table>
        <label id="no-records" v-if="items.length === 0">There are no records to show</label>
      </b-col>
    </b-row>

    <b-row>
      <!-- Pagination -->
      <b-col col sm="6" offset-xl="3" xl="3" id="catalog-pagination" class="pagination-container">
        <b-pagination
          class="justify-content-center"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
        ></b-pagination>
      </b-col>
      <!-- Per Page -->
      <b-col col sm="6" offset-xl="1" xl="2" id="catalog-row-per-page">
        <b-form-select
          class="justify-content-center"
          v-model="perPage"
          :options="pageOptions"
          size="sm"
        ></b-form-select>
      </b-col>
    </b-row>

    <!-- Edit modal -->
    <b-modal ref="editModal" id="catalog-edit-modal" hide-footer centered :title="itemModal.title">
      <b-form class="edit-form" @submit.prevent="onSubmit">
        <b-container>
          <b-row>
            <b-col col>
              <b-form-group
                id="uniqueId-fieldset"
                label-for="uniqueId"
                :state="uniqueIdState"
                :invalid-feedback="uniqueIdFeedback"
              >
              <template v-slot:label>
                Unique ID <span class="text-danger">*</span>
              </template>
                <b-form-input
                  ref="uniqueIdInput"
                  id="uniqueId"
                  placeholder="Unique ID"
                  maxlength="30"
                  v-model="itemModal.item.companyUniqueId"
                  :state="uniqueIdState"
                  trim
                  @keydown="checkKeyDownAlphaNumericAndHyphen($event)"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col col>
              <b-form-group
                id="companyName-fieldset"
                label-for="company-name"
                :state="companyNameState"
                :invalid-feedback="companyNameFeedback"
              >
              <template v-slot:label>
                Company Name <span class="text-danger">*</span>
              </template>
                <b-form-input
                  ref="companyNameInput"
                  id="company-name"
                  placeholder="Company Name"
                  maxlength="100"
                  :state="companyNameState"
                  v-model="itemModal.item.companyName"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="phone-fieldset"
                label="Phone"
                label-for="company-phone"
                :state="phoneState"
                :invalid-feedback="phoneFeedback"
              >
                <b-form-input
                  id="company-phone"
                  placeholder="Company phone"
                  maxlength="14"
                  v-model="itemModal.item.companyPhone"
                  @input="acceptNumber"
                  :state="phoneState"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col col>
              <b-form-group
                id="company-address-fieldset"
                label-for="company-address1"
                :state="address1State"
                :invalid-feedback="address1Feedback"
              >
                <template v-slot:label>
                  Address <span class="text-danger">*</span>
                </template>
                <b-form-input id="company-address1" placeholder="Address" v-model="itemModal.item.companyAddressLine1" :state="address1State" trim></b-form-input>
                <b-form-input id="company-address2" placeholder="Address" v-model="itemModal.item.companyAddressLine2" trim></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="city-fieldset"
                label-for="company-city"
                :state="cityState"
                :invalid-feedback="cityFeedback"
              >
                <template v-slot:label>
                  City <span class="text-danger">*</span>
                </template>
                <b-form-input id="company-city" placeholder="City" v-model="itemModal.item.city" :state="cityState" trim></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group
                id="company-state-fieldset"
                label-for="company-state"
                :state="stateState"
                :invalid-feedback="stateFeedback"
              >
                <template v-slot:label>
                  State <span class="text-danger">*</span>
                </template>
                <b-form-select id="company-state" v-model="itemModal.item.state" :options="states" :state="stateState"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="zip-fieldset"
                label-for="company-zip"
                :state="zipState"
                :invalid-feedback="zipFeedback"
              >
                <template v-slot:label>
                  Zip <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="company-zip"
                  placeholder="Zip Code"
                  maxlength="10"
                  v-model="itemModal.item.zip"
                  :state="zipState"
                  trim
                  @keydown="checkKeyDownAlphaNumeric($event)"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col>
              <b-form-checkbox
                id="accountOf"
                v-model="itemModal.item.isAccountOf"
                value="Y"
                unchecked-value="N"
                :class="{ 'red-check': !isAnyCheckBoxSelected }"
              >Account of</b-form-checkbox>

              <b-form-checkbox
                  id="billTo"
                  v-model="itemModal.item.isBillTo"
                  value="Y"
                  unchecked-value="N"
                  :class="{ 'red-check': !isAnyCheckBoxSelected }"
                >Bill to</b-form-checkbox>

              <b-form-checkbox
                id="shipper"
                v-model="itemModal.item.isShipper"
                value="Y"
                unchecked-value="N"
                :class="{ 'red-check': !isAnyCheckBoxSelected }"
              >Shipper</b-form-checkbox>

            </b-col>
            <b-col>
              <b-form-checkbox
                  id="forecasting"
                  v-model="itemModal.item.isActiveForForecasting"
                  value="Y"
                  unchecked-value="N"
                >Active For Forecasting</b-form-checkbox>

              <b-form-checkbox
                  id="consignee"
                  v-model="itemModal.item.isConsignee"
                  value="Y"
                  unchecked-value="N"
                  :class="{ 'red-check': !isAnyCheckBoxSelected }"
                >Consignee</b-form-checkbox>

                <b-form-checkbox
                id="supplier"
                v-model="itemModal.item.isSupplier"
                value="Y"
                unchecked-value="N"
                :class="{ 'red-check': !isAnyCheckBoxSelected }"
              >Supplier</b-form-checkbox>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" col lg="6" class="pt-2 pb-2">
              <b-button id="catalog-save-btn-modal" block variant="outline-kag-light-blue" type="submit" :disabled="!isSubmitButtonDisabled">Save</b-button>
            </b-col>
            <b-col cols="12" col lg="6" class="pt-2 pb-2">
              <b-button id="catalog-cancel-btn-modal" block variant="outline-kag-red" @click="cancelItemModal">Cancel</b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
    </b-modal>
  </div>
</template>

<style lang="scss">
@import '~@/assets/scss/themes/theme';

.catalog-container {
  .catalog-table {
    .actions {
      width: 80px;
      min-width: 80px;
      background-color: $white;
      border: none;
    }
    .keep-spaces { white-space: pre-wrap; }
  }
}
.red-check{
  label {
    color: #c22032;
  }
}
</style>

<script>
import global from '@/mixins/shared/global'
import toastMixin from '@/mixins/shared/toastMixin'

import { RepositoryFactory } from '@/repositories/RepositoryFactory'

const CompanyRepo = RepositoryFactory.get('CompanyRepository')

export default {
  name: 'CompaniesCatalog',
  mixins: [
    toastMixin
  ],
  props: {
    itemName: { type: String },
    canCreate: { type: Boolean },
    canUpdate: { type: Boolean },
    canDelete: { type: Boolean }
  },
  computed: {
    isNewItem () {
      return !!this.itemModal.item && !this.itemModal.item.id
    },
    uniqueIdState () {
      return !!this.itemModal.item.companyUniqueId
    },
    uniqueIdFeedback () {
      if (!this.itemModal.item.companyUniqueId) {
        return 'This field is required'
      }
      return ''
    },
    companyNameState () {
      return !!this.itemModal.item.companyName && this.itemModal.item.companyName.trimStart().length > 0
    },
    companyNameFeedback () {
      if (!this.itemModal.item.companyName || !this.itemModal.item.companyName.trimStart().length > 0) {
        return 'This field is required'
      }
      return ''
    },
    phoneFeedback () {
      return 'Please, type only numbers'
    },
    address1State () {
      return !!this.itemModal.item.companyAddressLine1 && this.itemModal.item.companyAddressLine1.trimStart().length > 0
    },
    address1Feedback () {
      if (!this.itemModal.item.companyAddressLine1 || !this.itemModal.item.companyAddressLine2 ||
        !this.itemModal.item.companyAddressLine1.trimStart().length > 0 ||
        !this.itemModal.item.companyAddressLine2.trimStart().length > 0) {
        return 'This field is required'
      }
      return ''
    },
    cityState () {
      return !!this.itemModal.item.city && this.itemModal.item.city.trimStart().length > 0
    },
    cityFeedback () {
      if (!this.itemModal.item.city || !this.itemModal.item.city.trimStart().length > 0) {
        return 'This field is required'
      }
      return ''
    },
    stateState () {
      return !!this.itemModal.item.state && this.itemModal.item.state.trimStart().length > 0
    },
    stateFeedback () {
      if (!this.itemModal.item.state || !this.itemModal.item.state.trimStart().length > 0) {
        return 'This field is required'
      }
      return ''
    },
    zipState () {
      return !!this.itemModal.item.zip && this.itemModal.item.zip.trimStart().length > 0
    },
    zipFeedback () {
      if (!this.itemModal.item.zip || !this.itemModal.item.zip.trimStart().length > 0) {
        return 'This field is required'
      }
      return ''
    },
    isSubmitButtonDisabled () {
      return !!(this.uniqueIdState && this.companyNameState &&
        this.address1State && this.cityState && this.stateState &&
        this.zipState && this.phoneIsValid && this.isAnyCheckBoxSelected)
    },
    isAnyCheckBoxSelected () {
      return (this.itemModal.item.isAccountOf === 'Y' ||
      this.itemModal.item.isBillTo === 'Y' ||
      this.itemModal.item.isConsignee === 'Y' ||
      this.itemModal.item.isShipper === 'Y' ||
      this.itemModal.item.isSupplier === 'Y')
    }
  },
  data () {
    return {
      fields: [
        { key: 'companyUniqueId', label: 'Unique ID', sortable: false, sortDirection: 'desc' },
        { key: 'companyName', label: 'Company Name', sortable: false, sortDirection: 'desc' },
        { key: 'actions', label: '', class: 'actions' }
      ],
      items: [],
      perPage: 10,
      pageOptions: [
        { value: 5, text: '5 per page' },
        { value: 10, text: '10 per page' },
        { value: 25, text: '25 per page' },
        { value: 50, text: '50 per page' },
        { value: 100, text: '100 per page' }
      ],
      totalRows: 0,
      currentPage: 1,
      filter: '',
      filterIncludedFields: ['companyUniqueId', 'companyName'],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      itemModal: {
        title: '',
        isCreateForm: true,
        item: {}
      },
      phoneState: null,
      phoneIsValid: true,
      states: [
        { value: 'AL', text: 'Alabama' },
        { value: 'AK', text: 'Alaska' },
        { value: 'AS', text: 'American Samoa' },
        { value: 'AZ', text: 'Arizona' },
        { value: 'AR', text: 'Arkansas' },
        { value: 'CA', text: 'California' },
        { value: 'CO', text: 'Colorado' },
        { value: 'CT', text: 'Connecticut' },
        { value: 'DE', text: 'Delaware' },
        { value: 'DC', text: 'District Of Columbia' },
        { value: 'FM', text: 'Federated States Of Micronesia' },
        { value: 'FL', text: 'Florida' },
        { value: 'GA', text: 'Georgia' },
        { value: 'GU', text: 'Guam' },
        { value: 'HI', text: 'Hawaii' },
        { value: 'ID', text: 'Idaho' },
        { value: 'IL', text: 'Illinois' },
        { value: 'IN', text: 'Indiana' },
        { value: 'IA', text: 'Iowa' },
        { value: 'KS', text: 'Kansas' },
        { value: 'KY', text: 'Kentucky' },
        { value: 'LA', text: 'Louisiana' },
        { value: 'ME', text: 'Maine' },
        { value: 'MH', text: 'Marshall Islands' },
        { value: 'MD', text: 'Maryland' },
        { value: 'MA', text: 'Massachusetts' },
        { value: 'MI', text: 'Michigan' },
        { value: 'MN', text: 'Minnesota' },
        { value: 'MS', text: 'Mississippi' },
        { value: 'MO', text: 'Missouri' },
        { value: 'MT', text: 'Montana' },
        { value: 'NE', text: 'Nebraska' },
        { value: 'NV', text: 'Nevada' },
        { value: 'NH', text: 'New Hampshire' },
        { value: 'NJ', text: 'New Jersey' },
        { value: 'NM', text: 'New Mexico' },
        { value: 'NY', text: 'New York' },
        { value: 'NC', text: 'North Carolina' },
        { value: 'ND', text: 'North Dakota' },
        { value: 'MP', text: 'Northern Mariana Islands' },
        { value: 'OH', text: 'Ohio' },
        { value: 'OK', text: 'Oklahoma' },
        { value: 'OR', text: 'Oregon' },
        { value: 'PW', text: 'Palau' },
        { value: 'PA', text: 'Pennsylvania' },
        { value: 'PR', text: 'Puerto Rico' },
        { value: 'RI', text: 'Rhode Island' },
        { value: 'SC', text: 'South Carolina' },
        { value: 'SD', text: 'South Dakota' },
        { value: 'TN', text: 'Tennessee' },
        { value: 'TX', text: 'Texas' },
        { value: 'UT', text: 'Utah' },
        { value: 'VT', text: 'Vermont' },
        { value: 'VI', text: 'Virgin Islands' },
        { value: 'VA', text: 'Virginia' },
        { value: 'WA', text: 'Washington' },
        { value: 'WV', text: 'West Virginia' },
        { value: 'WI', text: 'Wisconsin' },
        { value: 'WY', text: 'Wyoming' }
      ]
    }
  },
  async created () {
    if (!this.canUpdate && !this.canDelete) {
      this.fields = this.fields.filter((f) => f.key !== 'actions')
    }

    const sites = await CompanyRepo.getAllSites()
    this.init(sites.data)
  },
  methods: {
    checkKeyDownAlphaNumeric (event) {
      if (event.keyCode === 229) {
        event.preventDefault()
      }
      if (!/[a-zA-Z0-9\s]/.test(event.key)) {
        event.preventDefault()
      }
    },
    checkKeyDownAlphaNumericAndHyphen (event) {
      if (event.keyCode === 229) {
        event.preventDefault()
      }
      if (!/[a-zA-Z0-9\s]/.test(event.key)) {
        if (!(event.keyCode === 189)) {
          event.preventDefault()
        }
      }
    },
    acceptNumber () {
      if (this.startWithSpace(this.itemModal.item.companyPhone)) {
        this.phoneIsValid = false
        this.phoneState = false
      } else {
        const phoneNumber = this.itemModal.item.companyPhone.replace(/[\d()\x2D\s]/g, '')
        var x = this.itemModal.item.companyPhone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
        this.itemModal.item.companyPhone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
        this.phoneState = this.isOnlyDigits(phoneNumber)
      }
    },
    startWithSpace (string) {
      return string.startsWith(' ')
    },
    isOnlyDigits (string) {
      if (string.length > 0) {
        this.phoneIsValid = false
        return false
      }

      this.phoneIsValid = true
      return null
    },
    init (items) {
      this.items = items
      this.totalRows = this.items.length
      this.currentPage = 1
    },
    fetchAfterDelete (id) {
      this.items = this.items.filter(item => item.id !== id)
      this.totalRows = this.items.length
    },
    async fetch () {
      const sites = await CompanyRepo.getAllSites()
      this.items = sites.data
      this.totalRows = this.items.length
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async editItem (item) {
      if (this.canUpdate) {
        this.itemModal.isCreateForm = false
        this.itemModal.title = `Editing ${this.itemName} ${item.companyName}`
        const company = await CompanyRepo.get(item.id)
        this.itemModal.item = company.data
        this.$refs.editModal.show()
      } else {
        this.showError(`Current user does not have permissions to edit ${this.itemName}`)
      }
    },
    newItem () {
      this.itemModal.title = `New ${this.itemName}`
      this.itemModal.item = {}
      this.itemModal.isCreateForm = true
      this.$refs.editModal.show()
    },
    cancelItemModal () {
      this.itemModal.title = ''
      this.itemModal.item = {}
      this.itemModal.isCreateForm = true
      this.$refs.editModal.hide()
    },
    onSubmit (event) {
      event.preventDefault()
      this.itemModal.isCreateForm ? this.createCompany() : this.updateCompany()
    },
    createCompany () {
      this.itemModal.item.offset = '-05:00'
      this.itemModal.item.timeZoneId = 'US/Central'
      this.itemModal.item.checksum = ''
      this.itemModal.item.isDeleted = false
      this.itemModal.item.isAccountOf = this.itemModal.item.isAccountOf ? this.itemModal.item.isAccountOf : 'N'
      this.itemModal.item.isSupplier = this.itemModal.item.isSupplier ? this.itemModal.item.isSupplier : 'N'
      this.itemModal.item.isShipper = this.itemModal.item.isShipper ? this.itemModal.item.isShipper : 'N'
      this.itemModal.item.isActiveForForecasting = this.itemModal.item.isActiveForForecasting ? this.itemModal.item.isActiveForForecasting : 'N'
      this.itemModal.item.isConsignee = this.itemModal.item.isConsignee ? this.itemModal.item.isConsignee : 'N'
      this.itemModal.item.isBillTo = this.itemModal.item.isBillTo ? this.itemModal.item.isBillTo : 'N'
      this.itemModal.item.companyAddressLine2 = this.itemModal.item.companyAddressLine2 ? this.itemModal.item.companyAddressLine2 : ''
      this.itemModal.item.companyPhone = this.itemModal.item.companyPhone ? this.itemModal.item.companyPhone : ''

      CompanyRepo.create(this.itemModal.item)
        .then(() => {
          this.showSuccess('The site has been created successfully')
          this.fetch()
          this.$refs.editModal.hide()
          this.itemModal.item = {}
          this.itemModal.title = ''
          this.itemModal.isCreateForm = true
        })
        .catch(error => {
          this.showError(global.handleError(error, 'An error occurred while creating the site. Please try again later', 'company'))
        })
    },
    updateCompany () {
      CompanyRepo.update(this.itemModal.item)
        .then(() => {
          const company = this.items.find((company) => company.id === this.itemModal.item.id)
          if (company) {
            company.companyName = this.itemModal.item.companyName
            company.companyUniqueId = this.itemModal.item.companyUniqueId
            this.showSuccess('Company information has been updated')
            this.$refs.editModal.hide()
            this.itemModal.title = ''
            this.itemModal.item = {}
            this.itemModal.isCreateForm = true
          }
        })
        .catch((error) => {
          this.showError(global.handleError(error, 'An error occurred while updating the site. Please try again later', 'company'))
        })
    },
    async deleteCompany (item) {
      const company = await CompanyRepo.get(item.id)
      if (this.canDelete) {
        this.$emit('deleteCompany', company.data)
      } else {
        this.showError(`Current user does not have permissions to delete ${this.itemName}`)
      }
    }
  }
}
</script>

<template>
  <div class="error">
    <img alt="Vue logo" src="../../assets/logo.png">
    <div>
      An error occurred.
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultError'
}
</script>

<template>
    <div>
        <b-row >
            <b-col offset-xl="3" col xl="6">
                <b-table id="catalog-table" class="catalog-table" bordered small no-border-collapse responsive="lg"
                    primary-key="id" :items="items" >
                </b-table>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
  name: 'UserProfileInfo',
  props: {
    user: Object
  },
  data () {
    return {
      items: []
    }
  },
  created () {
  },
  methods: {
    getItems (item) {
      this.items = [{
        name: item.name,
        email: item.emailAddress
      }]
    }
  },
  watch: {
    user (newVal, oldVal) {
      this.getItems(newVal)
    }
  }
}
</script>

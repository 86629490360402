<template>
    <b-form class="edit-form" @submit.prevent="onSubmit">
        <b-container>
        <b-row>
            <b-col col>
            <b-form-group
                label-cols="3"
                content-cols="9"
                :key="tankEditData.tank.product"
                id="tank-threshold"
                label="Threshold"
                label-for="threshold"
                class="threshold-form-group"
                v-bind:class="{ 'warn': !!thresholdWarnState(this.tankEditData.tank) }"
            >
                <b-form-input :id="tankEditData.tank.product" class="threshold-input" type="number" placeholder="threshold" v-model="tankEditData.tank.threshold"></b-form-input>
                <font-awesome-icon
                class="threshold-icon"
                icon="circle-info"
                v-if="!!thresholdWarnState(this.tankEditData.tank)"
                v-b-tooltip.hover="thresholdTooltipState(this.tankEditData.tank)"
            />
            </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
              <b-input-group>
                <b-form-checkbox class="btn-sitesort" v-model="tankEditData.tank.outOfService" name="check-button" switch>
                  Out Of Service
                </b-form-checkbox>
              </b-input-group>
            </b-col>
          </b-row>
        <b-row>
            <b-col cols="12" col lg="6" class="pt-2 pb-2">
              <b-button id="catalog-save-btn-modal" block variant="outline-kag-light-blue" type="submit">Save</b-button>
            </b-col>
            <b-col cols="12" col lg="6" class="pt-2 pb-2">
              <b-button id="catalog-cancel-btn-modal" block variant="outline-kag-red" @click="cancelModal">Cancel</b-button>
            </b-col>
        </b-row>
        </b-container>
    </b-form>
</template>

<script>

export default {
  name: 'ThresholdForm',
  methods: {
    thresholdWarnState (tank) {
      return tank.threshold > tank.safefillQty || !!(tank.threshold % 1) || tank.threshold < 0
    },
    cancelModal () {
      this.tankEditData.title = ''
      this.tankEditData.tank = {}
      this.$emit('cancelModal', true)
    },
    onSubmit () {
      this.$emit('onSubmit', this.tankEditData)
    }
  },
  props: {
    tankEditData: Object
  }
}
</script>

<style lang="scss">
</style>

import Vue from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faPenToSquare,
  faTrash,
  faBars,
  faArrowLeft,
  faArrowRight,
  faCircleInfo,
  faSpinner,
  faPlus,
  faBell,
  faRotateRight,
  faMagnifyingGlass
} from '@fortawesome/free-solid-svg-icons'

// Icons to use
library.add(faPenToSquare)
library.add(faTrash)
library.add(faBars)
library.add(faArrowLeft)
library.add(faArrowRight)
library.add(faCircleInfo)
library.add(faSpinner)
library.add(faPlus)
library.add(faBell)
library.add(faRotateRight)
library.add(faMagnifyingGlass)

// Register component globally
Vue.component('font-awesome-icon', FontAwesomeIcon)

import Vue from 'vue'
import VueRouter from 'vue-router'
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'
import store from '../store'

import DefaultError from '../views/shared/DefaultError.vue'

import Regions from '../views/region/Regions.vue'
import CustomGroups from '../views/custom-group/CustomGroups.vue'
import Companies from '../views/companies/Companies.vue'
import Sites from '../views/site/Sites.vue'

import UserProfiles from '../views/userProfile/UserProfiles.vue'
import RegionsToSelect from '../views/userProfile/RegionsToSelect.vue'
import OidcCallback from '@/components/shared/OidcCallback.vue'

Vue.use(VueRouter)

async function beforeAccess (to, from, next) {
// Short circuit access-denied to prevent infinite redirect loop
  if (to.meta.isPublic) {
    return next()
  }
  if (
    !store.getters['oidcStore/oidcIsAuthenticated'] ||
    to.name === 'access-denied'
  ) {
    return next('/access-denied')
  }
  if (store.getters['userAuthorization/tenantName'] === null) {
    return next('/notenant')
  }
  if (store.getters['userAuthorization/tenantNameMatch'] === false) {
    return next('/tenantmismatch')
  }
  return next()
}

const routes = [
  {
    path: '/',
    redirect: '/custom-group'
  },
  {
    path: '/companies',
    component: () => import('../views/companies/Company.vue'),
    children: [
      {
        path: '',
        name: 'Companies',
        component: Companies
      }
    ],
    beforeEnter: beforeAccess
  },
  {
    path: '/custom-group',
    component: () =>
      import(
        /* webpackChunkName: 'custom-group' */ '../views/custom-group/CustomGroup.vue'
      ),
    children: [
      {
        path: '',
        name: 'CustomGroups',
        component: CustomGroups
      }
    ],
    beforeEnter: beforeAccess
  },
  {
    path: '/region',
    component: () =>
      import(/* webpackChunkName: 'region' */ '../views/region/Region.vue'),
    children: [
      {
        path: '',
        name: 'Regions',
        component: Regions
      }
    ],
    beforeEnter: beforeAccess
  },
  {
    path: '/site',
    component: () =>
      import(/* webpackChunkName: 'site' */ '../views/site/Site.vue'),
    children: [
      {
        path: '/site/:siteId',
        name: 'Sites',
        component: Sites
      }
    ],
    beforeEnter: beforeAccess
  },
  {
    path: '/UserProfile',
    component: () =>
      import(
        /* webpackChunkName: 'UserProfile' */ '../views/userProfile/UserProfile.vue'
      ),
    children: [
      {
        path: '',
        name: 'UserProfiles',
        component: UserProfiles
      },
      {
        path: '/userprofile/regions/:email',
        name: 'UserProfilesRegions',
        component: RegionsToSelect
      }
    ],
    beforeEnter: beforeAccess
  },
  {
    path: '/error',
    name: 'DefaultError',
    component: DefaultError,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/oidc-callback', // Needs to match redirectUri (redirect_uri if you use snake case) in you oidcSettings
    name: 'oidcCallback',
    component: OidcCallback
  },
  {
    path: '/oidc-silent-renew',
    component: () => import('@/components/shared/SilentRenewOidc.html'),
    meta: {
      isPublic: true
    },
    beforeEnter: beforeAccess
  },
  {
    path: '/notenant',
    name: 'notenant',
    component: () =>
      import(/* webpackChunkName: 'notenant' */ '../views/shared/NoTenant.vue'),
    meta: {
      isPublic: true
    }
  },
  {
    path: '/tenantmismatch',
    name: 'tenantmismatch',
    component: () => import(/* webpackChunkName: "tenantmismatch" */ '../views/shared/TenantMismatch.vue'),
    meta: {
      isPublic: true
    }
  },
  {
    path: '/accessdenied',
    name: 'AccessDenied',
    component: () =>
      import(
        /* webpackChunkName: 'accessdenied' */ '../views/shared/AccessDenied.vue'
      ),
    meta: {
      isPublic: true
    },
    beforeEnter: beforeAccess
  },
  {
    path: '*',
    redirect: '/custom-group'
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'))

export default router

<template>
  <div id="site-information" class="site-information">
    <div>
      <strong>Site Information</strong>
    </div>
    <div>
      <b>Name:</b> <span id="site-info-name">{{ site.companyName }}</span>
    </div>
    <div>
      <b>ID:</b> <span id="site-info-unique-id">{{ site.companyUniqueId }}</span>
    </div>
    <div>
      <b>Address:</b>
      <br />
      &emsp;<span id="site-info-address-1">{{ site.companyAddressLine1 }}</span>
      <span v-if="site.companyAddressLine2">
        <br />
        &emsp;<span id="site-info-address-2">{{ site.companyAddressLine2 }}</span>
      </span>
      <br />
      &emsp;<span id="site-info-city">{{ site.city }}</span>, <span id="site-info-state">{{ site.state }}</span> <span id="site-info-zip">{{ site.zip }}</span>
    </div>
    <div>
      <b>Phone:</b> <span id="site-info-phone">{{ site.companyPhone | phone  }}</span>
    </div>
    <div>
      <b>Comments:</b>
      <br />
      <span id="site-info-comments">
        <b-form-textarea id="site-comments"
          rows="3"
          maxlength="254"
          v-model="commentText"
          placeholder="Enter comments"
          @blur="companyCommentsSave"
          @change="commentChange()">
        </b-form-textarea>
      </span>
    </div>
  </div>
</template>

<script>
import global from '@/mixins/shared/global'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const CompanyCommentRepo = RepositoryFactory.get('CompanyCommentRepository')

export default {
  name: 'SiteInformation',
  filters: {
    phone: (p) => {
      return global.formatPhone(p)
    }
  },
  props: {
    site: Object
  },
  data () {
    return {
      commentText: '',
      companyComment: {
        companyId: 0,
        comment: ''
      },
      commentChanged: false
    }
  },
  async mounted () {
    await this.getCompanyComment()
  },
  watch: {
    async site (newVal, oldVal) {
      if (newVal !== oldVal) {
        await this.getCompanyComment()
      }
    }
  },
  methods: {
    async getCompanyComment () {
      var result = await CompanyCommentRepo.getByCompanyId(this.site.id)
      this.companyComment = result.data
      this.commentText = this.companyComment.comment
    },
    commentChange () {
      this.commentChanged = !this.commentChanged
    },
    companyCommentsSave () {
      if (this.commentChanged) {
        this.companyComment.comment = this.commentText
        this.commentChanged = false
        // async await was not allowing event bubbling for Blur.
        const nonBlockingRequest = CompanyCommentRepo.RawRequest()
        nonBlockingRequest.rest.post(nonBlockingRequest.saveCompanyCommentEndpoint, this.companyComment, nonBlockingRequest.config)
      }
    }
  }
}
</script>

<style lang="scss">
  #site-comments {
    background-color: #D6DCE5;
    font-weight: bold;
  }

</style>

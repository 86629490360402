/* eslint-disable prefer-const */
import ConfigService from '@/configuration/configservice'

const environmentApiBase = {
  url: '#{EnvironmentApiBaseUrl}',
  description:
    'The base api url for the environment used as a base url for other KAG API base urls.'
}

const identityServerBaseClient = {
  id: '#{IdentityServerBaseClient}',
  description: 'The client id referenced in Identity Server'
}

const oidcEvents = {
  enabled: '#{EnableOidcEvents}',
  description: 'turn on/off Oidc Events for Authentication',
  possibleValues: ['true', 'false']
}

const environment = {
  name: '#{EnvironmentName}',
  description: 'The environment name example DEV,QA,UAT and PROD',
  possibleValues: ['DEV', 'QA', 'UAT', 'PROD']
}

const environmentBanner = {
  show: '#{ShowEnvironmentBanner}',
  description: 'Toggles the display of the environment banner',
  possibleValues: ['true', 'false']
}

const uiClient = {
  url: '#{UiClientUrl}',
  description: "This UI Client's environment url"
}

const uiApi = {
  url: '#{UiApiUrl}',
  description: 'The specific API for the UI environment url'
}

const uiRelease = {
  number: '#{UiReleaseNumber}',
  description: 'The release number generated from Octopus Deploy'
}

const isRunningLocal = ConfigService.isLocalMode(environment.name)

// For local development, the string above won't be replaced. The following block provides
// a default fallback URL. In a file being ran through Octopus variable substitution,
// you have to avoid using the 'hash' symbol anywhere (other than a "real"
// Octopus variable, of course). Otherwise Octopus throws a wobbly.
if (isRunningLocal) {
  environmentApiBase.url = 'https://api.qa.thekag.com/'
  oidcEvents.enabled = 'true'
  identityServerBaseClient.id = 'FIMLOCAL'
  environment.name = 'LOCAL'
  environmentBanner.show = 'true'
  uiClient.url = 'http://localhost:8080/'
  uiApi.url = 'https://api.qa.thekag.com/inventories/api' // 'https://localhost:44360/api'
  uiRelease.number = 'LOCAL'
}

const Configuration = {
  ApiBaseUrl: environmentApiBase.url,
  IdentityServerClientId: identityServerBaseClient.id,
  IdentityServerUrl: environmentApiBase.url + 'identityserver',
  LogOidcEvents: oidcEvents.enabled === 'true',
  InternalEnvironment: environment.name,
  RunningLocally: isRunningLocal,
  UiReleaseNumber: uiRelease.number,
  ShowEnvironmentBanner: environmentBanner.show === 'true',
  UiClientUrl: uiClient.url,
  UiApiUrl: uiApi.url
}

export default {
  ...Configuration
}

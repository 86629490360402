import global from '@/mixins/shared/global'
import Repository from './Repository'
const userRegionsRepo = Repository(global.basePath)

const resource = '/UserRegions'

export default {
  getByIdUser (idUser) {
    return userRegionsRepo.get(`${resource}/GetUserRegionsByIdUserAsync/${idUser}`)
  },
  getByIdRegion (idRegion) {
    return userRegionsRepo.get(`${resource}/GetUserRegionsByIdRegionAsync/${idRegion}`)
  },
  createUserRegion (userRegion) {
    return userRegionsRepo.post(`${resource}/`, userRegion)
  },
  deleteUserRegion (uderId, regionId) {
    return userRegionsRepo.delete(`${resource}/DeleteByUserAndRegionAsync/${uderId}/${regionId}`)
  },
  getAll () {
    return userRegionsRepo.get(`${resource}`)
  }
}

<template>
  <b-modal ref="modalPanel"
    id="orderRemarksModal"
    hide-footer
    hide-header
    centered
    title="Order Remarks">
  <b-form novalidate class="edit-form" @submit.prevent="confirmOR">
    <b-container>
      <b-row>
        <b-col col>
          <span><strong>Order Remarks (200 Characters Max)</strong></span>
        </b-col>
      </b-row>
    <b-row>
        <b-col col>
        <b-form-group
                content-cols="12"
            id="order-remark-info"
            class="threshold-form-group">
                <b-form-textarea id="Order-Remark"
                    no-resize
                    rows="5"
                    resize="none"
                    maxlength="200"
                v-model="orderRemarks"
                placeholder="200 Characters Max">
                </b-form-textarea>
        </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col cols="12" col lg="6" class="pt-2 pb-2">
              <b-button id="catalog-save-btn-modal" block variant="outline-kag-light-blue" type="submit">Ok</b-button>
        </b-col>
        <b-col  cols="12" col lg="6" class="pt-2 pb-2">
          <b-button id="catalog-cancel-btn-modal" block variant="outline-kag-red" @click="cancel">Cancel</b-button>
        </b-col>
    </b-row>
    </b-container>
  </b-form>
</b-modal>
</template>

<script>
export default {
  data () {
    return {
      title: '',
      orderRemarks: ''
    }
  },
  methods: {
    cancel () {
      this.$refs.modalPanel.hide()
    },
    async confirmOR () {
      this.$refs.modalPanel.hide()
      this.$emit('confirmedValue', this.orderRemarks)
    },
    show (opts = {}) {
      this.orderRemarks = opts.orderRemarks
      this.$refs.modalPanel.show()
    }
  }
}
</script>

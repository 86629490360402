<template>
  <div id="review-order" class="review-order">
    <b-modal ref="confirmModal" size="lg" no-close-on-backdrop hide-footer :static="true" title="Review New Orders" @hide="cancel">
      <b-form class="new-orders-form" @submit.prevent="confirm">
        <b-container>
          <b-row>
            <b-col cols="12" col>
              <PendingOrders
                ref="pendingOrdersComponent"
                compId="review"
                :pendingOrders="pendingOrders"
                :showEdit="true"
                :confirmButtonStatus="confirmButtonNotClicked"
                @refresh="refresh"
                @deleting="refreshCalculator"
              >
              </PendingOrders>
            </b-col>
          </b-row>

          <b-row v-if="confirmButtonNotClicked">
            <b-col cols="12" col offset-lg="4" lg="4" class="pt-2 pb-2">
              <div class="d-flex">
                <b-button block variant="outline-kag-dark-blue" class="mt-2 mr-3" type="submit">Confirm</b-button>
                <b-button id="confirm-modal-cancel-btn" block variant="outline-kag-red" @click="cancel">Cancel</b-button>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
      <b-row v-if="!confirmButtonNotClicked">
        <b-col cols="12" col offset-lg="4" lg="4" class="pt-2 pb-2">
          <div class="d-flex">
            <b-button block variant="outline-kag-dark-blue" @click="cancel">Close</b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<style lang="scss">
</style>

<script>
import global from '@/mixins/shared/global'
import userAuthGetters from '@/mixins/shared/userAuthGetters'
import toastMixin from '@/mixins/shared/toastMixin'

import { RepositoryFactory } from '@/repositories/RepositoryFactory'

import PendingOrders from '@/components/site/PendingOrders.vue'

const FimOrderRepo = RepositoryFactory.get('FimOrderRepository')

export default {
  name: 'CreateOrder',
  mixins: [
    userAuthGetters,
    toastMixin
  ],
  components: {
    PendingOrders
  },
  data () {
    return {
      pendingOrders: [],
      tanks: [],
      // Private variables
      resolvePromise: () => { },
      confirmButtonNotClicked: true
    }
  },
  methods: {
    build () {
    },
    show (opts = {}) {
      this.pendingOrders = opts.pendingOrders
      this.tanks = opts.tanks
      this.build()
      this.$refs.confirmModal.show()

      return new Promise((resolve) => {
        this.resolvePromise = resolve
      })
    },
    clear () {
      this.confirmButtonNotClicked = true
      this.pendingOrders = []
    },
    async refresh () {
      const fimOrders = await FimOrderRepo.userOrdersForReview()
      if (fimOrders && fimOrders.data && fimOrders.data.length) {
        this.pendingOrders = fimOrders.data
        this.build()
      } else {
        this.clear()
        this.$refs.confirmModal.hide()
        this.resolvePromise(false)
      }
      this.refreshCalculator()
    },
    cancel () {
      this.clear()
      this.$refs.confirmModal.hide()
      this.resolvePromise(false)
    },
    async confirm () {
      this.confirmButtonNotClicked = false
      try {
        const response = await FimOrderRepo.createUserConfirmedOrders(this.getEmail)
        this.$refs.pendingOrdersComponent.updateAfterConfirm(response.data)
        this.$store.dispatch('orders/emptyOrdersToReview')
      } catch (error) {
        this.showError(global.handleError(error, 'An error occurred while exporting orders. Please try again later'))
      }
    },
    refreshCalculator () {
      this.$emit('refresh-calculator')
    }
  }
}
</script>

import store from '../../index'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const FimOrderRepo = RepositoryFactory.get('FimOrderRepository')

const state = {
  ordersToReview: []
}

const getters = {
  amountOfOrdersToReview: state => {
    return state.ordersToReview.length
  }
}

const mutations = {
  setOrdersToReview: (state, orders) => {
    state.ordersToReview = orders
  },
  addOrdersToReview: (state, orders) => {
    // Convert single order to an array if it's not already an array
    const ordersArray = Array.isArray(orders) ? orders : [orders]

    state.ordersToReview.push(...ordersArray)
  },
  updateOrderToReview: (state, order) => {
    // Find the index of the order to update in the ordersToReview array
    const index = state.ordersToReview.findIndex(orderToReview => orderToReview.id === order.id)
    // If the order exists in the array, update it
    if (index !== -1) {
      state.ordersToReview.splice(index, 1, order)
    }
  },
  deleteOrderToReview: (state, orderId) => {
    // Find the index of the order to delete in the ordersToReview array
    const index = state.ordersToReview.findIndex(orderToReview => orderToReview.id === orderId)
    state.ordersToReview.splice(index, 1)
  },
  emptyOrdersToReview: (state) => {
    state.ordersToReview = []
  }
}

const actions = {
  loadOrdersToReview: async ({ commit }) => {
    try {
      const loggedInUserEmail = store.getters['userAuthorization/getEmail']
      const fimOrders = await FimOrderRepo.userOrdersForReview(loggedInUserEmail)
      commit('setOrdersToReview', fimOrders.data)
    } catch (error) {
      console.error('Error loading pending orders:', error)
    }
  },
  setOrdersToReview: ({ commit }, orders) => {
    commit('setOrdersToReview', orders)
  },
  addOrdersToReview: ({ commit }, orders) => {
    commit('addOrdersToReview', orders)
  },
  updateOrderToReview: ({ commit }, order) => {
    commit('updateOrderToReview', order)
  },
  deleteOrderToReview: ({ commit }, orderId) => {
    commit('deleteOrderToReview', orderId)
  },
  emptyOrdersToReview: ({ commit }) => {
    commit('emptyOrdersToReview')
  }
}

export default {
  namespaced: true, // Optionally set to true if you want namespacing
  state,
  getters,
  mutations,
  actions
}

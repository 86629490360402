export default {
  data () {
    return {
      count: 0
    }
  },
  methods: {
    createToast (message) {
      const id = `toast-${this.count++}`
      const elm = this.$createElement(
        'div',
        {
          on: { click: () => this.$bvToast.hide(id) }
        },
        message
      )
      return { id, elm }
    },
    showSuccess (message) {
      const toast = this.createToast(message)
      this.$bvToast.toast(toast.elm, {
        id: toast.id,
        variant: 'success'
      })
    },
    showWarning (message) {
      const toast = this.createToast(message)
      this.$bvToast.toast(toast.elm, {
        id: toast.id,
        variant: 'warning'
      })
    },
    showError (message) {
      const toast = this.createToast(message)
      this.$bvToast.toast(toast.elm, {
        id: toast.id,
        variant: 'danger'
      })
    }
  }
}

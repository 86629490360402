<template>
  <div class="site-rules-container">
    <b-row>
      <div v-if="canViewRestriction" class="col" :class="canViewNote ? 'col-lg-6' : 'col-12'">
        <strong class="title">Restrictions</strong>
        <b-table
          class="restrictions-table"
          bordered
          small
          no-border-collapse
          responsive="lg"
          primary-key="id"
          :items="restrictionItems"
          :fields="restrictionFields"
        >
          <template slot="top-row">
            <td id="restrictions-no-records" v-if="!restrictionItems || restrictionItems.length === 0" colspan="2" class="text-center">
              No records to display
            </td>
          </template>

          <template #cell(restriction)="row">
            {{row.item.windowStart}} - {{row.item.windowEnd}}
          </template>
        </b-table>
      </div>
      <div v-if="canViewNote" class="col" :class="canViewRestriction ? 'col-lg-6' : 'col-12'">
        <strong class="title">Notes</strong>
        <b-table
          class="notes-table"
          bordered
          small
          no-border-collapse
          responsive="lg"
          primary-key="id"
          :items="noteItems"
          :fields="noteFields"
        >
          <template slot="top-row">
            <td id="notes-no-records" v-if="!noteItems || noteItems.length === 0" class="text-center">
              No records to display
            </td>
          </template>
        </b-table>
      </div>
    </b-row>
  </div>
</template>

<style lang="scss">
@import '~@/assets/scss/themes/theme';

.site-rules-container {
  .title {
    color: $kag-dark-blue-color;
  }
}
</style>

<script>
import userAuthGetters from '@/mixins/shared/userAuthGetters'

export default {
  name: 'SiteRules',
  mixins: [
    userAuthGetters
  ],
  props: {
    noteItems: Array,
    restrictionItems: Array
  },
  data () {
    return {
      restrictionFields: [
        { key: 'windowDay', label: 'Day' },
        { key: 'restriction', label: 'Restriction' }
      ],
      noteFields: [
        { key: 'notesText', label: 'Note' }
      ]
    }
  }
}
</script>

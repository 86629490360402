import global from '@/mixins/shared/global'
import Repository from './Repository'
const fimOrderRepo = Repository(global.basePath)

const resource = '/FimOrder'

export default {
  userOrdersForReview () {
    return fimOrderRepo.get(`${resource}/UserOrdersForReview`)
  },
  create (payload) {
    return fimOrderRepo.post(`${resource}`, payload)
  },
  update (payload) {
    return fimOrderRepo.put(`${resource}`, payload)
  },
  delete (id) {
    return fimOrderRepo.delete(`${resource}/${id}`)
  },
  createUserConfirmedOrders () {
    return fimOrderRepo.get(`${resource}/CreateUserConfirmedOrders`)
  }
}

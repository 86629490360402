<template>
  <div id="confirm-modal" class="confirm-modal">
    <b-modal ref="confirmModal" hide-footer :static="true" :title="title">
      <b-form @submit.prevent="confirm">
        <p class="confirm-modal-text">{{ text }}</p>
        <div class="text-right">
          <b-button id="confirm-modal-confirm-btn" class="mt-2 mr-3" :variant="confirmVariant" type="submit">{{ confirmText }}</b-button>
          <b-button id="confirm-modal-cancel-btn" class="mt-2" :variant="cancelVariant" @click="cancel">{{ cancelText }}</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ConfirmModal',
  data () {
    return {
      title: '',
      text: '',
      cancelText: '',
      confirmText: '',
      cancelVariant: '',
      confirmVariant: '',
      // Private variables
      resolvePromise: () => {}
    }
  },
  methods: {
    show (opts = {}) {
      this.title = opts.title
      this.text = opts.text
      this.cancelText = opts.cancelText || 'Cancel'
      this.confirmText = opts.confirmText || 'Confirm'
      this.cancelVariant = opts.cancelVariant || 'outline-kag-grey'
      this.confirmVariant = opts.confirmVariant || 'outline-kag-dark-blue'
      this.$refs.confirmModal.show()

      return new Promise((resolve) => {
        this.resolvePromise = resolve
      })
    },
    cancel () {
      this.$refs.confirmModal.hide()
      this.resolvePromise(false)
      // this.rejectPromise(new Error(false))
    },
    confirm () {
      this.$refs.confirmModal.hide()
      this.resolvePromise(true)
    }
  }
}
</script>

import CustomGroupRepository from './CustomGroupRepository'
import CompanyRepository from './CompanyRepository'
import RegionRepository from './RegionRepository'
import InventoryRepository from './InventoryRepository'
import SalesRepository from './SaleRepository'
import FimOrderRepository from './FimOrderRepository'
import TankRepository from './TankRepository'
import CommodityRepository from './CommodityRepository'
import CalculatorRepository from './CalculatorRepository'
import UserProfileRepository from './UserProfileRepository'
import UserRegionsRepository from './UserRegionsRepository'
import CompanyCommentRepository from './CompanyCommentRepository'
import CommodityProfileRepository from './CommodityProfileRepository'

const repositories = {
  CustomGroupRepository,
  CompanyRepository,
  RegionRepository,
  InventoryRepository,
  SalesRepository,
  FimOrderRepository,
  TankRepository,
  CommodityRepository,
  CalculatorRepository,
  UserProfileRepository,
  UserRegionsRepository,
  CompanyCommentRepository,
  CommodityProfileRepository
}

export const RepositoryFactory = {
  get: (name) => repositories[name]
}

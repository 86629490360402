<template>
  <div class="site-edition-container mb-5">
    <b-form class="site-form" @submit.prevent="saveSite">
      <b-container>
        <b-row>
          <b-col cols="12" col lg="4">
            <b-form-group
              id="site-region-fieldset"
              label="Region"
              label-for="site-region"
            >
              <b-form-select
                id="site-region"
                placeholder="Region"
                v-model="site.regionId"
                :disabled="!canUpdate"
                :options="regionOptions"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" col lg="4">
            <b-form-group
              id="site-custom-group-fieldset"
              label="Custom Group"
              label-for="site-custom-group"
            >
              <b-form-select
                id="site-custom-group"
                placeholder="Custom Group"
                v-model="site.customGroupId"
                :disabled="!canUpdate"
                :options="customGroupOptions"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" col lg="4">
            <b-form-group
              id="site-bill-to-fieldset"
              label="Bill To"
              label-for="site-bill-to"
            >
              <b-form-select
                id="site-bill-to"
                placeholder="Bill To"
                v-model="site.assignedBillToId"
                :disabled="!canUpdate"
                :options="billToOptions"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
        </b-row>
        <b-row>
          <b-col cols="12" col lg="4">
            <b-form-group
              id="site-forecast-fieldset"
              label-for="site-forecast"
            >
              <b-form-checkbox
                id="site-forecast"
                v-model="site.isActiveForForecasting"
                value="Y"
                unchecked-value="N"
                :disabled="!canUpdate"
                switch
              >
                <span v-if="site.isActiveForForecasting === 'Y'">Active</span>
                <span v-if="site.isActiveForForecasting === 'N' || !site.isActiveForForecasting">Inactive</span>
                for forecasting
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="12" col lg="4">
            <b-form-group
              id="site-inventory-reading-alert-fieldset"
              label-for="site-inventory-reading-alert"
            >
              <b-form-checkbox
                id="site-inventory-reading-alert"
                v-model="site.showInventoryReadingAlert"
                value="Y"
                unchecked-value="N"
                :disabled="!canUpdate"
                switch
              >
                Show inventory reading alert
                <span v-if="site.showInventoryReadingAlert === 'Y'">(Yes)</span>
                <span v-if="site.showInventoryReadingAlert === 'N' || !site.showInventoryReadingAlert">(No)</span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="canUpdate">
          <b-col cols="12" col offset-lg="4" lg="4" class="pt-2 pb-2">
            <b-button block variant="outline-kag-light-blue" type="submit">Save</b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </div>
</template>

<script>
import global from '@/mixins/shared/global'
import toastMixin from '@/mixins/shared/toastMixin'

import { RepositoryFactory } from '@/repositories/RepositoryFactory'

const CompanyRepo = RepositoryFactory.get('CompanyRepository')

export default {
  name: 'SiteEdit',
  mixins: [toastMixin],
  props: {
    site: Object,
    regionOptions: Array,
    customGroupOptions: Array,
    billToOptions: Array,
    canUpdate: Boolean
  },
  methods: {
    saveSite () {
      if (this.canUpdate) {
        CompanyRepo.update(this.site)
          .then(() => {
            this.showSuccess('Site updated successfully')
            this.$emit('company-updated')
          })
          .catch((error) => {
            this.showError(global.handleError(error, `An error occurred while trying to edit ${this.site.companyUniqueId}. Please try again later`))
          })
      } else {
        this.showError('Current user does not have permissions to edit sites')
      }
    }
  }
}
</script>

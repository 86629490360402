import global from '@/mixins/shared/global'
import Repository from './Repository'
const userProfileRepo = Repository(global.basePath)

const resource = '/UserProfile'

export default {
  getByEmailAddress (emailAddress) {
    return userProfileRepo.get(`${resource}/GetUserProfileByEmailAddress?emailAddress=${encodeURIComponent(emailAddress)}`)
  },
  getAll () {
    return userProfileRepo.get(`${resource}`)
  },
  create (profile) {
    return userProfileRepo.post(`${resource}/`, profile)
  }
}

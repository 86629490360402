<template>
  <div class="catalog-container text-center">
    <!-- Content -->
    <div v-if="canCreate" class="mb-3">
      <b-button id="add-btn" variant="kag-dark-blue" @click="newItem()">
        Add {{ itemName }}
      </b-button>
    </div>

    <!-- Filter -->
    <b-row>
      <b-col offset-xl="4" col xl="4" offset-md="2" md="8">
        <b-form-group
          id="filter-fieldset"
          label="Filter"
          label-class="font-weight-bold"
          label-for="filter-input"
          label-cols-md="3"
        >
          <b-form-input id="filter-input" type="search" placeholder="Search" v-model="filter"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col offset-xl="3" col xl="6">
        <b-table
          id="catalog-table"
          class="catalog-table"
          bordered
          small
          no-border-collapse
          responsive="lg"
          primary-key="id"
          :items="items"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="filter"
          @filtered="onFiltered"
          :filterIncludedFields="filterIncludedFields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
        >
          <template #cell(description)="row" >
            <div v-html="$options.filters.uppercase(row.item.description)" class="keep-spaces"></div>
          </template>
          <template #cell(actions)="row">
            <b-button v-if="canUpdate" variant="kag-light-blue" class="mr-2 edit-btn" size="sm" @click="editItem(row.item)" v-b-tooltip.hover="'Edit'">
              <font-awesome-icon class="clickable" icon="pen-to-square" />
            </b-button>
            <b-button v-if="canDelete" variant="kag-red" class="delete-btn" size="sm" @click="deleteItem(row.item)" v-b-tooltip.hover="'Delete'">
              <font-awesome-icon class="clickable" icon="trash" />
            </b-button>
          </template>
        </b-table>
        <label id="no-records" v-if="items.length === 0">There are no records to show</label>
      </b-col>
    </b-row>

    <b-row>
      <!-- Pagination -->
      <b-col col sm="6" offset-xl="3" xl="3" id="catalog-pagination" class="pagination-container">
        <b-pagination
          class="justify-content-center"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
        ></b-pagination>
      </b-col>
      <!-- Per Page -->
      <b-col col sm="6" offset-xl="1" xl="2" id="catalog-row-per-page">
        <b-form-select
          class="justify-content-center"
          v-model="perPage"
          :options="pageOptions"
          size="sm"
        ></b-form-select>
      </b-col>
    </b-row>

    <!-- Edit modal -->
    <b-modal ref="editModal" id="catalog-edit-modal" hide-footer centered :title="itemModal.title" @shown="editModalShown">
      <b-form class="edit-form" @submit.prevent="confirmItemModal">
        <b-container>
          <b-row>
            <b-col col>
              <b-form-group
                id="item-name-fieldset"
                :label="itemName"
                label-for="item-name"
                :invalid-feedback="nameFeedback"
                :state="nameState"
              >
                <b-form-input ref="nameInput" id="item-name" placeholder="Name" maxlength="30" v-model="itemModal.item.description" :state="nameState" trim></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" col lg="6" class="pt-2 pb-2">
              <b-button id="catalog-cancel-btn-modal" block variant="outline-kag-red" @click="cancelItemModal">Cancel</b-button>
            </b-col>
            <b-col cols="12" col lg="6" class="pt-2 pb-2">
              <b-button id="catalog-save-btn-modal" block variant="outline-kag-light-blue" type="submit" :disabled="formState">Save</b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
    </b-modal>
  </div>
</template>

<style lang="scss">
@import '~@/assets/scss/themes/theme';

.catalog-container {
  .catalog-table {
    .actions {
      width: 80px;
      min-width: 80px;
      background-color: $white;
      border: none;
    }
    .keep-spaces { white-space: pre-wrap; }
  }
}
</style>

<script>
import global from '@/mixins/shared/global'
import toastMixin from '@/mixins/shared/toastMixin'
import constants from '@/mixins/shared/constants'

export default {
  name: 'Catalog',
  mixins: [toastMixin],
  filters: {
    uppercase: (s) => {
      return s.toUpperCase()
    }
  },
  props: {
    itemName: { type: String },
    getAllRequest: { type: Function },
    createRequest: { type: Function },
    updateRequest: { type: Function },
    initialItems: { type: Array },
    canCreate: { type: Boolean },
    canUpdate: { type: Boolean },
    canDelete: { type: Boolean }
  },
  computed: {
    formState () {
      return !this.nameState
    },
    nameState () {
      return !!this.itemModal.item.description && this.itemModal.item.description.length <= 30 && constants.alphanumericRegex.test(this.itemModal.item.description)
    },
    nameFeedback () {
      if (!this.itemModal.item.description) {
        return `${this.itemName} is required`
      }
      if (this.itemModal.item.description.length > 30) {
        return 'Max length is 30 characters'
      }
      if (!constants.alphanumericRegex.test(this.itemModal.item.description)) {
        return 'Only -, _, &, / or , and alphanumeric characters are allowed'
      }
      return ''
    },
    isNewItem () {
      return !!this.itemModal.item && !this.itemModal.item.id
    }
  },
  data () {
    return {
      fields: [
        { key: 'description', label: 'Name', sortable: true, sortDirection: 'desc' },
        { key: 'actions', label: '', class: 'actions' }
      ],
      items: [],
      perPage: 10,
      pageOptions: [
        { value: 5, text: '5 per page' },
        { value: 10, text: '10 per page' },
        { value: 25, text: '25 per page' },
        { value: 50, text: '50 per page' },
        { value: 100, text: '100 per page' }
      ],
      totalRows: 0,
      currentPage: 1,
      filter: '',
      filterIncludedFields: ['description'],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      itemModal: {
        title: '',
        item: {}
      }
    }
  },
  created () {
    if (!this.canUpdate && !this.canDelete) {
      this.fields = this.fields.filter((f) => f.key !== 'actions')
    }
    this.items = [...this.initialItems]
    this.init(this.items)
  },
  methods: {
    init (items) {
      // this.items = items.filter((d) => d.isActive)
      this.items = items
      this.totalRows = this.items.length
      this.currentPage = 1
    },
    fetch () {
      this.getAllRequest()
        .then((response) => {
          const items = response && response.data ? response.data : []
          this.init(items)
        })
        .catch((error) => {
          this.showError(global.handleError(error, `An error occurred while fetching ${this.itemName}s. Please try again later`))
          this.init([])
        })
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    editItem (item) {
      if (this.canUpdate) {
        this.itemModal.title = `Editing ${this.itemName} ${item.description}`
        this.itemModal.item = { ...item }
        this.$refs.editModal.show()
      } else {
        this.showError(`Current user does not have permissions to edit ${this.itemName}`)
      }
    },
    newItem () {
      this.itemModal.title = `New ${this.itemName}`
      this.itemModal.item = {}
      this.$refs.editModal.show()
    },
    editModalShown () {
      this.$refs.nameInput.$el.focus()
    },
    cancelItemModal () {
      this.itemModal.title = ''
      this.itemModal.item = {}
      this.$refs.editModal.hide()
    },
    confirmItemModal (event) {
      event.preventDefault()

      if (this.isNewItem) {
        if (this.canCreate) {
          const newItem = { ...this.itemModal.item }
          newItem.description = newItem.description.trim().toUpperCase()
          const itemExists = !!this.items.find((r) => r.description.toUpperCase() === newItem.description)
          if (itemExists) {
            this.showError(`${this.itemName} already exists`)
            return
          }
          this.createRequest(newItem)
            .then(() => {
              this.fetch()
              this.itemModal.item = {}
              this.showSuccess(`${this.itemName} created successfully`)
              this.$refs.editModal.hide()
            })
            .catch((error) => {
              this.showError(global.handleError(error, `An error occurred while trying to create new ${this.itemName}. Please try again later`))
            })
        } else {
          this.showError(`Current user does not have permissions to create new ${this.itemName}`)
        }
      } else {
        if (this.canUpdate) {
          const itemExists = this.items.find((r) =>
            r.description.toUpperCase() === this.itemModal.item.description.trim().toUpperCase() && r.id !== this.itemModal.item.id)
          if (itemExists) {
            this.showError(`${this.itemName} already exists`)
            return
          }

          this.updateRequest(this.itemModal.item)
            .then(() => {
              this.fetch()
              this.itemModal.item = {}
              this.showSuccess(`${this.itemName} updated successfully`)
              this.$refs.editModal.hide()
            })
            .catch((error) => {
              this.showError(global.handleError(error, `An error occurred while trying to edit ${this.itemName}. Please try again later`))
            })
        } else {
          this.showError(`Current user does not have permissions to edit ${this.itemName}`)
        }
      }
    },
    deleteItem (item) {
      if (this.canDelete) {
        this.$emit('deleteItem', item)
      } else {
        this.showError(`Current user does not have permissions to delete ${this.itemName}`)
      }
    }
  }
}
</script>

import { mapGetters } from 'vuex'
import store from '@/store'

export default {
  computed: {
    ...mapGetters('userAuthorization', Object.keys(store.getters).map(function (key) {
      if (key.startsWith('userAuthorization')) {
        return key.substring(key.indexOf('/') + 1)
      }
    }))
  }
}

<template>
  <div>
    <b-navbar-nav class='ml-auto'>
      <b-nav-form>
        <b-button
          size='sm'
          class='my-2 my-sm-0'
          variant='kag-grey'
          @click='logout'
          >{{ isAuthenticated ? 'Sign-Out' : 'Sign-In' }}</b-button
        >
      </b-nav-form>
    </b-navbar-nav>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
export default {
  methods: {
    ...mapActions('oidcStore', ['removeOidcUser', 'signOutOidc']),
    goToHome () {
      // this.$router.push({ name: 'home' })
      this.$router.push({ name: 'custom-group' })
    },
    logout () {
      if (this.isAuthenticated) {
        this.signOutOidc()
        // .then(() => {
        //   this.$router.push({ name: 'custom-group' })
        // })
      }
    },
    oidcError: function (e) {
      console.log(
        'I am listening to the oidc oidcError event in vuex-oidc',
        e.detail
      )
    },
    automaticSilentRenewError: function (e) {
      console.log(
        'I am listening to the automaticSilentRenewError event in vuex-oidc',
        e.detail
      )
    },
    registerUser: function (state) {
      try {
        const profileData = {
          name: '',
          emailAddress: ''
        }
        profileData.name =
          state.userAuthorization.firstName +
          ' ' +
          state.userAuthorization.lastName
        profileData.emailAddress = state.userAuthorization.email
        if (profileData.emailAddress && profileData.emailAddress.length > 0) {
          const UserProfileRepo = RepositoryFactory.get('UserProfileRepository')
          UserProfileRepo.getByEmailAddress(profileData.emailAddress).then(
            (response) => {
              if (response && response.data) {
                console.log('user profile already exists')
              } else {
                UserProfileRepo.create(profileData)
                console.log('user profile is created')
              }
            }
          )
        }
      } catch {
        console.log('register user failed')
      }
    }
  },
  computed: mapState({
    ...mapGetters('oidcStore', ['oidcUser', 'oidcIsAuthenticated']),
    isAuthenticated: function (state) {
      console.log('oidcIsAuthenticated', this.oidcIsAuthenticated)
      if (this.oidcIsAuthenticated) {
        this.registerUser(state)
      }
      return this.oidcIsAuthenticated
    }
  }),
  mounted () {
    window.addEventListener('vuexoidc:userLoaded', this.userLoaded)
  },
  destroyed () {
    window.removeEventListener('vuexoidc:userLoaded', this.userLoaded)
  }
}
</script>

import global from '@/mixins/shared/global'
import Repository from './Repository'
const CommodityRepo = Repository(global.basePath)

const resource = '/Commodity'

export default {
  getCommodities () {
    return CommodityRepo.get(`${resource}`)
  }
}

import 'mutationobserver-shim'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './plugins/bootstrap-vue'
import './scripts/font-awesome'

Vue.config.productionTip = false

window.addEventListener('beforeunload', function (event) {
  // Check if there are orders to review
  const ordersToReview = store.getters['orders/amountOfOrdersToReview'] // Assuming 'orders' is the name of your module
  if (ordersToReview > 0) {
    // Customize the message that will be shown to the user
    const confirmationMessage = 'You have pending orders to review. Are you sure you want to leave this page?'

    // Some browsers may need this to display the message
    event.preventDefault()
    event.returnValue = confirmationMessage

    // Return the message to be displayed
    return confirmationMessage
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

  <template>
  <div>Loading..... </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'OidcCallback',
  computed: {
  },
  methods: {
    ...mapActions('oidcStore', ['oidcSignInCallback'])
  },
  mounted () {
    this.oidcSignInCallback()
      .then(redirectPath => {
        this.$router.push(redirectPath)
      })
      .catch(err => {
        console.log(err)
        this.$router.push('/access-denied') // Handle errors any way you want
      })
  }
}
</script>

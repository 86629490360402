import global from '@/mixins/shared/global'
import Repository from './Repository'
const calculatorRepo = Repository(global.basePath)

const resource = '/Calculator'

export default {
  getCalculator (id) {
    return calculatorRepo.get(`${resource}/${id}`)
  }
}

<template>
  <div class="companies">
    <CompaniesCatalog
      ref="CompaniesComponent"
      item-name="Company"
      :can-create="canCreateCustomGroup"
      :can-update="canUpdateCustomGroup"
      :can-delete="canDeleteCustomGroup"
      @deleteCompany="deleteCompany"
    ></CompaniesCatalog>
    <ConfirmModal ref="deleteModal"></ConfirmModal>
  </div>
</template>

<script>
import global from '@/mixins/shared/global'
import userAuthGetters from '@/mixins/shared/userAuthGetters'
import toastMixin from '@/mixins/shared/toastMixin'

import ConfirmModal from '@/components/shared/ConfirmModal.vue'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
import CompaniesCatalog from '../../components/shared/CompaniesCatalog.vue'

const CompanyRepo = RepositoryFactory.get('CompanyRepository')

export default {
  name: 'Companies',
  mixins: [
    userAuthGetters,
    toastMixin
  ],
  components: {
    CompaniesCatalog,
    ConfirmModal
  },
  created () {
    if (!this.canViewCustomGroup) {
      if (this.canViewRegion) {
        this.$router.push({ name: 'Regions' })
      } else if (this.canViewCompany) {
        this.$router.push({ name: 'Sites' })
      } else {
        this.$router.push({ name: 'AccessDenied' })
      }
    }
  },
  methods: {
    async deleteCompany (item) {
      const result = await this.$refs.deleteModal.show({
        title: 'Delete Site',
        text: `Are you sure you want to delete site ${item.companyName}?`,
        confirmText: 'Delete',
        confirmVariant: 'outline-kag-red'
      })
      if (result) {
        item.isDeleted = true
        CompanyRepo.update(item)
          .then(() => {
            this.$refs.CompaniesComponent.fetchAfterDelete(item.id)
            this.showSuccess(`${item.companyName} has been deleted`)
          })
          .catch((error) => {
            this.showError(global.handleError(error, 'An error occurred while trying to delete the site. Please try again later'))
          })
      }
    }
  }
}
</script>

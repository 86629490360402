<template>
  <b-form class="edit-form" @submit.prevent="confirmDeleteModal">
    <b-container>
      <b-row>
        <b-col col>
          <p class="confirm-modal-text">{{ this.deleteText }}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" col lg="6" class="pt-2 pb-2">
          <b-button id="catalog-save-btn-modal" block variant="outline-kag-light-blue" type="submit">Delete</b-button>
        </b-col>
        <b-col cols="12" col lg="6" class="pt-2 pb-2">
          <b-button id="catalog-cancel-btn-modal" block variant="outline-kag-red" @click="closeModal">Cancel</b-button>
        </b-col>
      </b-row>
    </b-container>
  </b-form>
</template>

<script>

export default {
  name: 'DeleteForm',
  data () {
    return {
    }
  },
  methods: {
    closeModal () {
      this.$emit('cancelModal', true)
    },
    confirmDeleteModal () {
      this.$emit('onConfirm', true)
    }
  },
  props: {
    tankDeleteData: Object,
    deleteText: String
  }
}
</script>

<style lang="scss">
</style>

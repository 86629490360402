<template>
  <div id="order-history" class="order-history">
    <b-table
      v-if="orderData.length"
      id="order-history-table"
      class="order-history-table"
      bordered
      small
      responsive
      no-border-collapse
      :tbody-tr-class="rowClass"
      :items="orderData"
      :fields="orderFields"
    >
      <template v-slot:cell(shipper)="data">
          <span v-b-tooltip.hover :title=data.value>{{ data.value.substring(0,25)}}</span>
      </template>
    </b-table>

    <div v-if="!orderData.length" class="d-block w-100 text-center">
      No records to display
    </div>
  </div>
</template>

<style lang="scss">
@import '~@/assets/scss/themes/theme';

.order-history {
  .order-history-table {
    tbody {
      tr {
        &.cmp {
          color: white;
          background-color: $kag-green-color;
        }
        &.cmp_h {
          color: white;
          background-color: #6c757d;
        }

        td {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>

<script>
import moment from 'moment-timezone'

const INIT_ORDER_FIELDS = [
  { key: 'orderId', label: 'Number' },
  { key: 'status', label: 'Status' },
  { key: 'shipper', label: 'Shipper' },
  { key: 'retain', label: 'Retain' },
  { key: 'eta', label: 'ETA' },
  { key: 'drops', label: 'Drops' }
]
const END_ORDER_FIELDS = [
  { key: 'carrier', label: 'Drvr/Carrier' },
  { key: 'fleet', label: 'Fleet' }
]

export default {
  name: 'OrderHistory',
  props: {
    orders: Array,
    commodityClasses: Array
  },
  data () {
    return {
      orderFields: [],
      orderData: []
    }
  },
  watch: {
    orders (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.build()
      }
    }
  },
  created () {
    this.build()
  },
  methods: {
    build () {
      this.orderData = []
      if (this.orders && this.orders.length === 0) {
        return
      }

      const uniqueCommodityClass = []
      const computedOrders = []
      this.orders.forEach((order) => {
        const existingCommodity = uniqueCommodityClass.indexOf(order.commodityClass) >= 0
        if (!existingCommodity) {
          uniqueCommodityClass.push(order.commodityClass)
        }

        const existingOrderIdx = computedOrders.findIndex((co) => co.orderId === order.orderHdrNumber)
        if (existingOrderIdx >= 0) {
          computedOrders[existingOrderIdx].products.push({ name: order.commodityClass, qty: order.stopTotalVolume })
          if (computedOrders[existingOrderIdx].shipper?.indexOf(order.shipperUniqueId?.trim()) === -1) {
            computedOrders[existingOrderIdx].shipper = computedOrders[existingOrderIdx].shipper?.trim() + ', ' + order.shipperUniqueId
          }
        } else {
          const newOrder = {
            orderId: order.orderHdrNumber,
            status: order.orderStatus,
            shipper: order.shipperUniqueId?.trim(),
            retain: new Date(order.retain),
            eta: new Date(order.arrivalDate),
            drops: order.orderDropCount,
            carrier: order.mppFullName,
            fleet: order.mppFleet,
            includedAsHistorical: order.includedAsHistorical,
            products: [
              {
                name: order.commodityClass,
                qty: order.stopTotalVolume
              }
            ]
          }
          computedOrders.push(newOrder)
        }
      })
      this.orderFields = [...INIT_ORDER_FIELDS]

      this.commodityClasses.forEach((cc) => {
        this.orderFields.push({ key: cc.name, label: 'Product' })
        this.orderFields.push({ key: `${cc.name}Qty`, label: 'Qty' })
      })
      // uniqueCommodityClass.forEach((key) => {
      //   this.orderFields.push({ key, label: 'Product' })
      //   this.orderFields.push({ key: `${key}Qty`, label: 'Qty' })
      // })
      this.orderFields = this.orderFields.concat(END_ORDER_FIELDS)

      computedOrders.sort((a, b) => {
        if (a.retain.getTime() < b.retain.getTime()) {
          return 1
        } else if (b.retain.getTime() > a.retain.getTime()) {
          return -1
        }
        return 0
      })

      const orders = []
      computedOrders.forEach((order) => {
        const orderData = {
          orderId: order.orderId,
          status: order.status,
          shipper: order.shipper,
          retain: this.formatDate(order.retain),
          eta: this.formatDate(order.eta),
          drops: order.drops,
          carrier: order.carrier,
          fleet: order.fleet,
          includedAsHistorical: order.includedAsHistorical
        }
        order.products.forEach((product) => {
          orderData[product.name] = product.name
          orderData[`${product.name}Qty`] = product.qty
        })

        orders.push(orderData)
      })

      this.orderData = orders
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      return item.status && !item.includedAsHistorical && item.status.toLowerCase() === 'cmp' ? 'table-row cmp' : item.includedAsHistorical ? 'table-row cmp_h' : 'table-row'
    },
    formatDate (date) {
      return moment(date).format('MM/DD HH:mm')
    }
  }
}
</script>

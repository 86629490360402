import global from '@/mixins/shared/global'
import Repository from './Repository'
const tankRepo = Repository(global.basePath)

const resource = '/Tanks'

export default {
  getTanksByDateRange (siteId, from, to, showAll) {
    const params = {
      id: siteId,
      from,
      to,
      showAll
    }
    return tankRepo.get(`${resource}/GetTanksWithInventoriesBetweenDates`, { params })
  },
  getTankByDateRange (tankId, from, to) {
    const params = {
      id: tankId,
      from,
      to
    }
    return tankRepo.get(`${resource}/GetTankWithInventoriesBetweenDates`, { params })
  },
  updateTankThresholdAndOutOfService (tankId, threshold, outOfService) {
    const payload = {
      id: tankId,
      threshold,
      outOfService
    }
    return tankRepo.put(`${resource}/UpdateTankThresholdAndOutOfServiceByTankIdAsync/${payload.id}/${threshold}/${outOfService}`, payload)
  },
  createTank (tank) {
    return tankRepo.post(`${resource}/`, tank)
  },
  updateTank (tankId, tank) {
    return tankRepo.put(`${resource}/${tankId}`, tank)
  },
  deleteTank (companyId, tankNumber) {
    return tankRepo.delete(`${resource}/DeleteByCompanyIdAndTankNumberAsync/${companyId}/${tankNumber}`)
  },
  getTanksByCompanyId (companyId) {
    return tankRepo.get(`${resource}/GetTanksByCompanyId/${companyId}`)
  }
}

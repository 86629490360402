import global from '@/mixins/shared/global'

export const oidcSettings = {
  authority: global.appConfig.IdentityServerUrl, // The IDS
  clientId: global.appConfig.IdentityServerClientId, // Client Id to IDS - change to local for local build
  redirectUri: global.appConfig.UiClientUrl + 'oidc-callback', // Redirect Back To
  responseType: 'code',
  scope: 'openid profile IdentityServerApi InventoriesApi LoadTendersApi',
  automaticSilentRenew: true,
  silentRedirectUri: global.appConfig.UiClientUrl + 'oidc-silent-renew',
  automaticSilentSignin: false
}

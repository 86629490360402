import global from '@/mixins/shared/global'
import Repository from './Repository'
import axios from 'axios'
import store from '../store'
const companyCommentRepo = Repository(global.basePath)

const resource = '/CompanyComment'
const getConfig = () => {
  return {
    headers: {
      Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`
    }
  }
}

export default {
  getByCompanyId (companyId) {
    return companyCommentRepo.get(`${resource}/GetCompanyComment/${companyId}`)
  },
  SaveCompanyComment (companyId, comment) {
    return companyCommentRepo.post(`${resource}/SaveCompanyComment`, { companyId, comment })
  },
  RawRequest () {
    return {
      rest: axios,
      config: getConfig(),
      saveCompanyCommentEndpoint: `${global.basePath}${resource}/SaveCompanyComment`
    }
  }
}

import global from '@/mixins/shared/global'
import Repository from './Repository'
const companyRepo = Repository(global.basePath)

const resource = '/Company'

export default {
  types: {
    CONSIGNEE: 'Consignee',
    SHIPPER: 'Shipper',
    SUPPLIER: 'Supplier',
    ACCOUNT_OF: 'AccountOf',
    BILL_TO: 'BillTo'
  },
  getAll () {
    return companyRepo.get('/mocks/sites.json')
  },
  getBy (type) {
    return companyRepo.get(`${resource}/GetCompaniesByTypeAsync/${type}`)
  },
  getUniqueIdsBy (type) {
    return companyRepo.get(`${resource}/GetCompaniesListByTypeAsync/${type}`)
  },
  getSites (customGroupId, regionId, inventoryType) {
    const params = {
      customGroupId,
      regionId,
      inventoryType
    }
    return companyRepo.get(`${resource}/GetConsigneeIdsByCustomGroupAndRegionAsync`, { params })
  },
  getAllSites () {
    return companyRepo.get(`${resource}/GetAllCompanies`)
  },
  getSitesWithDOS (customGroupId, regionId, inventoryType) {
    const params = {
      customGroupId,
      regionId,
      inventoryType
    }
    return companyRepo.get(`${resource}/GetConsigneeIdsByCustomGroupAndRegionWithDOSAsync`, { params })
  },
  get (id) {
    return companyRepo.get(`${resource}/${id}`)
  },
  create (payload) {
    return companyRepo.post(`${resource}`, payload)
  },
  update (payload) {
    return companyRepo.put(`${resource}/${payload.id}`, payload)
  },
  delete (id) {
    return companyRepo.delete(`${resource}/${id}`)
  }
}

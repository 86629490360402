import global from '@/mixins/shared/global'
import Repository from './Repository'
const salesRepo = Repository(global.basePath)

const resource = '/Sales'

export default {
  getAll () {
    return salesRepo.get(`${resource}`)
  },
  get (id) {
    return salesRepo.get(`${resource}/${id}`)
  },
  create (payload) {
    return salesRepo.post(`${resource}`, payload)
  },
  update (id, payload) {
    return salesRepo.put(`${resource}/${id}`, payload)
  }
}

<template>
    <div class="catalog-container text-center">
        <b-container>
            <!-- Filter -->
            <b-row>
                <b-col offset-xl="4" col xl="4" offset-md="2" md="8">
                    <b-form-group id="filter-fieldset" label="Filter" label-class="font-weight-bold"
                        label-for="filter-input" label-cols-md="3">
                        <b-form-input id="filter-input" type="search" placeholder="Search" v-model="filter"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <!-- Items -->
            <b-row>
                <b-col offset-xl="4" col xl="4" offset-md="2" md="8">
                    <b-table id="catalog-table"
                    class="catalog-table"
                    bordered small no-border-collapse responsive="lg"
                    primary-key="id"
                    :items="items"
                    :fields="fields"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :filter="filter"
                    @filtered="onFiltered"
                    :filterIncludedFields="filterIncludedFields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection">
                        <template #cell(actions)="row">
                            <b-button v-if="!row.item.isSelected" variant="kag-light-blue"
                            class="edit-btn" size="sm" @click="addItem(row.item)"
                                v-b-tooltip.hover="'Assign'">
                                <font-awesome-icon class="clickable" icon="pen-to-square" />
                            </b-button>
                            <b-button v-else variant="kag-red" class="delete-btn" size="sm" @click="deleteItem(row.item)"
                                v-b-tooltip.hover="'Unassign'">
                                <font-awesome-icon class="clickable" icon="trash" />
                            </b-button>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                 <!-- Pagination -->
              <b-col col sm="6" offset-xl="3" xl="3" id="catalog-pagination" class="pagination-container">
                <b-pagination
                  class="justify-content-center"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                ></b-pagination>
              </b-col>
              <!-- Per Page -->
              <b-col col sm="6" offset-xl="1" xl="2" id="catalog-row-per-page">
                <b-form-select
                  class="justify-content-center"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                ></b-form-select>
              </b-col>
            </b-row>
        </b-container>
        <ConfirmModal ref="deleteModal"></ConfirmModal>
    </div>
</template>

<script >
import userAuthGetters from '@/mixins/shared/userAuthGetters'
import toastMixin from '@/mixins/shared/toastMixin'
import ConfirmModal from '@/components/shared/ConfirmModal.vue'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const UserRegionsRepo = RepositoryFactory.get('UserRegionsRepository')

export default {
  name: 'RegionsToSelect',
  mixins: [
    userAuthGetters,
    toastMixin
  ],
  props: {
    user: Object
  },
  components: {
    ConfirmModal
  },
  data () {
    return {
      userId: this.user.id,
      items: [],
      fields: [
        { key: 'description', label: 'Region Name', sortable: true, sortDirection: 'desc' },
        { key: 'actions', label: '', class: 'actions' }
      ],
      perPage: 10,
      pageOptions: [
        { value: 5, text: '5 per page' },
        { value: 10, text: '10 per page' },
        { value: 25, text: '25 per page' },
        { value: 50, text: '50 per page' },
        { value: 100, text: '100 per page' }
      ],
      totalRows: 0,
      currentPage: 1,
      filter: '',
      filterIncludedFields: ['description'],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc'
    }
  },
  methods: {
    init (items) {
      // this.items = items.filter((d) => d.isActive)
      this.items = items
      this.totalRows = this.items.length
      this.currentPage = 1
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async addItem (item) {
      const result = await this.$refs.deleteModal.show({
        title: 'Assign Region',
        text: `Are you sure you want to assign Region ${item.description} to the user?`,
        confirmText: 'Save',
        confirmVariant: 'outline-kag-light-blue'
      })
      if (result) {
        UserRegionsRepo.createUserRegion({ UserId: this.userId, RegionId: item.id })
          .then(() => {
            this.showSuccess('Region has been assigned')
            this.updateTable()
          })
          .catch((error) => {
            this.showError(global.handleError(error, 'An error occurred while trying to Assign Region. Please try again later'))
          })
      }
    },
    async deleteItem (item) {
      const result = await this.$refs.deleteModal.show({
        title: 'Unassign Region',
        text: `Are you sure you want to unassign Region ${item.description} for this user?`,
        confirmText: 'Delete',
        confirmVariant: 'outline-kag-red'
      })
      if (result) {
        UserRegionsRepo.deleteUserRegion(this.userId, item.id)
          .then(() => {
            this.showSuccess('Region has been unassigned')
            this.updateTable()
          })
          .catch((error) => {
            this.showError(global.handleError(error, 'An error occurred while trying to unassign Region. Please try again later'))
          })
      }
    },
    updateTable () {
      UserRegionsRepo.getByIdUser(this.userId)
        .then((response) => {
          const items = response && response.data ? response.data : []
          this.init(items)
        })
        .catch((error) => {
          this.showError(global.handleError(error, `An error occurred in the getByIdUser method with id ${this.userId}`))
          this.init([])
        })
    }
  },
  watch: {
    user (newVal, oldVal) {
      this.userId = this.user.id
      this.updateTable()
    }
  }
}
</script>
<style lang="scss">
@import '~@/assets/scss/themes/theme';

.catalog-container {
  .catalog-table {
    .actions {
      width: 80px;
      min-width: 80px;
      background-color: $white;
      border: none;
    }
    .keep-spaces { white-space: pre-wrap; }
  }
}
</style>

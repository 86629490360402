<template>
    <b-form class="edit-form" @submit.prevent="onSubmit">
        <b-container>
          <b-row>
            <b-col col>
              <b-form-group
                label-cols="4"
                content-cols="8"
                :key="tankEditData.tank.product"
                type="number"
                id="tank-number"
                label-for="tankNumber"
                class="threshold-form-group"
              >
              <template v-slot:label>
                Tank Number <span class="text-danger">*</span>
              </template>
                <b-form-input
                  :id="TankData.tank.product"
                  class="threshold-input"
                  type="number"
                  placeholder="Tank Number"
                  v-model="TankData.tank.tankNumber"
                  @input="validateTankNumber()"
                ></b-form-input>
                <span class="error-message" v-if="errorMessage.tankNumber">{{ errorMessage.tankNumber }}</span>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col col class="site-options">
              <b-form-group
                label-cols="4"
                content-cols="8"
                id="selection-commodity-fieldset"
                label-for="selection-commodity"
              >
                <template v-slot:label>
                  Commodity <span class="text-danger">*</span>
                </template>
                <b-form-select
                  id="selection-commodity"
                  placeholder="Product"
                  v-model="TankData.tank.commodityClass"
                  :options="commodityOptions"
                  @input="commoditySelect">
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col col class="site-optionCodes">
              <b-form-group
                label-cols="4"
                content-cols="8"
                id="selection-commodity-fieldset"
                label-for="selection-commodityCode"
              >
                <template v-slot:label>
                  Commodity Code <span class="text-danger">*</span>
                </template>
                <b-form-select
                  id="selection-commodityCode"
                  placeholder="Commodity Code"
                  v-model="TankData.tank.commodityCode"
                  :options="commodityCodeOptions"
                  @change="validateForm"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col col>
              <b-form-group
                label-cols="4"
                content-cols="8"
                :key="tankEditData.tank.product"
                id="tank-safefillQty"
                label="SafefillQty"
                label-for="safefillQty"
                class="threshold-form-group1"
              >
              <template v-slot:label>
                SafefillQty <span class="text-danger">*</span>
              </template>
                <b-form-input
                  :id="TankData.tank.product"
                  class="threshold-input"
                  type="number"
                  placeholder="Safe Fill"
                  v-model.number="TankData.tank.safefillQty"
                  @input="validateSafeFillQty()"
                  ></b-form-input>
                  <span class="error-message" v-if="errorMessage.safeFillQty">{{ errorMessage.safeFillQty }}</span>
              </b-form-group>
              <p class="error-message">{{ tankSizeError }}</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col col>
              <b-form-group
                label-cols="4"
                content-cols="8"
                :key="TankData.tank.product"
                id="tank-tankSize"
                label="Tank Size"
                label-for="tankSize"
                class="threshold-form-group1"
                >
                <template v-slot:label>
                  Tank Size <span class="text-danger">*</span>
                </template>
                <b-form-input
                :id="TankData.tank.product"
                class="threshold-input"
                type="number"
                placeholder="Physical Size"
                v-model.number="TankData.tank.tankSize"
                @input="validateTankSize()"
                ></b-form-input>
                <span class="error-message" v-if="errorMessage.tankSize">{{ errorMessage.tankSize }}</span>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col col>
              <b-form-group
                label-cols="4"
                content-cols="8"
                :key="TankData.tank.product"
                id="tank-threshold"
                label="Threshold"
                label-for="threshold"
                class="threshold-form-group"
              >
                <b-form-input
                  :id="TankData.tank.product"
                  class="threshold-input"
                  type="number"
                  placeholder="Threshold"
                  v-model.number="TankData.tank.threshold"
                  @input="validateForm()"
                ></b-form-input>
                <span class="error-message" v-if="errorMessage.threshold">{{ errorMessage.threshold }}</span>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col col>
              <b-form-group
                label-cols="4"
                content-cols="8"
                :key="TankData.tank.product"
                id="tank-pumpStopQty"
                label="Pump Stop"
                label-for="pumpStopQty"
                class="threshold-form-group"
              >
                <b-form-input
                  :id="TankData.tank.product"
                  class="threshold-input"
                  type="number"
                  placeholder="Pump Stop Qty"
                  v-model="TankData.tank.pumpStopQty"
                  @input="validateForm()"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" col lg="6" class="pt-2 pb-2">
              <b-button id="catalog-save-btn-modal" block variant="outline-kag-light-blue" type="submit" :disabled="submitButtonIsDisabled">Save</b-button>
            </b-col>
            <b-col cols="12" col lg="6" class="pt-2 pb-2">
              <b-button id="catalog-cancel-btn-modal" block variant="outline-kag-red"
                @click="closeModal">Cancel</b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
</template>

<script>
import global from '@/mixins/shared/global'

import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const CommodityRepo = RepositoryFactory.get('CommodityRepository')

export default {
  name: 'TankForm',
  data () {
    return {
      commodityOptions: [],
      commodityCodeOptions: [],
      errorMessage: {},
      submitButtonIsDisabled: true,
      tankEditData: {
        title: '',
        tank: {}
      },
      products: [],
      tankSizeError: ''
    }
  },
  computed: {
    thresholdIsValid () {
      return this.TankData.tank.threshold >= 0 && !(this.TankData.tank.threshold > this.TankData.tank.safefillQty || !!(this.TankData.tank.threshold % 1))
    },
    tankSizeisValid () {
      return this.TankData.tank.tankSize > 0 && !!this.TankData.tank.tankSize
    },
    tankNumberisValid () {
      return this.TankData.tank.tankNumber > 0 && !!this.TankData.tank.tankNumber
    },
    safefillQtyisValid () {
      return this.TankData.tank.safefillQty > 0 && this.TankData.tank.safefillQty < this.TankData.tank.tankSize
    },
    commodityisValid () {
      return !!this.TankData.tank.commodity && this.TankData.tank.commodityCode
    },
    pumpStopisValid () {
      return this.TankData.tank.pumpStopQty >= 0
    }
  },
  async created () {
    const commodities = await CommodityRepo.getCommodities()

    this.products = commodities.data.map(product => {
      return {
        commodityClass: product.commodityClass,
        commodity: product.commodityName,
        commodityCode: product.commodityCodes
      }
    })
    this.commodityOptions = this.products.map(global.mapUpperCase('commodity')).sort(global.sortBy('commodity')).map(global.productsMapOptions('commodityClass', 'commodity'))
    this.TankData.type === 'edit' && this.commoditySelect(this.TankData.tank.commodityClass)
  },
  methods: {
    closeModal () {
      this.TankData.title = ''
      this.TankData.tank = {}
      this.$emit('closeModal', true)
    },
    commoditySelect (commodityClass) {
      const commodityCode = this.TankData.tank.commodityCode
      if (commodityCode) {
        this.TankData.tank.commodityCode = ''
      }

      const item = this.products.find((s) => s.commodityClass === commodityClass)

      if (item) {
        this.TankData.tank.commodity = item.commodity

        if (commodityCode) {
          const currentCommodityCode = item.commodityCode.find((code) => code.commodityCode === commodityCode)
          this.TankData.tank.commodityCode = currentCommodityCode ? currentCommodityCode.commodityCode : ''
        }

        this.commodityCodeOptions = item.commodityCode.map(code => {
          return {
            text: code.commodityCode,
            value: code.commodityCode
          }
        })
        this.validateForm()
      }
    },
    onSubmit () {
      this.TankData.type === 'new' ? this.$emit('onCreate', this.TankData) : this.$emit('onUpdate', this.TankData)
    },
    validateTankNumber () {
      this.errorMessage.tankNumber = ''

      if (this.TankData.tank.tankNumber < 0) {
        this.errorMessage.tankNumber = 'Tank number must be greater than zero'
      }

      if (!this.TankData.tank.tankNumber || isNaN(this.TankData.tank.tankNumber)) {
        this.errorMessage.tankNumber = 'This field is required'
      }

      this.validateForm()
    },
    validateSafeFillQty () {
      this.errorMessage.safeFillQty = ''
      if (this.TankData.tank.safefillQty < 0) {
        this.errorMessage.safeFillQty = 'SafeFillQty must be greater than zero'
      }

      if (this.TankData.tank.safefillQty >= 0 && this.TankData.tank.safefillQty > this.TankData.tank.tankSize) {
        this.errorMessage.safeFillQty = 'Safe fill quality must be less than tank size'
      }

      if (!this.TankData.tank.safefillQty || isNaN(this.TankData.tank.safefillQty)) {
        this.errorMessage.safeFillQty = 'This field is required'
      }

      this.validateForm()
    },
    validateTankSize () {
      this.errorMessage.tankSize = ''

      if (this.TankData.tank.tankSize < 0) {
        this.errorMessage.tankSize = 'Tank size must be greater than zero'
      }

      if (this.TankData.tank.tankSize >= 0 && this.TankData.tank.safefillQty > this.TankData.tank.tankSize) {
        this.errorMessage.tankSize = 'Safe fill quality must be less than tank size'
      }

      if (!this.TankData.tank.tankSize || isNaN(this.TankData.tank.tankSize)) {
        this.errorMessage.tankSize = 'This field is required'
      }
      this.validateForm()
    },
    validateForm () {
      if (this.TankData.tank.pumpStopQty === '') {
        this.TankData.tank.pumpStopQty = 0
      }

      if (this.TankData.tank.threshold === '') {
        this.TankData.tank.threshold = 0
      }

      this.tankNumberisValid && this.safefillQtyisValid && this.tankSizeisValid &&
      this.thresholdIsValid && this.commodityisValid && this.pumpStopisValid ? this.submitButtonIsDisabled = false : this.submitButtonIsDisabled = true
    }
  },
  props: {
    TankData: Object
  }
}
</script>

<style lang="scss">
.error-message {
  color: red;
  font-size: 13px;
}
</style>

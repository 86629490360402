<template>
  <div>
    <div v-if="showData" class="forecast-history-container">
      <div class="forecast-history-date-table-container">
        <b-table
          id="forecast-history-date-table"
          class="forecast-history-date-table"
          bordered
          small
          no-border-collapse
          tbody-tr-class="table-row"
          :items="dateData"
          :fields="dateFields"
        >
          <template #head(date)="data">
            <div>&nbsp;</div>
            <span>
              {{ data.label.toUpperCase() }}
            </span>
          </template>
          <template #cell()="data">
            <span>
              {{ data.item.date }}
              <font-awesome-icon v-if="highlightInventoryReadingInRed(data.index)" class="text-danger" icon="bell" />
            </span>
          </template>
        </b-table>
      </div>
      <div class="forecast-history-data-container">
        <div class="forecast-history-inventory-table-container">
          <div class="title-container text-center">
            <strong class="title">Inventory</strong>
          </div>
          <b-table
            id="forecast-history-inventory-table"
            class="forecast-history-inventory-table"
            bordered
            small
            no-border-collapse
            tbody-tr-class="table-row"
            :items="inventoryData"
            :fields="inventoryFields"
          >
            <template slot="top-row">
              <td id="forecast-history-inventory-no-records" v-if="!inventoryData || inventoryData.length === 0" class="text-center align-middle">
                No records to display
              </td>
            </template>
            <template #head()="data">
              <span>{{ data.column }}</span>
              <div>{{ data.label }}</div>
            </template>
            <template v-for="field in inventoryFields" v-slot:[`cell(${field.key})`]="{ item }">
              <div class="td-content" :key="field.key" v-if="item[field.key] && item[field.key].daysOfSupply !== undefined">
                {{ item[field.key].daysOfSupply }}
              </div>
              <div class="td-content" :key="field.key" v-if="item[field.key] && item[field.key].avgDailySales !== undefined">
                {{ item[field.key].avgDailySales }}
              </div>
              <div class="td-content" :class="{ 'selected': item[field.key].id === cellSelected }" :key="field.key" v-if="item[field.key] && item[field.key].daysOfSupply == undefined && item[field.key].avgDailySales === undefined" @click="selectCell(item[field.key].id)">
                {{ item[field.key].inventory }}
              </div>
            </template>
          </b-table>
        </div>
        <div class="forecast-history-can-hold-table-container">
          <div class="title-container text-center">
            <strong class="title">Can Hold</strong>
          </div>
          <b-table
            id="forecast-history-can-hold-table"
            class="forecast-history-can-hold-table"
            bordered
            small
            no-border-collapse
            tbody-tr-class="table-row"
            :items="canHoldData"
            :fields="canHoldFields"
          >
            <template slot="top-row">
              <td id="forecast-history-can-hold-no-records" v-if="!canHoldData || canHoldData.length === 0" class="text-center align-middle">
                No records to display
              </td>
            </template>
            <template #head()="data">
              <span>{{ data.column }}</span>
              <div>{{ data.label }}</div>
            </template>
            <template v-for="field in canHoldFields" v-slot:[`cell(${field.key})`]="{ item }">
              <div v-if="item[field.key]" class="td-content" :class="{ 'selected': item[field.key].id === cellSelected }" :key="field.key" @click="selectCell(item[field.key].id)">
                {{ item[field.key].canHold }}
              </div>
            </template>
          </b-table>
        </div>
        <div class="forecast-history-sales-table-container">
          <div class="title-container text-center">
            <strong class="title">Sales</strong>
          </div>
          <b-table
            id="forecast-history-sales-table"
            class="forecast-history-sales-table"
            bordered
            small
            no-border-collapse
            tbody-tr-class="table-row"
            :items="salesData"
            :fields="salesFields"
          >
            <template slot="top-row">
              <td id="forecast-history-sales-no-records" v-if="!salesData || salesData.length === 0" class="text-center align-middle">
                No records to display
              </td>
            </template>
            <template #head()="data">
              <span>{{ data.column == 'salesDate' || data.column == 'Action' ? '' : data.column }}</span>
              <div>{{ data.label.toUpperCase() }}</div>
            </template>
            <template v-for="field in salesFields" v-slot:[`cell(${field.key})`]="{ item }">
              <div class="td-content" v-if="item[field.key] && field.key === 'salesDate'" :key="field.key">
                {{ item.salesDate }}
              </div>
              <div class="td-content"
                :class="{ 'selected': item[field.key].id === cellSelected,
                          'bg-danger':  item[field.key].sales < 0 }"
                v-if="item[field.key] && field.key !== 'salesDate' && field.key !== 'Action'"
                :key="field.key"
                @click="selectCell(item[field.key].id)">
                {{ item[field.key].sales }}
              </div>
              <div class="td-content btn-kag-light-blue"
                v-b-tooltip.hover="'Edit'"
                v-if="item[field.key] && field.key == 'Action'"
                @click="editItem(item)"
                :key="field.key">
                <font-awesome-icon class="clickable" icon="pen-to-square" />
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <div v-if="!showData" class="text-center">
      No records to display
    </div>

    <!-- Sales edit modal -->
    <b-modal ref="salesEditModal" id="sales-edit-modal" hide-footer centered :title="salesEditData.title">
      <b-form class="edit-form" @submit.prevent="confirmModal">
        <b-container>
          <b-row>
            <b-col col>
              <b-form-group
                label-cols="3"
                content-cols="9"
                v-for="(sale, idx) in salesEditData.sales.filter((s) => s.product !== 'Action')"
                :key="sale.product"
                :id="'sale-fieldset-' + idx"
                :label="sale.product"
                :label-for="sale.product"
              >
                <b-form-input
                  :id="sale.product" type="number"
                  placeholder="Sales"
                  v-model="sale.sale.sales">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" col lg="6" class="pt-2 pb-2">
              <b-button id="catalog-save-btn-modal" block variant="outline-kag-light-blue" type="submit">Save</b-button>
            </b-col>
            <b-col cols="12" col lg="6" class="pt-2 pb-2">
              <b-button id="catalog-cancel-btn-modal" block variant="outline-kag-red" @click="cancelModal">Cancel</b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
    </b-modal>
  </div>
</template>

<style lang="scss">
@import '~@/assets/scss/themes/theme';

.forecast-history-container {
  width: 100%;
  display: inline-flex;

  > div {
    display: inline-flex;
  }

  $date-container-width: 160px;
  $actions-container-width: 45px;
  $table-title-height: 35px;
  $row-height: 33px;

  .forecast-history-date-table-container {
    width: $date-container-width;
    margin-top: 35px;

    .forecast-history-date-table {
      tbody {
        tr:last-child,
        tr:nth-last-child(2) {
          background-color: $kag-table-head-bg-color;
          font-weight: bold;
        }

        tr {
          height: $row-height;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        td {
          padding: 0;

          .td-content {
            width: 100%;
            height: 100%;
            padding: 0.3rem;
          }
        }
      }
    }
  }

  .forecast-history-data-container {
    width: calc(100% - #{$date-container-width} - #{$actions-container-width});
    overflow-x: overlay;

    > div {
      flex: 1;

      .title-container {
        height: $table-title-height;
        color: white;
        background-color: $kag-dark-blue-color;
        margin: 0 2px;

        .title {
          vertical-align: sub;
        }
      }

      table {
        tr {
          height: $row-height;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }

    .forecast-history-inventory-table {
      tbody {
        tr:last-child,
        tr:nth-last-child(2) {
          background-color: $kag-table-head-bg-color;
          font-weight: bold;
        }
      }
    }

    .forecast-history-inventory-table,
    .forecast-history-can-hold-table,
    .forecast-history-sales-table {
      td {
        padding: 0;

        .td-content {
          width: 100%;
          height: 100%;
          padding: 0.3rem;

          &.selected {
            background-color: $kag-warning-color;
          }
        }
      }
    }

    $sales-date-width: 60px;
    .forecast-history-sales-table {
      .sales-date {
        width: $sales-date-width;
      }
    }
  }

  .forecast-history-actions-table-container {
    width: $actions-container-width;
    margin-top: calc(#{$table-title-height} + #{$row-height});

    table {
      tr {
        height: $row-height;
      }
    }

    .actions {
      background-color: $white;
      border: none;

      .btn {
        font-size: 0.475rem;
      }
    }
  }
}
</style>

<script>
import moment from 'moment-timezone'

import global from '@/mixins/shared/global'
import userAuthGetters from '@/mixins/shared/userAuthGetters'
import toastMixin from '@/mixins/shared/toastMixin'

import { RepositoryFactory } from '@/repositories/RepositoryFactory'

const SaleRepo = RepositoryFactory.get('SalesRepository')

export default {
  name: 'ForecastHistory',
  mixins: [
    userAuthGetters,
    toastMixin
  ],
  data () {
    return {
      showData: false,
      dateFields: [
        { key: 'date', label: 'Date', thClass: 'text-center', tdClass: 'td-content' }
      ],
      inventoryFields: [],
      inventoryData: [],
      canHoldFields: [],
      dateData: [],
      canHoldData: [],
      salesFields: [],
      salesData: [],
      actionFields: [
        { key: 'actions', label: '', class: 'actions' }
      ],
      salesEditData: {
        title: '',
        sales: []
      },
      cellSelected: ''
    }
  },
  props: {
    tankItems: Array,
    timeZoneId: String,
    showInventoryReadingAlert: Boolean
  },
  watch: {
    tankItems (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.buildHistory()
      }
    }
  },
  created () {
    this.buildHistory()
  },
  methods: {
    editItem (saleItem) {
      if (this.canUpdateSales) {
        const dailySales = []
        let date
        for (const key in saleItem) {
          if (['ids', 'id'].includes(key)) {
            continue
          }
          if (key === 'salesDate') {
            date = saleItem[key]
            continue
          }
          dailySales.push({ product: key, sale: { ...saleItem[key] }, id: saleItem[key].saleId })
        }

        this.salesEditData.title = `Editing Sales for ${date}`
        this.salesEditData.sales = dailySales
        this.$refs.salesEditModal.show()
      } else {
        this.showError('Current user does not have permissions to edit sales')
      }
    },
    cancelModal () {
      this.salesEditData.title = ''
      this.salesEditData.sales = []
      this.$refs.salesEditModal.hide()
    },
    confirmModal () {
      Promise.all(
        this.salesEditData.sales.map(async (s) => {
          if (s.id !== -1) {
            const salesAmount = +s.sale.sales > 0 ? +s.sale.sales : 0
            return await SaleRepo.update(s.id, { salesAmount: salesAmount })
          }
        })
      )
        .then(() => {
          this.showSuccess('Sales updated successfully')
          this.$emit('sales-updated')
        })
        .catch((error) => {
          this.showError(global.handleError(error, 'An error occurred while updating sales. Please try again later'))
        })
        .finally(() => {
          this.salesEditData.title = ''
          this.salesEditData.sales = []
          this.$refs.salesEditModal.hide()
        })
    },
    buildHistory () {
      this.inventoryFields = []
      this.inventoryData = []
      this.canHoldFields = []
      this.dateData = []
      this.canHoldData = []
      this.salesFields = []
      this.salesData = []

      const inventories = []
      const sales = []
      const additionalInfo = []
      this.tankItems.forEach((tank) => {
        let name = tank.commodityClass
        if (this.tankItems.filter((t) => t.commodityClass === name).length > 1) {
          name += ` (${tank.tankNumber})`
        }

        additionalInfo.push({
          name,
          avgDailySales: tank.avgDailySales,
          daysOfSupply: tank.daysOfSupply
        })

        tank.sales.forEach((sale) => {
          let currentIdx = sales.findIndex((i) => this.formatSalesSimpleDate(i.salesTimeStamp) === this.formatSalesSimpleDate(sale.salesTimeStamp))
          const saleDate = new Date(sale.salesTimeStamp)
          if (currentIdx === -1) {
            sales.push({
              salesTimeStamp: sale.salesTimeStamp,
              date: saleDate,
              salesDate: this.formatSalesSimpleDate(sale.salesTimeStamp),
              tankSales: []
            })
            currentIdx = sales.length - 1
          }
          const newTankSales = {
            tankId: sale.tankId,
            name: name,
            commodityCode: tank.commodityCode,
            saleId: sale.id,
            sales: sale.salesAmount ? Math.round(sale.salesAmount) : 0,
            saleDate: this.formatSalesDate(sale.salesTimeStamp),
            salesThresholdExceeded: sale.salesThresholdExceeded
          }
          sales[currentIdx].tankSales.push(newTankSales)
        })
        tank.inventories.forEach((inv) => {
          let currentIdx = inventories.findIndex((i) => i.inventoryTimeStamp === inv.inventoryTimeStamp)
          const invDate = new Date(inv.inventoryTimeStamp)
          if (currentIdx === -1) {
            inventories.push({
              inventoryTimeStamp: inv.inventoryTimeStamp,
              date: invDate,
              dateString: this.formatDate(invDate),
              previousDate: this.formatPreviousDate(invDate),
              tanks: []
            })
            currentIdx = inventories.length - 1
          }

          const inventory = inv.inventoryReading ? Math.round(inv.inventoryReading) : 0
          const newTank = {
            tankId: tank.id,
            name: name,
            inventory,
            canHold: tank.safefillQty - inventory,
            commodityCode: tank.commodityCode
          }
          inventories[currentIdx].tanks.push(newTank)
        })
      })

      if (sales.length > 0) {
        this.salesFields.push({ key: 'salesDate', label: 'Date', class: 'sales-date' })
        // Sort inventories by timesyamp
        sales.sort((a, b) => {
          if (a.date.getTime() < b.date.getTime()) {
            return 1
          }
          if (a.date.getTime() > b.date.getTime()) {
            return -1
          }
          return 0
        })
        sales.forEach((salesForecastData, saleIdx) => {
          const sale = {}
          salesForecastData.tankSales.forEach((tank) => {
            const salesExist = this.salesFields.findIndex((i) => i.key === tank.name) >= 0
            if (!salesExist) {
              this.salesFields.push(
                {
                  key: tank.name,
                  class: 'text-center',
                  label: tank.commodityCode
                })
            }
            sale.id = `${saleIdx}-${tank.name}`
            sale[tank.name] = { id: `${saleIdx}-${tank.name}`, sales: tank.sales, saleId: tank.saleId, salesThresholdExceeded: tank.salesThresholdExceeded }
            sale.salesDate = salesForecastData.salesDate
            if (!sale.ids) {
              sale.ids = []
            }
            sale.ids.push(tank.saleId)
          })
          if (this.canUpdateSales) {
            sale.Action = { id: `${saleIdx}-Action`, sales: 0, saleId: -1, salesThresholdExceeded: false }
          }
          this.salesData.push(sale)
        })
      }

      if (inventories.length > 0) {
        // Sort inventories by timesyamp
        inventories.sort((a, b) => {
          if (a.date.getTime() < b.date.getTime()) {
            return 1
          }
          if (a.date.getTime() > b.date.getTime()) {
            return -1
          }
          return 0
        })

        inventories.forEach((forecastData, invIdx) => {
          const inventory = {}
          const canHold = {}
          forecastData.tanks.forEach((tank) => {
            const inventoryExist = this.inventoryFields.findIndex((i) => i.key === tank.name) >= 0
            if (!inventoryExist) {
              this.inventoryFields.push(
                {
                  key: tank.name,
                  class: 'text-center',
                  label: tank.commodityCode
                })
            }
            const canHoldExist = this.canHoldFields.findIndex((i) => i.key === tank.name) >= 0
            if (!canHoldExist) {
              this.canHoldFields.push(
                {
                  key: tank.name,
                  class: 'text-center',
                  label: tank.commodityCode
                })
            }
            inventory[tank.name] = { id: `${invIdx}-${tank.name}`, inventory: tank.inventory, commodityCode: tank.commodityCode }
            canHold.id = `${invIdx}-${tank.name}`
            canHold[tank.name] = { id: `${invIdx}-${tank.name}`, canHold: tank.canHold }
          })
          this.inventoryData.push(inventory)
          this.canHoldData.push(canHold)

          this.dateData.push({ date: forecastData.dateString, inventoryTimeStamp: forecastData.inventoryTimeStamp })
        })
        const displayRows = 7
        if (this.dateData.length > displayRows) {
          this.dateData.splice(displayRows)
          this.inventoryData.splice(displayRows)
          this.canHoldData.splice(displayRows)
          this.salesData.splice(displayRows)
        }

        this.canHoldFields.sort((a, b) => {
          if (a.key < b.key) {
            return -1
          }
          if (a.key > b.key) {
            return 1
          }
          return 0
        })

        this.salesFields.sort((a, b) => {
          if (a.key < b.key) {
            return -1
          }
          if (a.key > b.key) {
            return 1
          }
          return 0
        })
        if (this.canUpdateSales && sales.length > 0) {
          this.salesFields.push(
            {
              key: 'Action',
              class: 'text-center',
              label: 'EDIT'
            })
        }
        this.salesFields.unshift({ key: 'salesDate', label: 'Date', class: 'sales-date' })

        this.inventoryFields.sort((a, b) => {
          if (a.key < b.key) {
            return -1
          }
          if (a.key > b.key) {
            return 1
          }
          return 0
        })
        const daysOfSupply = {}
        const dailySales = {}
        additionalInfo.forEach((tank) => {
          daysOfSupply[tank.name] = {
            daysOfSupply: !!tank.daysOfSupply || tank.daysOfSupply === 0 ? tank.daysOfSupply : 'N/A'
          }
          dailySales[tank.name] = {
            avgDailySales: !!tank.avgDailySales || tank.avgDailySales === 0 ? tank.avgDailySales : 'N/A'
          }
        })

        this.inventoryData.push(daysOfSupply)
        this.inventoryData.push(dailySales)

        this.dateData.push({ date: 'Days of supply' })
        this.dateData.push({ date: 'Average daily sales' })

        this.showData = true
      } else {
        this.showData = false
      }
    },
    formatDate (date) {
      return moment(date).format('ddd MM/DD HH:mm')
    },
    formatPreviousDate (date) {
      return moment(date).subtract(1, 'days').format('MM/DD')
    },
    formatInventorySalesDate (date) {
      return moment(date).subtract(1, 'days').format('yyyy/MM/DD')
    },
    formatSalesSimpleDate (date) {
      return moment(date).format('MM/DD')
    },
    formatSalesDate (date) {
      return moment(date).format('yyyy/MM/DD')
    },
    selectCell (id) {
      if (id === this.cellSelected) {
        this.cellSelected = ''
      } else {
        this.cellSelected = id
      }
    },
    highlightInventoryReadingInRed (index) {
      // Highlight first inventory reading in read:
      // If the number of hours since the inventory reading is greater then 12
      // If the date is not equal to today
      if (index > 0 || !this.showInventoryReadingAlert) return false
      const inventoryReadingDate = moment(new Date(this.dateData[index].inventoryTimeStamp))
      const hoursDiff = moment().diff(inventoryReadingDate, 'hours')
      return hoursDiff > 12 || moment(inventoryReadingDate).format('yyyy/MM/DD') !== moment().format('yyyy/MM/DD')
    }
  }
}
</script>

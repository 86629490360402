<template>
  <div class="regions">
    <Catalog
      ref="catalogComponent"
      item-name="Region"
      :get-all-request="getAllRequest"
      :create-request="createRequest"
      :update-request="updateRequest"
      :initial-items="items"
      :can-create="canCreateRegion"
      :can-update="canUpdateRegion"
      :can-delete="canDeleteRegion"
      @deleteItem="deleteRegion"
    ></Catalog>
    <ConfirmModal ref="deleteModal"></ConfirmModal>
  </div>
</template>

<script>
import global from '@/mixins/shared/global'
import userAuthGetters from '@/mixins/shared/userAuthGetters'
import toastMixin from '@/mixins/shared/toastMixin'

import Catalog from '../../components/shared/Catalog.vue'
import ConfirmModal from '@/components/shared/ConfirmModal.vue'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'

const RegionRepo = RepositoryFactory.get('RegionRepository')
const CompanyRepo = RepositoryFactory.get('CompanyRepository')
const UserRegionsRepo = RepositoryFactory.get('UserRegionsRepository')

export default {
  name: 'Regions',
  mixins: [
    userAuthGetters,
    toastMixin
  ],
  components: {
    Catalog,
    ConfirmModal
  },
  data () {
    return {
      getAllRequest: RegionRepo.getAll,
      createRequest: RegionRepo.create,
      updateRequest: RegionRepo.update,
      items: []
    }
  },
  async beforeRouteEnter (to, from, next) {
    try {
      const response = await RegionRepo.getAll()
      to.meta.regions = response && response.data ? response.data : []
      next()
    } catch {
      next('/error')
    }
  },
  created () {
    if (!this.canViewRegion) {
      if (this.canViewCustomGroup) {
        this.$router.push({ name: 'CustomGroups' })
      } else if (this.canViewCompany) {
        this.$router.push({ name: 'Sites' })
      } else {
        this.$router.push({ name: 'AccessDenied' })
      }
      return
    }
    this.items = this.$route.meta.regions
  },
  methods: {
    async deleteRegion (item) {
      const companyResult = await CompanyRepo.getSites(-1, item.id)
      if (companyResult && companyResult.data && companyResult.data.sites && companyResult.data.sites.length === 0) {
        const assignedToUser = await this.isAssignedRegion(item)
        if (!assignedToUser) {
          const result = await this.$refs.deleteModal.show({
            title: 'Delete Region',
            text: `Are you sure you want to delete Region ${item.description}?`,
            confirmText: 'Delete',
            confirmVariant: 'outline-kag-red'
          })
          if (result) {
            item.isDeleted = true
            RegionRepo.update(item)
              .then(() => {
                this.$refs.catalogComponent.fetch()
              })
              .catch((error) => {
                this.showError(global.handleError(error, 'An error occurred while trying to delete Region. Please try again later'))
              })
          }
        }
      } else {
        this.showError(`${item.description} is currently assigned to a Site. Make sure it is not assigned to a Site before deleting it`)
      }
    },
    async isAssignedRegion (item) {
      const userRegionsResult = await UserRegionsRepo.getByIdRegion(item.id)
      if (userRegionsResult && userRegionsResult.data && userRegionsResult.data.length === 0) {
        return false
      } else {
        this.showError(`${item.description} is currently assigned to a User Profile. Make sure it is not assigned to a User Profile before deleting it`)
        return true
      }
    }
  }
}
</script>

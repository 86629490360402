/* eslint-disable camelcase */
import Vue from 'vue'
import Vuex from 'vuex'
import global from '@/mixins/shared/global'
import { vuexOidcCreateStoreModule } from 'vuex-oidc'
import { oidcSettings } from '../configuration/oidc'
import jwt_decode from 'jwt-decode'
import userAuthorization from './modules/UserAuthorization/userAuthorization'
import orders from './modules/Orders/orders'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    userAuthorization,
    orders: orders,
    oidcStore: vuexOidcCreateStoreModule(oidcSettings,
      {
        namespaced: true,
        dispatchEventsOnWindow: true
      },
      // Optional OIDC event listeners
      {
        userLoaded: async (user) => {
          if (user && user.access_token) {
            const token = jwt_decode(user.access_token)
            const authTypeKey = 'Inventories_'
            const clientAuthTypeKey = 'client_Inventories_'
            for (const [key, value] of Object.entries(token)) {
              if (key.startsWith(authTypeKey)) {
                const authType = key.substring(authTypeKey.length)
                if (value instanceof Array) {
                  for (const permission of value) {
                    userAuthorization.state[`can${permission}${authType}`] = true
                  }
                } else if (typeof value === 'string') {
                  const permission = `can${value}${authType}`
                  if (permission in userAuthorization.state) {
                    userAuthorization.state[`can${value}${authType}`] = true
                  }
                }
              } else if (key.startsWith(clientAuthTypeKey)) {
                const authType = key.substring(clientAuthTypeKey.length)
                if (value instanceof Array) {
                  for (const permission of value) {
                    userAuthorization.state[`can${permission}${authType}`] = true
                  }
                } else if (typeof value === 'string') {
                  const permission = `can${value}${authType}`
                  if (permission in userAuthorization.state) {
                    userAuthorization.state[`can${value}${authType}`] = true
                  }
                }
              }
              if (key === 'client_FIM_Tenant_Name') {
                userAuthorization.state.tenantName = value
              }
              if (key.startsWith('client_FIMPermissions')) {
                if (value.includes('Create')) {
                  userAuthorization.state.hasInventoryManagementAdmin = true
                }
              }
              if (key.startsWith('first_name')) {
                userAuthorization.state.firstName = value
              }
              if (key.startsWith('last_name')) {
                userAuthorization.state.lastName = value
              }
              if (key.startsWith('email')) {
                userAuthorization.state.email = value
              }
              if (key.startsWith('client_UICalculatorEditor')) {
                userAuthorization.state.UICalculatorEditor = value === 'true'
              }
              if (key.startsWith('client_UICompanyEditor')) {
                userAuthorization.state.UICompanyEditor = value === 'true'
              }
              if (key.startsWith('client_UITankEditor')) {
                userAuthorization.state.UITankEditor = value === 'true'
              }
              if (key.startsWith('client_UIUserProfilesEditor')) {
                userAuthorization.state.UIUserProfilesEditor = value === 'true'
              }
              if (key.startsWith('userProfilesAdmin')) {
                userAuthorization.state.userProfilesAdmin = value === 'true'
              }
            }
            if (Object.hasOwn(token, `FIM_Tenant_${userAuthorization.state.tenantName}`)) {
              userAuthorization.state.tenantNameMatch = true
              document.title = `(${userAuthorization.state.tenantName}) Inventory Management`
            }
          }
          if (global.appConfig.LogOidcEvents) console.log('OIDC user is loaded:', user)
        },
        userUnloaded: () => {
          if (global.appConfig.LogOidcEvents) console.log('OIDC user is unloaded')
        },
        accessTokenExpiring: () => {
          if (global.appConfig.LogOidcEvents) console.log('Access token will expire')
        },
        accessTokenExpired: () => {
          if (global.appConfig.LogOidcEvents) console.log('Access token did expire')
        },
        silentRenewError: () => {
          if (global.appConfig.LogOidcEvents) console.log('Silent Renew Error')
        },
        userSignedOut: () => {
          if (global.appConfig.LogOidcEvents) console.log('OIDC user is signed out')
        },
        oidcError: (payload) => {
          if (global.appConfig.LogOidcEvents) console.log(`An error occured at ${payload.context}:`, payload.error)
        }
      })
  }
})

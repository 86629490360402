<template>
  <div id="inventory-history" class="inventory-history">
    <b-modal ref="inventoryHistoryModal" size="lg" no-close-on-backdrop hide-footer :static="true" title="Inventory History" @hide="cancel">
<b-row>
        <b-col>
          <b-input-group>
            <b-form-checkbox class="btn-sitesort" v-model="showAll" name="check-button" switch @change="build" :disabled="inventoryData.length < 1">
              First Inventory of the day
          </b-form-checkbox>
          </b-input-group>
        </b-col>
      </b-row>
      <b-form class="inventory-history-range-form" @submit.prevent="build()">
        <b-row>
          <b-col col cols="12" xl="5">
            <b-input-group>
              <b-form-input
                id="inventory-start-date"
                v-model="inventoryStartDateString"
                type="text"
                size="sm"
                placeholder="MM/DD/YY"
                autocomplete="off"
                maxlength="8"
                :state="startDateState"
                @input="onStartDateChange"
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  button-only
                  left
                  id="inventory-start-date-icon"
                  size="sm"
                  :min="minStart"
                  :max="inventoryEndDate"
                  value-as-date
                  hide-header
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  locale="en"
                  v-model="inventoryStartDate"
                  @context="onStartDateContext"
                ></b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col col cols="12" xl="5">
            <b-input-group>
              <b-form-input
                id="inventory-end-date"
                v-model="inventoryEndDateString"
                type="text"
                size="sm"
                placeholder="MM/DD/YY"
                autocomplete="off"
                maxlength="8"
                :state="endDateState"
                @input="onEndDateChange"
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  button-only
                  left
                  id="inventory-end-date-icon"
                  size="sm"
                  :min="inventoryStartDate"
                  :max="maxEnd"
                  value-as-date
                  hide-header
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  locale="en"
                  v-model="inventoryEndDate"
                  @context="onEndDateContext"
                ></b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col col cols="12" xl="2">
            <b-button id="inventory-history-filter-btn" type="submit" variant="kag-dark-blue" block size="sm" :disabled="startDateState === false || endDateState === false">Filter</b-button>
          </b-col>
        </b-row>
      </b-form>
      <b-container>
        <b-row>
          <b-col cols="12" col>
            <div v-if="showData" class="inventory-history-container mt-3">
              <div>
                <div class="inventory-tables-container" :class="{ 'no-actions': !canUpdateInventory && !canDeleteInventory }">
                  <div class="inventory-container w-100">
                    <div class="inventory-history-date-container">
                      <b-table
                        id="inventory-history-date-table"
                        class="inventory-history-date-table"
                        bordered
                        small
                        no-border-collapse
                        tbody-tr-class="table-row"
                        :fields="dateFields"
                        :items="dateData"
                        :per-page="perPage"
                        :current-page="currentPage"
                      >
                      </b-table>
                    </div>

                    <div class="inventory-history-data-container">
                      <b-table
                        id="forecast-history-table"
                        class="inventory-history-table"
                        bordered
                        small
                        no-border-collapse
                        tbody-tr-class="table-row"
                        :fields="inventoryFields"
                        :items="inventoryData"
                        :per-page="perPage"
                        :current-page="currentPage"
                      >
                        <template v-for="field in inventoryFields" v-slot:[`cell(${field.key})`]="{ item }">
                          <div v-if="item[field.key] && field.key !== 'Actions'" :key="field.key">
                            {{ item[field.key].inventoryReading }}
                          </div>
                          <div class="td-content"
                            v-if="item[field.key] && field.key == 'Actions'"
                            :key="field.key">
                              <font-awesome-icon v-if="canUpdateInventory" class="clickable icon-light-blue pr-3" icon="pen-to-square" :id="'edit' + item[field.key].name" v-b-tooltip.hover="'Edit'" @click="editItem(item)"/>
                              <font-awesome-icon v-if="canDeleteInventory" class="clickable icon-red" icon="trash" :id="'delete' + item[field.key].name" v-b-tooltip.hover="'Delete'" @click="deleteItem(item)"/>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!showData" class="text-center">
              No records to display
            </div>
          </b-col>
        </b-row>
        <b-row v-if="showData">
          <!-- Pagination -->
          <b-col col sm="6" offset-xl="1" xl="7" class="pagination-container">
            <b-pagination
              id="inventory-history-pagination"
              class="justify-content-center"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
            ></b-pagination>
          </b-col>
          <!-- Per Page -->
          <b-col col sm="6" offset-xl="1" xl="3">
            <b-form-select
              id="inventory-row-per-page"
              class="justify-content-end"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <InventoryEdit ref="invEditModal"></InventoryEdit>
    <ConfirmModal ref="deleteModal"></ConfirmModal>
  </div>
</template>

<script>
import moment from 'moment-timezone'

import global from '@/mixins/shared/global'
import userAuthGetters from '@/mixins/shared/userAuthGetters'
import toastMixin from '@/mixins/shared/toastMixin'

import InventoryEdit from '@/components/site/InventoryEdit'
import ConfirmModal from '@/components/shared/ConfirmModal.vue'

import { RepositoryFactory } from '@/repositories/RepositoryFactory'

const TankRepo = RepositoryFactory.get('TankRepository')
const InventoryRepo = RepositoryFactory.get('InventoryRepository')

export default {
  name: 'InventoryHistory',
  mixins: [
    userAuthGetters,
    toastMixin
  ],
  components: {
    InventoryEdit,
    ConfirmModal
  },
  data () {
    const minStartDate = new Date()
    minStartDate.setHours(0)
    minStartDate.setMinutes(0)
    minStartDate.setSeconds(0)
    minStartDate.setMilliseconds(0)
    minStartDate.setDate(minStartDate.getDate() - 180)
    const startDate = new Date()
    startDate.setHours(0)
    startDate.setMinutes(0)
    startDate.setSeconds(0)
    startDate.setMilliseconds(0)
    startDate.setDate(startDate.getDate() - 30)
    const endDate = new Date()
    endDate.setHours(23)
    endDate.setMinutes(59)
    endDate.setSeconds(59)
    endDate.setMilliseconds(999)
    const maxEndDate = new Date()
    maxEndDate.setHours(23)
    maxEndDate.setMinutes(59)
    maxEndDate.setSeconds(59)
    maxEndDate.setMilliseconds(999)

    return {
      showAll: true,
      siteId: -1,
      allTanks: [],
      tankItems: [],
      dateFields: [
        { key: 'date', label: 'Date', thClass: 'text-center' }
      ],
      dateData: [],
      inventoryFields: [],
      inventoryData: [],
      showData: false,
      timeZoneId: '',
      editFields: [
        { key: 'edit', label: '', class: 'edit' }
      ],
      minStart: minStartDate,
      maxEnd: maxEndDate,
      inventoryStartDateString: global.buildShortDateString(startDate),
      inventoryStartDate: startDate,
      inventoryEndDateString: global.buildShortDateString(endDate),
      inventoryEndDate: endDate,
      selectedTankId: -1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [
        { value: 5, text: '5 per page' },
        { value: 10, text: '10 per page' },
        { value: 25, text: '25 per page' },
        { value: 50, text: '50 per page' },
        { value: 100, text: '100 per page' }
      ],
      // Private variables
      resolvePromise: () => {}
    }
  },
  computed: {
    totalRows () {
      return this.inventoryData.length
    },
    startDateState () {
      if (this.inventoryStartDateString.match(global.shortDateRegex)) {
        if (this.inventoryStartDate.getTime() >= this.minStart.getTime() && this.inventoryStartDate.getTime() <= this.inventoryEndDate.getTime()) {
          return null
        }
      }

      return false
    },
    endDateState () {
      if (this.inventoryEndDateString.match(global.shortDateRegex)) {
        if (this.inventoryEndDate.getTime() >= this.inventoryStartDate.getTime() && this.inventoryEndDate.getTime() <= this.maxEnd.getTime()) {
          return null
        }
      }

      return false
    }
  },
  mounted () {
    this.$root.$on('bv::modal::hide', (bvEvent) => {
      if (bvEvent.vueTarget._uid - 1 === this._uid) {
        this.clear()
      }
    })
  },
  methods: {
    build () {
      this.inventoryEndDate.setHours(23)
      this.inventoryEndDate.setMinutes(59)
      this.inventoryEndDate.setSeconds(59)
      this.inventoryEndDate.setMilliseconds(999)
      this.tankItems = []
      this.dateData = []
      this.inventoryFields = []
      this.inventoryData = []

      TankRepo.getTanksByDateRange(this.siteId, this.buildRequestDate(this.inventoryStartDate), this.buildRequestDate(this.inventoryEndDate), !this.showAll)
        .then((response) => {
          if (response && response.data && response.data.length) {
            this.tankItems = [...response.data]

            const inventories = []
            this.tankItems.forEach((tank) => {
              let name = tank.commodityClass
              if (this.allTanks.filter((t) => t.commodityClass === name).length > 1) {
                name += ` (${tank.tankNumber})`
              }

              tank.inventories.forEach((inv) => {
                let currentIdx = inventories.findIndex((i) => i.inventoryTimeStamp === inv.inventoryTimeStamp)
                const invDate = new Date(inv.inventoryTimeStamp)
                if (currentIdx === -1) {
                  inventories.push({
                    inventoryTimeStamp: inv.inventoryTimeStamp,
                    date: invDate,
                    dateString: this.formatDate(invDate),
                    tanks: []
                  })
                  currentIdx = inventories.length - 1
                }

                const inventory = inv.inventoryReading ? Math.round(inv.inventoryReading) : 0
                const newInventoryObj = {
                  inventoryId: inv.id,
                  tankId: tank.id,
                  name: name,
                  inventory
                }

                inventories[currentIdx].tanks.push(newInventoryObj)
              })
            })

            if (inventories.length > 0) {
              // Sort inventories by timesyamp
              inventories.sort((a, b) => {
                if (a.date.getTime() < b.date.getTime()) {
                  return 1
                }
                if (a.date.getTime() > b.date.getTime()) {
                  return -1
                }
                return 0
              })

              inventories.forEach((forecastData, rowIdx) => {
                const inventory = {}
                forecastData.tanks.forEach((tank) => {
                  const inventoryExist = this.inventoryFields.findIndex((i) => i.key === tank.name) >= 0
                  if (!inventoryExist) {
                    this.inventoryFields.push({ key: tank.name, class: 'text-center' })
                  }

                  inventory[tank.name] = {
                    inventoryId: tank.inventoryId,
                    tankId: forecastData.tankId,
                    name: tank.name,
                    inventoryReading: tank.inventory,
                    inventoryTimeStamp: forecastData.inventoryTimeStamp
                  }
                })
                if (this.canUpdateInventory || this.canDeleteInventory) {
                  inventory.Actions = { inventoryId: -1, tankId: `${rowIdx}-Actions`, name: `${rowIdx}-Actions`, inventoryReading: 0, inventoryTimeStamp: 0 }
                }
                this.inventoryData.push(inventory)
                this.dateData.push({ date: forecastData.dateString, inventoryTimeStamp: forecastData.inventoryTimeStamp })
              })
              if (this.canUpdateInventory || this.canDeleteInventory) {
                this.inventoryFields.push({ key: 'Actions', class: 'text-center' })
              }
              this.showData = true
            } else {
              this.showData = false
            }
          } else {
            this.showData = false
          }
        })
        .catch((error) => {
          this.showError(global.handleError(error, 'An error occurred while trying to retrieve inventories. Please try again later'))
        })
    },
    show (opts = {}) {
      if (opts.tankItems && opts.tankItems.length) {
        this.allTanks = opts.tankItems
        this.siteId = opts.siteId
        this.timeZoneId = opts.timeZoneId
        this.build()
      } else {
        this.showData = false
      }

      this.$refs.inventoryHistoryModal.show()

      return new Promise((resolve) => {
        this.resolvePromise = resolve
      })
    },
    clear () {
      this.siteId = -1
      this.allTanks = []
      this.tankItems = []
      this.dateData = []
      this.inventoryFields = []
      this.inventoryData = []
      this.showData = false
      this.timeZoneId = ''
      const minStartDate = new Date()
      minStartDate.setHours(0)
      minStartDate.setMinutes(0)
      minStartDate.setSeconds(0)
      minStartDate.setMilliseconds(0)
      minStartDate.setDate(minStartDate.getDate() - 180)
      const startDate = new Date()
      startDate.setHours(0)
      startDate.setMinutes(0)
      startDate.setSeconds(0)
      startDate.setMilliseconds(0)
      startDate.setDate(startDate.getDate() - 30)
      const endDate = new Date()
      endDate.setHours(23)
      endDate.setMinutes(59)
      endDate.setSeconds(59)
      endDate.setMilliseconds(999)
      const maxEndDate = new Date()
      maxEndDate.setHours(23)
      maxEndDate.setMinutes(59)
      maxEndDate.setSeconds(59)
      maxEndDate.setMilliseconds(999)
      this.minStart = minStartDate
      this.maxEnd = maxEndDate
      this.inventoryStartDate = startDate
      this.inventoryStartDateString = global.buildShortDateString(startDate)
      this.inventoryEndDate = endDate
      this.inventoryEndDateString = global.buildShortDateString(endDate)
      this.selectedTankId = -1
      this.currentPage = 1
      this.totalPages = 0
    },
    save () {
      this.$refs.inventoryHistoryModal.hide()
      this.resolvePromise(true)
    },
    cancel () {
      this.clear()
      this.$refs.inventoryHistoryModal.hide()
      this.resolvePromise(false)
    },
    formatDate (date) {
      return moment(date).format('ddd MM/DD HH:mm')
    },
    buildRequestDate (date) {
      return moment(date).format('yyyy-MM-DDTHH:mm:ss')
    },
    async editItem (item) {
      if (this.canUpdateInventory) {
        const result = await this.$refs.invEditModal.show({
          timeZoneId: this.timeZoneId,
          existingTanks: this.allTanks,
          item
        })

        if (result) {
          this.build()
          this.$emit('refresh-calculator')
        }
      } else {
        this.showError('Current useer does not have permissions to edit inventories')
      }
    },
    async deleteItem (item) {
      if (this.canDeleteInventory) {
        let dateTime = ''
        const ids = []
        for (const key in item) {
          if (key !== 'Actions' && item[key] && item[key].inventoryId) {
            dateTime = item[key].inventoryTimeStamp
            ids.push(item[key].inventoryId)
          }
        }
        if (ids.length >= 0) {
          const result = await this.$refs.deleteModal.show({
            title: 'Delete Inventories',
            text: `Are you sure you want to delete inventories of ${dateTime}?`,
            confirmText: 'Delete',
            confirmVariant: 'outline-kag-red'
          })
          if (result) {
            try {
              await Promise.all(ids.map(async (id) => await InventoryRepo.delete(id)))
              this.build()
              this.showSuccess('Inventories deleted successfully')
              this.$emit('refresh-calculator')
            } catch (error) {
              this.showError('An error occurred while trying to delete some inventories. Please try again later')
            }
          }
        } else {
          this.showWarning('There are no inventories to delete')
        }
      } else {
        this.showError('Current useer does not have permissions to delete inventories')
      }
    },
    onStartDateChange (text) {
      if (text.match(global.sixDigitRegex)) {
        this.inventoryStartDateString = `${text.substring(0, 2)}/${text.substring(2, 4)}/${text.substring(4, 6)}`
        this.onStartDateChange(this.inventoryStartDateString)
      } else {
        if (text.match(global.shortDateRegex)) {
          const split = text.split('/')
          this.inventoryStartDate = new Date((+split[2] + 2000), +split[0] - 1, +split[1])
        }
      }
    },
    onStartDateContext (ctx) {
      this.inventoryStartDateString = `${('0' + (+ctx.selectedDate.getMonth() + 1)).slice(-2)}/${('0' + ctx.selectedDate.getDate()).slice(-2)}/${ctx.selectedDate.getFullYear().toString().substring(2, 4)}`
    },
    onEndDateChange (text) {
      if (text.match(global.sixDigitRegex)) {
        this.inventoryEndDateString = `${text.substring(0, 2)}/${text.substring(2, 4)}/${text.substring(4, 6)}`
        this.onEndDateChange(this.inventoryEndDateString)
      } else {
        if (text.match(global.dateRegex)) {
          const split = text.split('/')
          this.inventoryEndDate = new Date((+split[2] + 2000), +split[0] - 1, +split[1])
        }
      }
    },
    onEndDateContext (ctx) {
      this.inventoryEndDateString = `${('0' + (+ctx.selectedDate.getMonth() + 1)).slice(-2)}/${('0' + ctx.selectedDate.getDate()).slice(-2)}/${ctx.selectedDate.getFullYear().toString().substring(2, 4)}`
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/scss/themes/theme';

.inventory-history {
  .inventory-history-container {
    width: 100%;
    display: inline-flex;

    > div {
      width: 100%;
      flex: 1;
      display: inline-flex;
    }

    $actions-width: 80px;
    $row-height: 33px;

    .inventory-tables-container {
      width: calc(100% - #{$actions-width});

      &.no-actions {
        width: 100%;
      }

      .inventory-container {
        display: inline-flex;

        tbody {
          tr {
            height: $row-height;
            white-space: nowrap;
          }
        }

        $date-width: 160px;

        .inventory-history-date-container {
          width: $date-width;
        }

        .inventory-history-data-container {
          width: calc(100% - #{$date-width});
          overflow-x: auto;

          .inventory-history-table {
            td, th {
              white-space: nowrap;
            }
          }
        }
      }
    }

    .inventory-history-actions-container {
      width: $actions-width;
      margin-top: $row-height;

      tr {
        border: none;
        border-radius: none;
      }

      .edit {
        background-color: $white;
        border: none;

        .btn {
          font-size: 0.475rem;
        }
      }
    }
  }
  .icon-red {
    color: $kag-red-color;
  }
  .icon-light-blue {
    color: $kag-light-blue-color;
  }
}
</style>

import global from '@/mixins/shared/global'
import Repository from './Repository'
const customGroupRepo = Repository(global.basePath)

const resource = '/CustomGroup'

export default {
  getAll () {
    return customGroupRepo.get(`${resource}`)
  },
  get (id) {
    return customGroupRepo.get(`${resource}/${id}`)
  },
  getCustomGroupsByRegionId (regionId) {
    const params = {
      regionId
    }
    return customGroupRepo.get(`${resource}/GetCustomGroupsByRegionId`, { params })
  },
  create (payload) {
    return customGroupRepo.post(`${resource}`, payload)
  },
  update (payload) {
    return customGroupRepo.put(`${resource}`, payload)
  }
}

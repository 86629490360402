export default {
  getFirstName (state) {
    return state.firstName
  },
  getLastName (state) {
    return state.lastName
  },
  getEmail (state) {
    return state.email
  },
  UICalculatorEditor (state) {
    return state.UICalculatorEditor
  },
  UICompanyEditor (state) {
    return state.UICompanyEditor
  },
  UITankEditor (state) {
    return state.UITankEditor
  },
  UIUserProfilesEditor (state) {
    return state.UIUserProfilesEditor
  },
  UserProfilesAdmin (state) {
    return state.userProfilesAdmin
  },
  canViewUserRegionProfiles (state) {
    return state.UIUserProfilesEditor && state.userProfilesAdmin
  },
  hasInventoryManagementAdmin (state) {
    return state.hasInventoryManagementAdmin
  },
  tenantName (state) {
    return state.tenantName
  },
  tenantNameMatch (state) {
    return state.tenantNameMatch
  },
  canViewCompany (state) {
    return state.canViewCompany
  },
  canCreateCompany (state) {
    return state.canCreateCompany
  },
  canUpdateCompany (state) {
    return state.canUpdateCompany
  },
  canDeleteCompany (state) {
    return state.canDeleteCompany
  },
  canViewCommodity (state) {
    return state.canViewCommodity
  },
  canCreateCommodity (state) {
    return state.canCreateCommodity
  },
  canUpdateCommodity (state) {
    return state.canUpdateCommodity
  },
  canDeleteCommodity (state) {
    return state.canDeleteCommodity
  },
  canViewCustomGroup (state) {
    return state.canViewCustomGroup
  },
  canCreateCustomGroup (state) {
    return state.canCreateCustomGroup
  },
  canUpdateCustomGroup (state) {
    return state.canUpdateCustomGroup
  },
  canDeleteCustomGroup (state) {
    return state.canDeleteCustomGroup
  },
  canViewInventory (state) {
    return state.canViewInventory
  },
  canCreateInventory (state) {
    return state.canCreateInventory
  },
  canUpdateInventory (state) {
    return state.canUpdateInventory
  },
  canDeleteInventory (state) {
    return state.canDeleteInventory
  },
  canViewNote (state) {
    return state.canViewNote
  },
  canCreateNote (state) {
    return state.canCreateNote
  },
  canUpdateNote (state) {
    return state.canUpdateNote
  },
  canDeleteNote (state) {
    return state.canDeleteNote
  },
  canViewOrder (state) {
    return state.canViewOrder
  },
  canCreateOrder (state) {
    return state.canCreateOrder
  },
  canUpdateOrder (state) {
    return state.canUpdateOrder
  },
  canDeleteOrder (state) {
    return state.canDeleteOrder
  },
  canViewRegion (state) {
    return state.canViewRegion
  },
  canCreateRegion (state) {
    return state.canCreateRegion
  },
  canUpdateRegion (state) {
    return state.canUpdateRegion
  },
  canDeleteRegion (state) {
    return state.canDeleteRegion
  },
  canViewRestriction (state) {
    return state.canViewRestriction
  },
  canCreateRestriction (state) {
    return state.canCreateRestriction
  },
  canUpdateRestriction (state) {
    return state.canUpdateRestriction
  },
  canDeleteRestriction (state) {
    return state.canDeleteRestriction
  },
  canViewSales (state) {
    return state.canViewSales
  },
  canCreateSales (state) {
    return state.canCreateSales
  },
  canUpdateSales (state) {
    return state.canUpdateSales
  },
  canDeleteSales (state) {
    return state.canDeleteSales
  },
  canViewTank (state) {
    return state.canViewTank
  },
  canCreateTank (state) {
    return state.canCreateTank
  },
  canUpdateTank (state) {
    return state.canUpdateTank
  },
  canDeleteTank (state) {
    return state.canDeleteTank
  },
  canViewTenant (state) {
    return state.canViewTenant
  },
  canCreateTenant (state) {
    return state.canCreateTenant
  },
  canUpdateTenant (state) {
    return state.canUpdateTenant
  },
  canDeleteTenant (state) {
    return state.canDeleteTenant
  },
  canCreateUserProfile (state) {
    return state.canCreateUserProfile
  },
  canViewFileUploadHeader (state) {
    return state.canViewFileUploadHeader
  },
  canCreateFileUploadHeader (state) {
    return state.canCreateFileUploadHeader
  },
  canUpdateFileUploadHeader (state) {
    return state.canUpdateFileUploadHeader
  },
  canDeleteFileUploadHeader (state) {
    return state.canDeleteFileUploadHeader
  }
}

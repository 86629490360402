<template>
  <div class="custom-groups">
    <Catalog
      ref="catalogComponent"
      item-name="Custom Group"
      :get-all-request="getAllRequest"
      :create-request="createRequest"
      :update-request="updateRequest"
      :initial-items="items"
      :can-create="canCreateCustomGroup"
      :can-update="canUpdateCustomGroup"
      :can-delete="canDeleteCustomGroup"
      @deleteItem="deleteCustomGroup"
    ></Catalog>
    <ConfirmModal ref="deleteModal"></ConfirmModal>
  </div>
</template>

<script>
import global from '@/mixins/shared/global'
import userAuthGetters from '@/mixins/shared/userAuthGetters'
import toastMixin from '@/mixins/shared/toastMixin'

import Catalog from '../../components/shared/Catalog.vue'
import ConfirmModal from '@/components/shared/ConfirmModal.vue'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'

const CustomGroupRepo = RepositoryFactory.get('CustomGroupRepository')
const CompanyRepo = RepositoryFactory.get('CompanyRepository')

export default {
  name: 'CustomGroups',
  mixins: [
    userAuthGetters,
    toastMixin
  ],
  components: {
    Catalog,
    ConfirmModal
  },
  data () {
    return {
      getAllRequest: CustomGroupRepo.getAll,
      createRequest: CustomGroupRepo.create,
      updateRequest: CustomGroupRepo.update,
      items: []
    }
  },
  async beforeRouteEnter (to, from, next) {
    try {
      const response = await CustomGroupRepo.getAll()
      to.meta.customGroups = response && response.data ? response.data : []
      next()
    } catch {
      next('/error')
    }
  },
  created () {
    if (!this.canViewCustomGroup) {
      if (this.canViewRegion) {
        this.$router.push({ name: 'Regions' })
      } else if (this.canViewCompany) {
        this.$router.push({ name: 'Sites' })
      } else {
        this.$router.push({ name: 'AccessDenied' })
      }
      return
    }
    this.items = this.$route.meta.customGroups
  },
  async mounted () {
    await this.$store.dispatch('orders/loadOrdersToReview')
  },
  methods: {
    async deleteCustomGroup (item) {
      const companyResult = await CompanyRepo.getSites(item.id, -1)
      if (companyResult && companyResult.data && companyResult.data.sites && companyResult.data.sites.length === 0) {
        const result = await this.$refs.deleteModal.show({
          title: 'Delete Custom Group',
          text: `Are you sure you want to delete Custom Group ${item.description}?`,
          confirmText: 'Delete',
          confirmVariant: 'outline-kag-red'
        })
        if (result) {
          item.isDeleted = true
          CustomGroupRepo.update(item)
            .then(() => {
              this.$refs.catalogComponent.fetch()
            })
            .catch((error) => {
              this.showError(global.handleError(error, 'An error occurred while trying to delete Custom Group. Please try again later'))
            })
        }
      } else {
        this.showError(`${item.description} is currently assigned to a Site. Make sure it is not assigned to a Site before deleting it`)
      }
    }
  }
}
</script>

import global from '@/mixins/shared/global'
import Repository from './Repository'
const commodityRepo = Repository(global.basePath)

const resource = '/CommoditySiteProfile'

export default {
  getCommodityProfiles () {
    return commodityRepo.get(`${resource}/`)
  },
  getCommodityProfileByCompanyId (companyId) {
    return commodityRepo.get(`${resource}/${companyId}`)
  },
  create (payload) {
    return commodityRepo.post(`${resource}/`, payload)
  },
  async update (payload) {
    return await commodityRepo.put(`${resource}?companyId=${payload.companyId}&commodityCode=${payload.commodityCode}`, payload)
  }
}

f<template>
  <div class="pb-5 mb-5 container-fluid">
    <div class="px-3">
      <b-row class="mt-3">
        <b-col md="2" lg="2" class="label margin text-right"
          ><strong>Tank Number</strong></b-col
        >
        <b-col v-for="(tank, index) in tanks" :key="index">
          <b-row>
            <b-col class="p-0 margin text-right"
              ><b-input :id="'tank-number-' + index" class="rounded-0" :value="tank.tankNumber" disabled></b-input
            ></b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" lg="2" class="label margin text-right"
          ><strong>Product</strong></b-col
        >
        <b-col v-for="(tank, index) in tanks" :key="index">
          <b-row>
            <b-col :id="'commodity-class-' + index" class="p-0 margin"><b-input :value="tank.commodityClass" class="rounded-0" disabled></b-input
            ></b-col>
          </b-row>
          <b-row>
            <b-col class="p-0 margin"
              ><b-input :id="'commodity-code-' + index" :value="tank.commodityCode" class="rounded-0" disabled></b-input
            ></b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" lg="2" class="label margin text-right"
          ><strong>Capacity</strong></b-col
        >
        <b-col v-for="(tank, index) in tanks" :key="index">
          <b-row>
            <b-col class="p-0 margin"
              ><b-input :id="'tank-size-' + index" :value="tank.tankSize" disabled class="rounded-0"></b-input
            ></b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" lg="2" class="label margin text-right"
          ><strong>Safe Fill</strong></b-col
        >
        <b-col v-for="(tank, index) in tanks" :key="index">
          <b-row>
            <b-col class="p-0 margin"
              ><b-input :id="'safe-fill-qty-' + index" :value="tank.safefillQty" class="rounded-0" disabled></b-input
            ></b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div class="bg-light-blue-2 px-3">
      <b-row class="mt-3 bg-light-blue-3 py-2">
        <b-col v-if="tanks.length > 0"><strong>{{ tanks[0].inventories[0].dayOfWeek }} {{ tanks[0].inventories[0].inventoryTimeStamp }}</strong></b-col>
      </b-row>
      <div class="py-1">
        <b-row>
          <b-col md="2" lg="2" class="bg-light-blue label text-right"
            ><strong>Beginning Inventory</strong></b-col
          >
          <b-col v-for="(tank, index) in tanks" :key="index">
            <b-row>
              <b-col class="p-0 margin">
                <b-input :id="'beginning-inventory-' + index" v-model="form[index].beginningInventory"
                class="rounded-0" type="number" disabled></b-input>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="2" lg="2" class="bg-light-blue label text-right"
            ><strong>Current Will Hold</strong></b-col
          >
          <b-col v-for="(tank, index) in tanks" :key="index">
            <b-row>
              <b-col class="p-0 margin"
                ><b-input :id="'current-will-hold-' + index" :value="form[index].currentWillHold" class="rounded-0" disabled></b-input
              ></b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="2" lg="2" class="bg-light-blue label text-right"><strong>Current Inventory</strong></b-col>
          <b-col v-for="(tank, index) in tanks" :key="index">
            <b-row>
              <b-col class="p-0 margin"
                ><b-input :id="'currentInventory-' + index" :value="form[index].currentInventory" class="rounded-0" disabled></b-input
              ></b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="2" lg="2" class="bg-light-blue label text-right"><strong>Days of Supply</strong></b-col>
          <b-col v-for="(tank, index) in tanks" :key="index">
            <b-row>
              <b-col class="p-0 margin"
                ><b-input :id="'days-of-supply-' + index" :value="form[index].daysOfSupply" class="rounded-0" disabled></b-input
              ></b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="2" lg="2" class="bg-light-blue label text-right"
            ><strong>Sales</strong></b-col
          >
          <b-col v-for="(tank, index) in tanks" :key="index">
            <b-row>
              <b-col class="p-0 margin"
                ><b-input :id="'sales-' + index" type="number" v-model.number="form[index].sales" min="0"
                        class="rounded-0" oninput="validity.valid||(value=0);"></b-input>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <b-row class="bg-light-blue-3 py-2">
        <b-col><strong>Pending Orders</strong></b-col>
      </b-row>
      <b-row>
        <b-col md="2" lg="2" class="bg-light-blue label text-right p-0">
          <b-row class="margin" v-for="(order, index) in pendingOrders" :key="index">
            <b-col class="margin"><strong>{{ order }}</strong></b-col>
          </b-row>
        </b-col>
        <b-col class="p-0 ml-0" v-for="(tank, tankIndex) in form" :key="tankIndex">
            <b-row class="p-0 ml-0 margin" v-for="(order, index) in tank.fimOrders" :key="index">
              <b-col class="p-0 ml-0 margin">
                <b-input :id="'fimorder-gallons-' + tankIndex + '-' + index" class="rounded-0" v-model.number="order.gallons" type="number" min="0"
                        oninput="validity.valid||(value=0);" @input="validateNumber('fimOrder', order, order.gallons)"></b-input>
              </b-col>
            </b-row>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="2" lg="2" class="bg-light-blue label text-right margin">
          <b-row v-for="(order, index) in orders" :key="index">
            <b-col class="margin"><strong>{{ order }}</strong></b-col>
          </b-row>
        </b-col>

        <b-col class="p-0 ml-0 margin" v-for="(tank, tankIndex) in form" :key="tankIndex">
          <b-row class="p-0 margin ml-0" v-for="(order, index) in tank.orders" :key="index">
            <b-col class="p-0 margin ml-0">
              <b-input :id="'order-gallons-' + tankIndex + '-' + index" type="number" min="0" class="rounded-0" v-model="order.stopTotalVolume"
                      oninput="validity.valid||(value=0);" @input="validateNumber('order', order, order.stopTotalVolume)"></b-input>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col md="2" lg="2" class="bg-light-blue label text-right"
          ><strong>Ending Inventory</strong></b-col
        >
        <b-col v-for="(tank, index) in form" :key="index">
          <b-row>
            <b-col class="p-0 margin"
              ><b-input :id="'ending-inventory-' + index" v-model="tank.endingInventory" class="rounded-0" disabled></b-input
            ></b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" lg="2" class="bg-light-blue label text-right"
          ><strong>Ending Will Hold</strong></b-col
        >
        <b-col>
          <b-row>
            <b-col class="p-0 margin" v-for="(tank, index) in form" :key="index">
              <b-input :id="'ending-will-hold-' + index" v-model="tank.endingWillHold" class="rounded-0" disabled></b-input>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>

    <b-row class="mt-4">
      <b-col cols="6" class="mx-auto text-center">
        <b-button variant="outline-primary mr-3" @click="calculate">Calculate</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { RepositoryFactory } from '@/repositories/RepositoryFactory'

const CalculatorRepo = RepositoryFactory.get('CalculatorRepository')

export default {
  name: 'Calculator',
  data () {
    return {
      tanks: [],
      pendingOrders: [],
      orders: [],
      totalOrder: 0,
      totalOrders: [],
      form: {},
      loadingCalculatorData: false
    }
  },
  watch: {
    site (newVal, oldVal) {
      if (this.loadingCalculatorData) return
      if (newVal !== oldVal) {
        this.updateData()
      }
    },
    tankOrders (newVal, oldVal) {
      if (this.loadingCalculatorData) return
      if (newVal !== oldVal) {
        this.updateData()
      }
    },
    tankPendingOrders (newVal, oldVal) {
      if (this.loadingCalculatorData) return
      if (newVal !== oldVal) {
        this.updateData()
      }
    }
  },
  props: {
    site: Object,
    tankOrders: Array,
    tankPendingOrders: Array
  },
  methods: {
    calculate () {
      this.updateForm()
    },
    validateNumber (type, order, value) {
      if (value === '' && type === 'order') {
        order.stopTotalVolume = 0
      }

      if (value === '' && type === 'fimOrder') {
        order.gallons = 0
      }
    },
    getTotalOrders (index) {
      return this.totalOrders[index]
    },
    endingInventory (tank, index) {
      if (tank.beginningInventory === '') {
        tank.beginningInventory = 0
      }
      var beginningInventory = tank.beginningInventory ?? tank.inventories[0].inventoryReading
      var sales = tank.sales ?? tank.avgDailySales
      var totalOrders = parseInt(this.getTotalOrders(index).toFixed(2))
      var total = (beginningInventory - sales) + totalOrders
      return (total)
    },
    currentWillHold (tank) {
      if (tank.currentInventory) {
        return tank.safefillQty - tank.currentInventory
      }
      return tank.safefillQty - tank.inventories[tank.inventories.length - 1].inventoryReading
    },
    endingWillHold (tank, index) {
      var inventory = this.endingInventory(tank, index)
      return tank.safefillQty - inventory
    },
    sales (tank) {
      if (tank.sales) {
        return tank.sales
      }
      return tank.avgDailySales
    },
    beginningInventory (tank) {
      if (tank.inventories.length === 0) {
        tank.inventories.push({
          tankId: tank.id,
          inventoryReading: 0
        })
        return 0
      }
      return tank.inventories[0].inventoryReading
    },
    currentInventory (tank) {
      if (tank.inventories.length === 0) {
        tank.inventories.push({
          tankId: tank.id,
          inventoryReading: 0
        })
        return 0
      }
      return tank.inventories[tank.inventories.length - 1].inventoryReading
    },
    createForm () {
      this.form = this.tanks.map((tank, index) => {
        return {
          ...tank,
          beginningInventory: this.beginningInventory(tank),
          currentInventory: this.currentInventory(tank),
          currentWillHold: this.currentWillHold(tank),
          sales: this.sales(tank),
          endingInventory: this.endingInventory(tank, index),
          endingWillHold: this.endingWillHold(tank, index)
        }
      })
    },
    async updateData () {
      this.loadingCalculatorData = true
      this.orders = []
      this.pendingOrders = []
      this.totalOrders = []
      const calculatorData = await CalculatorRepo.getCalculator(this.site.id)
      this.tanks = calculatorData.data.tanks

      this.tanks.map(tank => {
        tank.orders.sort((a, b) => (a.orderHdrNumber - b.orderHdrNumber))
        tank.fimOrders.sort((a, b) => (a.fimOrderId - b.fimOrderId))
        tank.fimOrders.map(order => {
          this.totalOrder += order.gallons
          if (!this.pendingOrders.includes(order.fimOrderId)) {
            this.pendingOrders.push(order.fimOrderId)
            return order.fimOrderId
          }
        })

        tank.orders.map(order => {
          this.totalOrder += order.stopTotalVolume
          if (!this.orders.includes(order.orderHdrNumber)) {
            this.orders.push(order.orderHdrNumber)
            return order.orderHdrNumber
          }
        })
        this.totalOrders.push(this.totalOrder)
        this.totalOrder = 0
      })
      this.createForm()
      this.loadingCalculatorData = false
    },
    async updateForm () {
      await this.updateTotalOrders()
      this.form.map((tank, index) => {
        tank.currentWillHold = this.currentWillHold(tank)
        tank.sales = this.sales(tank)
        tank.endingInventory = this.endingInventory(tank, index)
        tank.endingWillHold = this.endingWillHold(tank, index)
        tank.fimOrders = this.sortFimOrders(tank)
        tank.orders = this.sortOrders(tank)
      })
    },
    sortFimOrders (tank) {
      const array = tank.fimOrders
      return array.sort((a, b) => (a.fimOrderId - b.fimOrderId))
    },
    sortOrders (tank) {
      const array = tank.orders
      return array.sort((a, b) => (a.orderHdrNumber - b.orderHdrNumber))
    },
    async updateTotalOrders () {
      return new Promise((resolve) => {
        this.totalOrders = []
        this.form.map(tank => {
          tank.orders.map(order => {
            this.totalOrder += order.stopTotalVolume
            if (!this.orders.includes(order.orderHdrNumber)) {
              this.orders.push(order.orderHdrNumber)
              return order.orderHdrNumber
            }
          })
          tank.fimOrders.map(order => {
            this.totalOrder += order.gallons
            if (!this.pendingOrders.includes(order.fimOrderId)) {
              this.pendingOrders.push(order.fimOrderId)
              return order.fimOrderId
            }
          })
          resolve(this.totalOrders.push(this.totalOrder))
          this.totalOrder = 0
        })
      }).finally(() => {
        this.totalOrder = 0
      })
    },
    resetForm () {
      this.createForm()
    }
  },
  async mounted () {
    this.updateData()
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/themes/theme";
.form-control {
  font-size: 14px !important;
  height: 33px;
  font-weight: 500 !important;
}
.margin {
  margin: 1px;
}
.label {
  line-height: 33px !important;
}
.bg-light-gray {
  background-color: #d6dce5 !important;
}
.bg-light-gray-2 {
  background-color: #f1f3f6 !important;
}
.bg-light-blue {
  background-color: #8faadc !important;
}
.bg-light-blue-2 {
  background-color: #e9eef8 !important;
}
.bg-light-blue-3 {
  background-color: #dae3f3 !important;
}
</style>
